import { Modal } from "react-bootstrap";
import styled from "styled-components/macro";
import wrong from "../../../assets/images/wrong.png";

export function RidersPopup({ children, title, onHide, ...props }) {
  return (
    <Modal onHide={onHide} {...props}>
      <Modal.Body
        css={`
          padding: 0;
          padding-bottom: 1em;
        `}
      >
        <div
          css={`
            width: 498px;
            display: flex;
            align-items: center;
            padding: 10px 15px;
            border-radius: 8px;
            background-color: #f3f4f9;

            @media (max-width: 768px) {
              width: 320px;
            }
          `}
        >
          <p>{title}</p>

          <CloseButton
            onClick={() => {
              onHide();
            }}
          >
            <img
              css={`
                height: 25px;
              `}
              src={wrong}
              alt=""
            />
          </CloseButton>
        </div>
        <Container>{children}</Container>
      </Modal.Body>
    </Modal>
  );
}

const CloseButton = styled.button`
  width: 26px;
  height: 26px;
  position: absolute;
  right: 12px;
  top: 12px;
  border: 1px solid #dce2ea;
  border-radius: 50%;
  font-size: 16px;
  & i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Container = styled.div`
  padding: 10px 20px;
  overflow-y: scroll;
  font-family: "Inter";
  font-weight: 400;
  & span {
    text-transform: capitalize;
    font-weight: 600;
  }
`;
