import React from "react";
import FiltersMobile from "../../product-page/mobile/components/FiltersMobile";
// import { BasicDetails } from "./ComparePageFilters";
import { smForHook } from "../../../styles/mediaQueries";
import useMediaQuery from "../../../custom-hooks/useMediaQuery";
import { ComparePageTabBar } from "../../product-page/mobile/components/TabBar";
import { useLocation } from "react-router-dom";
const CompareFiltersMobile = () => {
  const [comparePage, setComparePage] = React.useState(false);
  const location = useLocation();
  const matches = useMediaQuery(smForHook);

  React.useEffect(() => {
    if (location.pathname === "/compare") setComparePage(true);
    else setComparePage(false);
  }, [location]);

  return (
    <div>
      {matches ? (
        <ComparePageTabBar isComparePage={comparePage} />
      ) : (
        <FiltersMobile />
      )}
    </div>
  );
};

export default CompareFiltersMobile;
