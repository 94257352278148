import React from "react";
import { AHead, AWrapper } from "./styles";
import logo from "../../assets/images/logo/logo.png";
import "styled-components/macro";
import { useSelector } from "react-redux";

function AgentConfidential() {
  const { frontendData } = useSelector(state => state.frontendBoot);
  return (
    <AWrapper className="container">
      <AHead>
        <img
          src={frontendData?.termsettings?.logo || logo}
          alt=""
          className="a-logo"
        />
        Agent Confidential Report
      </AHead>
    </AWrapper>
  );
}

export default AgentConfidential;
