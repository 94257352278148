import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateEnquiries } from "../../../Landing/input.slice";
// import { getAnnualIncome } from "../../Filters/helper";
import { getStateandCity, initAddressFetching } from "../Eligibility.slice";
import {
  Details,
  // ErrorMsg,
  // Label,
  // Select,
  // TextInput,
  Wrapper,
} from "../EligibilityCard.style";
import "styled-components/macro";
import { isDigits } from "../../../../utils";
import Select from "./Select/Select";
import TextInput from "./TextInput/TextInput";
import { allowOnlyNumbers } from "../../../ProposalPage/inputUtils/onInput";
import ButtonLoader from "../../../../components/CardSkeletonLoader/ButtonLoader/ButtonLoader";

const Address = ({ handleSubmit, isActive }) => {
  const {
    cityOptions,
    stateOptions,
    eligibilityGrid,
    e_sum_insured,
    errorMessage,
    isAddressFetching,
  } = useSelector(state => state.eligibility);

  const { data, isEnquiryLoading } = useSelector(state => state.inputForm);

  const [errors, setErrors] = useState({
    pincode: "",
    state: "",
    city: "",
  });
  const { selectedQuote } = useSelector(state => state.quotes);

  const dispatch = useDispatch();
  const [eligibilityData, setEligibilityData] = useState({
    occupation: data?.selected_product?.occupation || data.occupation,
    education: data?.selected_product?.education || data.education,
    term_annual_income:
      data?.selected_product?.term_annual_income || data.term_annual_income,
    pincode: "",
    state: "",
    city: "",
    city_name: "",
    state_name: "",
  });

  useEffect(() => {
    if (eligibilityData.pincode && eligibilityData.pincode.length === 6) {
      if (stateOptions.length === 1)
        setEligibilityData(prevData => ({
          ...prevData,
          state: stateOptions[0].code,
          state_name: stateOptions[0].display_name,
        }));
      if (cityOptions.length === 1)
        setEligibilityData(prevData => ({
          ...prevData,
          city: cityOptions[0]?.code,
          city_name: cityOptions[0]?.display_name,
        }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eligibilityData.pincode, stateOptions]);

  useEffect(() => {
    if (eligibilityData.pincode.length !== 6) {
      setEligibilityData(prevData => ({
        ...prevData,
        state: "",
        state_name: "",
        city: "",
        city_name: "",
      }));
    }
  }, [eligibilityData.pincode]);

  useEffect(() => {
    if (cityOptions.length > 1) {
      setEligibilityData(prevData => ({
        ...prevData,

        city: "",
        city_name: "",
      }));
    }
  }, [cityOptions]);
  useEffect(() => {
    if (eligibilityData.pincode && errorMessage) {
      setErrors({
        ...errors,
        pincode: errorMessage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  useEffect(() => {
    const data =
      eligibilityGrid[
        `${eligibilityData?.occupation}+${eligibilityData?.education}`
      ];
    if (data?.length) {
      const filteredItem = data.find(item => {
        if (
          eligibilityData?.term_annual_income >= item.minimum_annual_income &&
          eligibilityData?.term_annual_income <= item.maximum_annual_income
        ) {
          return true;
        } else return false;
      });

      if (!filteredItem || filteredItem.case_result !== "Pass") {
        setErrors({
          ...errors,
          education: "Invalid Education",
          occupation: "Invalid Occupation",
          term_annual_income: "Invalid Annual Income",
        });
      } else setErrors({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eligibilityData, eligibilityGrid]);

  const onChange = e => {
    setEligibilityData(prev => {
      return {
        ...prev,
        pincode: e.target.value,
        // state: e,
        //city: e,
      };
    });

    if (e.target.value && !isDigits(e.target.value)) return;
    if (e.target.value.length === 6) {
      dispatch(initAddressFetching());
      dispatch(
        getStateandCity({
          company_alias: selectedQuote.company_alias,
          pincode: e.target.value,
          sumassured: selectedQuote?.term_sum_assured,
        }),
      );
    }
  };
  if (!isActive) return null;

  return (
    <>
      <Details autoComplete="off">
        <Wrapper fullWidth>
          <Select
            label="Nationality*"
            options={[{ code: "Indian", display_name: "Indian" }]}
            readOnly
            selectedValue={"Indian"}
          />
        </Wrapper>
        <Wrapper fullWidth>
          <Select
            label="Country*"
            options={[{ code: "India", display_name: "India" }]}
            readOnly
            selectedValue={"India"}
          />
        </Wrapper>

        <Wrapper fullWidth>
          <TextInput
            label="Pincode*"
            onInput={allowOnlyNumbers}
            onChange={onChange}
            name="pincode"
            error={errors?.pincode}
            value={eligibilityData.pincode}
            inputProps={{ inputMode: "numeric", maxLength: 6 }}
          />
        </Wrapper>
        <Wrapper fullWidth>
          <Select
            pincodeFetch
            name="state"
            label="State*"
            noSearch
            options={!eligibilityData.state || errorMessage ? [] : stateOptions}
            handleChange={val =>
              setEligibilityData(prev => {
                return {
                  ...prev,
                  state: val,
                };
              })
            }
            autoComplete="new-password"
            error={errors?.state}
            selectedValue={isAddressFetching ? "" : eligibilityData.state}
          />
        </Wrapper>
        <Wrapper fullWidth>
          <Select
            pincodeFetch
            name="city"
            label="City*"
            options={
              (!eligibilityData.city && !eligibilityData.state) || errorMessage
                ? []
                : cityOptions
            }
            handleChange={val =>
              setEligibilityData(prev => {
                return {
                  ...prev,
                  city: val,
                  city_name: cityOptions?.find(x => x?.code === val)
                    .display_name,
                };
              })
            }
            error={errors.city}
            selectedValue={isAddressFetching ? "" : eligibilityData.city}
            autoComplete="new-password"
          />
        </Wrapper>
      </Details>

      {(e_sum_insured &&
        parseInt(e_sum_insured, 10) > eligibilityData?.term_annual_income) ||
      e_sum_insured === "NA" ? (
        <div
          style={{
            color: "red",
            alignSelf: "center",
            margin: "0 auto 10px",
            width: "85%",
            fontSize: ".9rem",
            fontWeight: "500",
          }}
        >
          {e_sum_insured !== "NA"
            ? `The minimum Annual Income required for the  pincode entered for the Selected Product is ${e_sum_insured}`
            : "This pincode is not eligible for the Selected Product"}
        </div>
      ) : (
        <button
          css={`
            width: 6em;
            padding: 0.67em 0;
            font-size: 1em;
            border-radius: 0;

            :hover {
              background-color: var(--primary-color);
            }
          `}
          className="btn btn-primary mx-auto d-block btn-lg shadow-none"
          onClick={() => {
            let flag = false;
            for (let key in eligibilityData) {
              if (
                key === "pincode" &&
                eligibilityData[key] &&
                eligibilityData[key].length !== 6
              ) {
                flag = true;
                setErrors(prev => ({
                  ...prev,
                  [key]: "Please enter a valid pincode",
                }));
              } else if (!eligibilityData[key]) {
                flag = true;
                setErrors(prev => ({
                  ...prev,
                  [key]: "This field is required",
                }));
              }
            }
            if (!Object.keys(errors).length && !flag) {
              dispatch(
                updateEnquiries({
                  input: { selected_product: eligibilityData },
                }),
              ).then(() => handleSubmit("personal_details"));
            }
          }}
        >
          {isEnquiryLoading ? <ButtonLoader /> : "Next"}
        </button>
      )}
    </>
  );
};

export default Address;
