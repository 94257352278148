import { useDispatch } from "react-redux";
import {
  setEducationOptions,
  setEligibilityGrid,
  setOccupationOptions,
} from "../pages/product-page/EligibilityCard/Eligibility.slice";
import { setSelectedQuote } from "../pages/product-page/Quotes/quotes.slice";

export function useQuoteBuyNow({ quoteData, onBuyNowClick = () => {} }) {
  const dispatch = useDispatch();

  const handleBuyNowClick = () => {
    dispatch(setEducationOptions(quoteData?.education_list));
    dispatch(setOccupationOptions(quoteData?.occupation_list));
    dispatch(setSelectedQuote(quoteData));
    dispatch(setEligibilityGrid(quoteData?.eligibilityGrid));
    onBuyNowClick();
  };

  return { handleBuyNowClick };
}
