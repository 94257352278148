import moment from "moment";
import * as yup from "yup";

export const medical = yup.object({
  preferred_date: yup.string().required("This field is required."),
  preferred_time: yup.string().required("This field is required."),
  visit_type: yup.string().required("This field is required"),
  address_line_1: yup.string().required("This field is required"),
  address_line_2: yup.string(),
  address_line_3: yup.string(),
  pincode: yup.string().required("This field is required"),
  state: yup.string().required("This field is required"),
  city: yup.string().required("This field is required"),
  state_code: yup.string().required("This field is required"),
  city_code: yup.string().required("This field is required"),
  landmark: yup.string().required("This field is required"),
  centre_details: yup.string().when("visit_type", {
    is: "center",
    then: yup.string().required("This field is required"),
  }),
  centre_id: yup.string().when("visit_type", {
    is: "center",
    then: yup.string().required("This field is required"),
  }),
});

export const dateOptions = () => {
  let days = [];
  let daysRequired = 6;

  for (let i = 0; i <= daysRequired; i++) {
    days.push(moment().add(i, "days").format("DD MMM YYYY"));
  }
  const options = days.map((item, index) => ({
    code: item,
    display_name: item,
  }));
  return options;
};

export const timeOptions = () => {
  const nonTUHours = [];

  for (let hour = 8; hour < 21; hour++) {
    nonTUHours.push(moment({ hour }).format("h:mm A"));
  }

  const optionTu = nonTUHours.map((item, index) => ({
    code: item,
    display_name: item,
  }));

  return optionTu;
};

export const timeOptionsTU = () => {
  const hours = [];

  for (let hour = 9; hour < 20; hour++) {
    hours.push(moment({ hour, minute: 30 }).format("h:mm A"));
  }

  const options = hours.map((item, index) => ({
    code: item,
    display_name: item,
  }));

  return options;
};

export const sample = {
  data: [
    {
      product_id: 174,
      medical_response: {
        centreList: [
          {
            CNTRMASTER_SRNO: 455036,
            CNTRMASTER_CUSTOMER_PINCODE: "421201",
            CNTRMASTER_CUSTOMER_TALUK: "Kalyan",
            CNTRMASTER_CUSTOMER_DISTRICT: "Thane",
            CNTRMASTER_CUSTOMER_DIVISION: "Thane Central",
            CNTRMASTER_CUSTOMER_PO_HO: "Kalyan City H O",
            CNTRMASTER_SERVICE_PROVIDER: "UHC",
            CNTRMASTER_CENTER_GRADE: "D",
            CNTRMASTER_DIAGNOSTIC_CENTER:
              "Kartik Diagnostic   Polyclinic Centre",
            CNTRMASTER_DC_ADDRESS1: "Dombivali E",
            CNTRMASTER_DC_ADDRESS2: "Dombivali E",
            CNTRMASTER_DC_LANDMARK: "Dombivali  E",
            CNTRMASTER_DC_AREA: "Dombivali  E",
            CNTRMASTER_DC_CITY: "Thane",
            CNTRMASTER_DC_PINCODE: "421201",
            CNTRMASTER_DC_STATE: "Maharashtra",
            CNTRMASTER_INT_ZONE_WISE: "INT 2",
            CNTRMASTER_STDCODE: "251",
            CNTRMASTER_TELEPHONENO: "2426312",
            CNTRMASTER_DC_MOBILENO: "9833565182",
            CNTRMASTER_DC_CONT_PERSON: "Dr Rajesh",
            CNTRMASTER_WORKING_HOURS: "8 30AM To 7PM",
            CNTRMASTER_OPEN_ON_SUNDAYS: "No",
            CNTRMASTER_CONTACT_PERSON1: "Dr Rajesh",
            CNTRMASTER_MER: "Y",
            CNTRMASTER_PATHOLOGY: "Y",
            CNTRMASTER_ECG: "Y",
            CNTRMASTER_CHESTXRAY: "Y",
            CNTRMASTER_TMT: "Y",
            CNTRMASTER_USG: "Y",
            CNTRMASTER_HOMEVISIT: "Y",
            CNTRMASTER_URINE_NICOTINE: "Y",
            CNTRMASTER_DECHO: "N",
            CNTRMASTER_SCANNING_FACILITY: "N",
            CNTRMASTER_ISACTIVE: "Y",
            CNTRMASTER_CRTD_ON: "Jan 7, 2022 9:27:27 PM",
            CNTRMASTER_HOTLINE_NO: "080 2206 8608/080 2206 8612/080 4480 4499",
          },
          {
            CNTRMASTER_SRNO: 455037,
            CNTRMASTER_CUSTOMER_PINCODE: "421201",
            CNTRMASTER_CUSTOMER_TALUK: "Kalyan",
            CNTRMASTER_CUSTOMER_DISTRICT: "Thane",
            CNTRMASTER_CUSTOMER_DIVISION: "Thane Central",
            CNTRMASTER_CUSTOMER_PO_HO: "Kalyan City H O",
            CNTRMASTER_SERVICE_PROVIDER: "UHC",
            CNTRMASTER_CENTER_GRADE: "D",
            CNTRMASTER_DIAGNOSTIC_CENTER:
              "Kartik Diagnostic   Polyclinic Centre",
            CNTRMASTER_DC_ADDRESS1: "Dombivali E",
            CNTRMASTER_DC_ADDRESS2: "Dombivali E",
            CNTRMASTER_DC_LANDMARK: "Dombivali  E",
            CNTRMASTER_DC_AREA: "Dombivali  E",
            CNTRMASTER_DC_CITY: "Thane",
            CNTRMASTER_DC_PINCODE: "421201",
            CNTRMASTER_DC_STATE: "Maharashtra",
            CNTRMASTER_INT_ZONE_WISE: "INT 2",
            CNTRMASTER_STDCODE: "251",
            CNTRMASTER_TELEPHONENO: "2426312",
            CNTRMASTER_DC_MOBILENO: "2530860",
            CNTRMASTER_DC_FAXNO: "2530860",
            CNTRMASTER_DC_CONT_PERSON: "Dr Rajesh",
            CNTRMASTER_WORKING_HOURS: "8 AM to 8 PM",
            CNTRMASTER_OPEN_ON_SUNDAYS: "No",
            CNTRMASTER_CONTACT_PERSON1: "Dr Rajesh",
            CNTRMASTER_MER: "Y",
            CNTRMASTER_PATHOLOGY: "Y",
            CNTRMASTER_ECG: "Y",
            CNTRMASTER_CHESTXRAY: "Y",
            CNTRMASTER_TMT: "Y",
            CNTRMASTER_USG: "Y",
            CNTRMASTER_HOMEVISIT: "Y",
            CNTRMASTER_URINE_NICOTINE: "Y",
            CNTRMASTER_DECHO: "N",
            CNTRMASTER_SCANNING_FACILITY: "N",
            CNTRMASTER_ISACTIVE: "Y",
            CNTRMASTER_CRTD_ON: "Jan 7, 2022 9:27:27 PM",
            CNTRMASTER_HOTLINE_NO: "080 2206 8608/080 2206 8612/080 4480 4499",
          },
          {
            CNTRMASTER_SRNO: 503898,
            CNTRMASTER_CUSTOMER_PINCODE: "421201",
            CNTRMASTER_SERVICE_PROVIDER: "UHC",
            CNTRMASTER_CENTER_GRADE: "A",
            CNTRMASTER_DIAGNOSTIC_CENTER: "Ruby Hospital-Thane",
            CNTRMASTER_DC_ADDRESS1:
              "101  Big Bazar CHS  Shivaji Chowk  Lodha Heaven  Nilje  Dombivali 421201",
            CNTRMASTER_DC_ADDRESS2: "NA",
            CNTRMASTER_DC_LANDMARK: "NA",
            CNTRMASTER_DC_AREA: "Dombivli  Maharashtra",
            CNTRMASTER_DC_CITY: "Thane",
            CNTRMASTER_DC_PINCODE: "421201",
            CNTRMASTER_DC_STATE: "Maharashtra",
            CNTRMASTER_INT_ZONE_WISE: "WEST",
            CNTRMASTER_STDCODE: "NA",
            CNTRMASTER_TELEPHONENO: "NA",
            CNTRMASTER_DC_MOBILENO: "NA",
            CNTRMASTER_DC_FAXNO: "NA",
            CNTRMASTER_DC_EMAILID: "NA",
            CNTRMASTER_DC_CONT_PERSON: "Shyam",
            CNTRMASTER_DESIGNATION: "NA",
            CNTRMASTER_WORKING_HOURS: "NA",
            CNTRMASTER_OPEN_ON_SUNDAYS: "N",
            CNTRMASTER_CONTACT_PERSON1: "NA",
            CNTRMASTER_CONTACT_PERSON2: "NA",
            CNTRMASTER_MER: "Y",
            CNTRMASTER_PATHOLOGY: "Y",
            CNTRMASTER_ECG: "Y",
            CNTRMASTER_CHESTXRAY: "Y",
            CNTRMASTER_TMT: "Y",
            CNTRMASTER_USG: "Y",
            CNTRMASTER_HOMEVISIT: "N",
            CNTRMASTER_URINE_NICOTINE: "Y",
            CNTRMASTER_DECHO: "Y",
            CNTRMASTER_SCANNING_FACILITY: "Y",
            CNTRMASTER_ISACTIVE: "Y",
            CNTRMASTER_CRTD_ON: "Jan 7, 2022 9:27:27 PM",
          },
          {
            CNTRMASTER_SRNO: 504273,
            CNTRMASTER_CUSTOMER_PINCODE: "421201",
            CNTRMASTER_SERVICE_PROVIDER: "UHC",
            CNTRMASTER_CENTER_GRADE: "A",
            CNTRMASTER_DIAGNOSTIC_CENTER: "Atharva Healthcare_Dombivili",
            CNTRMASTER_DC_ADDRESS1:
              "1/25  B Wing   Savita CHS  Kelkar Road  Above Subhash Dailry  Dombivali East -",
            CNTRMASTER_DC_ADDRESS2: "NA",
            CNTRMASTER_DC_LANDMARK: "NA",
            CNTRMASTER_DC_AREA: "Dombivli  Maharashtra",
            CNTRMASTER_DC_CITY: "Thane",
            CNTRMASTER_DC_PINCODE: "421201",
            CNTRMASTER_DC_STATE: "Maharashtra",
            CNTRMASTER_INT_ZONE_WISE: "WEST",
            CNTRMASTER_STDCODE: "NA",
            CNTRMASTER_TELEPHONENO: "NA",
            CNTRMASTER_DC_MOBILENO: "NA",
            CNTRMASTER_DC_FAXNO: "NA",
            CNTRMASTER_DC_EMAILID: "NA",
            CNTRMASTER_DC_CONT_PERSON: "Mr.Jeeshan Shaikh Ms.Snehal",
            CNTRMASTER_DESIGNATION: "NA",
            CNTRMASTER_WORKING_HOURS: "NA",
            CNTRMASTER_OPEN_ON_SUNDAYS: "Y",
            CNTRMASTER_CONTACT_PERSON1: "NA",
            CNTRMASTER_CONTACT_PERSON2: "NA",
            CNTRMASTER_MER: "Y",
            CNTRMASTER_PATHOLOGY: "Y",
            CNTRMASTER_ECG: "Y",
            CNTRMASTER_CHESTXRAY: "N",
            CNTRMASTER_TMT: "Y",
            CNTRMASTER_USG: "N",
            CNTRMASTER_HOMEVISIT: "Y",
            CNTRMASTER_URINE_NICOTINE: "Y",
            CNTRMASTER_DECHO: "N",
            CNTRMASTER_SCANNING_FACILITY: "N",
            CNTRMASTER_ISACTIVE: "Y",
            CNTRMASTER_CRTD_ON: "Jan 7, 2022 9:27:27 PM",
          },
          {
            CNTRMASTER_SRNO: 502012,
            CNTRMASTER_CUSTOMER_PINCODE: "421201",
            CNTRMASTER_SERVICE_PROVIDER: "UHC",
            CNTRMASTER_CENTER_GRADE: "B",
            CNTRMASTER_DIAGNOSTIC_CENTER: "Model Diagnostic Centre",
            CNTRMASTER_DC_ADDRESS1:
              "3 4 5  Ground Floor  Shree Navdurga Premises Co-op.  Phase-2  Near Old Model College  MIDC  Dombivali (East)  Maharashtra",
            CNTRMASTER_DC_ADDRESS2:
              "3 4 5  Ground Floor  Shree Navdurga Premises Co-op.  Phase-2  Near Old Model College  MIDC  Dombivali (East)  Maharashtra",
            CNTRMASTER_DC_LANDMARK: "Dombivali (E)",
            CNTRMASTER_DC_AREA: "Dombivali (E)",
            CNTRMASTER_DC_CITY: "Thane",
            CNTRMASTER_DC_PINCODE: "421201",
            CNTRMASTER_DC_STATE: "Maharashtra",
            CNTRMASTER_INT_ZONE_WISE: "West",
            CNTRMASTER_DC_MOBILENO: "N",
            CNTRMASTER_DC_FAXNO: "N",
            CNTRMASTER_DC_EMAILID: "modeldiagnostic@gmail.com",
            CNTRMASTER_DC_CONT_PERSON: "Ashwini More",
            CNTRMASTER_WORKING_HOURS: "08:00 AM - 08:00 PM",
            CNTRMASTER_OPEN_ON_SUNDAYS: "08:00 PM  02:00 PM",
            CNTRMASTER_MER: "Y",
            CNTRMASTER_PATHOLOGY: "Y",
            CNTRMASTER_ECG: "Y",
            CNTRMASTER_CHESTXRAY: "Y",
            CNTRMASTER_TMT: "N",
            CNTRMASTER_USG: "N",
            CNTRMASTER_HOMEVISIT: "Y",
            CNTRMASTER_URINE_NICOTINE: "N",
            CNTRMASTER_DECHO: "N",
            CNTRMASTER_SCANNING_FACILITY: "Y",
            CNTRMASTER_PARKING: "Y",
            CNTRMASTER_ISACTIVE: "Y",
            CNTRMASTER_CRTD_ON: "Jan 7, 2022 9:27:27 PM",
          },
          {
            CNTRMASTER_SRNO: 499391,
            CNTRMASTER_CUSTOMER_PINCODE: "421201",
            CNTRMASTER_SERVICE_PROVIDER: "UHC",
            CNTRMASTER_CENTER_GRADE: "C",
            CNTRMASTER_DIAGNOSTIC_CENTER:
              "Kartik Diagnostic & Polyclinic Centre-Dombivili",
            CNTRMASTER_DC_ADDRESS1:
              "4  Jai Khodiyar Building  New Optic Lane Manpada Road Dombivali E",
            CNTRMASTER_DC_ADDRESS2: "NA",
            CNTRMASTER_DC_LANDMARK: "NA",
            CNTRMASTER_DC_AREA: "Dombivli  Maharashtra",
            CNTRMASTER_DC_CITY: "Mumbai Suburban",
            CNTRMASTER_DC_PINCODE: "421201",
            CNTRMASTER_DC_STATE: "Maharashtra",
            CNTRMASTER_INT_ZONE_WISE: "WEST",
            CNTRMASTER_STDCODE: "NA",
            CNTRMASTER_TELEPHONENO: "NA",
            CNTRMASTER_DC_MOBILENO: "NA",
            CNTRMASTER_DC_FAXNO: "NA",
            CNTRMASTER_DC_EMAILID: "NA",
            CNTRMASTER_DC_CONT_PERSON: "Dr Ramesh Jadhav",
            CNTRMASTER_DESIGNATION: "NA",
            CNTRMASTER_WORKING_HOURS: "NA",
            CNTRMASTER_OPEN_ON_SUNDAYS: "N",
            CNTRMASTER_CONTACT_PERSON1: "NA",
            CNTRMASTER_CONTACT_PERSON2: "NA",
            CNTRMASTER_MER: "Y",
            CNTRMASTER_PATHOLOGY: "Y",
            CNTRMASTER_ECG: "Y",
            CNTRMASTER_CHESTXRAY: "N",
            CNTRMASTER_TMT: "N",
            CNTRMASTER_USG: "N",
            CNTRMASTER_HOMEVISIT: "N",
            CNTRMASTER_URINE_NICOTINE: "N",
            CNTRMASTER_DECHO: "N",
            CNTRMASTER_SCANNING_FACILITY: "N",
            CNTRMASTER_ISACTIVE: "Y",
            CNTRMASTER_CRTD_ON: "Jan 7, 2022 9:27:27 PM",
          },
          {
            CNTRMASTER_SRNO: 498698,
            CNTRMASTER_CUSTOMER_PINCODE: "421201",
            CNTRMASTER_CUSTOMER_TALUK: "Kalyan",
            CNTRMASTER_CUSTOMER_DISTRICT: "Thane",
            CNTRMASTER_CUSTOMER_DIVISION: "Thane Central",
            CNTRMASTER_CUSTOMER_PO_HO: "Kalyan City H O",
            CNTRMASTER_SERVICE_PROVIDER: "UHC",
            CNTRMASTER_CENTER_GRADE: "B",
            CNTRMASTER_DIAGNOSTIC_CENTER: "Gautam Hospital",
            CNTRMASTER_DC_AREA: "Ambernath  E",
            CNTRMASTER_DC_CITY: "Thane",
            CNTRMASTER_DC_PINCODE: "421501",
            CNTRMASTER_DC_STATE: "Maharashtra",
            CNTRMASTER_INT_ZONE_WISE: "INT 2",
            CNTRMASTER_STDCODE: "95251",
            CNTRMASTER_TELEPHONENO: "2601888",
            CNTRMASTER_DC_MOBILENO: "2602173",
            CNTRMASTER_DC_FAXNO: "2602173",
            CNTRMASTER_DC_CONT_PERSON: "Dr Gautam Jatale",
            CNTRMASTER_DESIGNATION: "MD",
            CNTRMASTER_WORKING_HOURS: "9AM To 5PM",
            CNTRMASTER_OPEN_ON_SUNDAYS: "No",
            CNTRMASTER_CONTACT_PERSON1: "Dr Ramesh",
            CNTRMASTER_CONTACT_PERSON2: "Dr Gautam Chatale",
            CNTRMASTER_MER: "Y",
            CNTRMASTER_PATHOLOGY: "Y",
            CNTRMASTER_ECG: "Y",
            CNTRMASTER_CHESTXRAY: "Y",
            CNTRMASTER_TMT: "Y",
            CNTRMASTER_USG: "N",
            CNTRMASTER_HOMEVISIT: "Y",
            CNTRMASTER_URINE_NICOTINE: "N",
            CNTRMASTER_DECHO: "N",
            CNTRMASTER_SCANNING_FACILITY: "N",
            CNTRMASTER_ISACTIVE: "Y",
            CNTRMASTER_CRTD_ON: "Jan 7, 2022 9:27:27 PM",
            CNTRMASTER_HOTLINE_NO: "080 2206 8608/080 2206 8612/080 4480 4499",
          },
          {
            CNTRMASTER_SRNO: 496506,
            CNTRMASTER_CUSTOMER_PINCODE: "421201",
            CNTRMASTER_SERVICE_PROVIDER: "UHC",
            CNTRMASTER_CENTER_GRADE: "B",
            CNTRMASTER_DIAGNOSTIC_CENTER: "Model Diagnostic Center - Dombivli",
            CNTRMASTER_DC_ADDRESS1: "3 4 5 Ground Floor and First Floor",
            CNTRMASTER_DC_ADDRESS2: "Shree Nav Durga Premices",
            CNTRMASTER_DC_LANDMARK: "Co-operative Phase 2",
            CNTRMASTER_DC_AREA: "Residential area - MIDC  Dombivli(East)",
            CNTRMASTER_DC_CITY: "Thane",
            CNTRMASTER_DC_PINCODE: "421201",
            CNTRMASTER_DC_STATE: "Maharashtra",
            CNTRMASTER_STDCODE: "11",
            CNTRMASTER_TELEPHONENO: "7777024014",
            CNTRMASTER_DC_MOBILENO: "Yes",
            CNTRMASTER_DC_FAXNO: "Yes",
            CNTRMASTER_DC_EMAILID: "modeldiagnostic@gmail.com",
            CNTRMASTER_DC_CONT_PERSON: "Ms Ashwini",
            CNTRMASTER_OPEN_ON_SUNDAYS: "N",
            CNTRMASTER_CONTACT_PERSON1: "Ms Ashwini",
            CNTRMASTER_CONTACT_PERSON2: "Ms Ashwini",
            CNTRMASTER_MER: "Y",
            CNTRMASTER_PATHOLOGY: "Y",
            CNTRMASTER_ECG: "Y",
            CNTRMASTER_CHESTXRAY: "N",
            CNTRMASTER_TMT: "N",
            CNTRMASTER_USG: "N",
            CNTRMASTER_HOMEVISIT: "N",
            CNTRMASTER_URINE_NICOTINE: "Y",
            CNTRMASTER_DECHO: "N",
            CNTRMASTER_SCANNING_FACILITY: "Y",
            CNTRMASTER_ISACTIVE: "Y",
            CNTRMASTER_CRTD_ON: "Jan 7, 2022 9:27:27 PM",
          },
          {
            CNTRMASTER_SRNO: 493570,
            CNTRMASTER_CUSTOMER_PINCODE: "421201",
            CNTRMASTER_SERVICE_PROVIDER: "UHC",
            CNTRMASTER_CENTER_GRADE: "B",
            CNTRMASTER_DIAGNOSTIC_CENTER:
              "Kartik Diagnostic & Polyclinic Centre",
            CNTRMASTER_DC_ADDRESS1:
              "# 4  Jai Khodiyar Building  New Optic Lane Manpada Road Dombivali (E) Thane",
            CNTRMASTER_DC_ADDRESS2:
              "# 4  Jai Khodiyar Building  New Optic Lane Manpada Road Dombivali (E) Thane",
            CNTRMASTER_DC_LANDMARK: "Dombivali (E)",
            CNTRMASTER_DC_AREA: "Dombivali (E)",
            CNTRMASTER_DC_CITY: "Thane",
            CNTRMASTER_DC_PINCODE: "421201",
            CNTRMASTER_DC_STATE: "Maharashtra",
            CNTRMASTER_INT_ZONE_WISE: "West",
            CNTRMASTER_DC_MOBILENO: "N",
            CNTRMASTER_DC_FAXNO: "N",
            CNTRMASTER_DC_EMAILID: "drrajeshjadhav@gmail.com",
            CNTRMASTER_DC_CONT_PERSON: "Dr Rajesh",
            CNTRMASTER_WORKING_HOURS: "08:00 AM - 08:00 PM",
            CNTRMASTER_OPEN_ON_SUNDAYS: "08:00 PM  02:00 PM",
            CNTRMASTER_MER: "Y",
            CNTRMASTER_PATHOLOGY: "Y",
            CNTRMASTER_ECG: "Y",
            CNTRMASTER_CHESTXRAY: "Y",
            CNTRMASTER_TMT: "Y",
            CNTRMASTER_USG: "Y",
            CNTRMASTER_HOMEVISIT: "N",
            CNTRMASTER_URINE_NICOTINE: "N",
            CNTRMASTER_DECHO: "N",
            CNTRMASTER_SCANNING_FACILITY: "Y",
            CNTRMASTER_PARKING: "Y",
            CNTRMASTER_ISACTIVE: "Y",
            CNTRMASTER_CRTD_ON: "Jan 7, 2022 9:27:27 PM",
          },
          {
            CNTRMASTER_SRNO: 491807,
            CNTRMASTER_CUSTOMER_PINCODE: "421201",
            CNTRMASTER_CUSTOMER_TALUK: "Kalyan",
            CNTRMASTER_CUSTOMER_DISTRICT: "Thane",
            CNTRMASTER_CUSTOMER_DIVISION: "Thane Central",
            CNTRMASTER_CUSTOMER_PO_HO: "Kalyan City H O",
            CNTRMASTER_SERVICE_PROVIDER: "UHC",
            CNTRMASTER_CENTER_GRADE: "D",
            CNTRMASTER_DIAGNOSTIC_CENTER:
              "Kartik Diagnostic Centre and Polyclinic Centre",
            CNTRMASTER_DC_ADDRESS1: "Dombivili E",
            CNTRMASTER_DC_ADDRESS2: "Dombivili E",
            CNTRMASTER_DC_LANDMARK: "Dombivili  E",
            CNTRMASTER_DC_AREA: "Dombivali",
            CNTRMASTER_DC_CITY: "Thane",
            CNTRMASTER_DC_PINCODE: "421201",
            CNTRMASTER_DC_STATE: "Maharashtra",
            CNTRMASTER_INT_ZONE_WISE: "INT 2",
            CNTRMASTER_STDCODE: "251",
            CNTRMASTER_TELEPHONENO: "2426312",
            CNTRMASTER_DC_MOBILENO: "9833565182",
            CNTRMASTER_DC_CONT_PERSON: "Rajesh Jadhav",
            CNTRMASTER_DESIGNATION: "Center Head",
            CNTRMASTER_WORKING_HOURS: "8 30AM To 12PM and 7 30PM To 8 30PM",
            CNTRMASTER_OPEN_ON_SUNDAYS: "8 30am To 10pm",
            CNTRMASTER_CONTACT_PERSON1: "Rajesh Jadhav",
            CNTRMASTER_CONTACT_PERSON2: "Mr  Raju",
            CNTRMASTER_MER: "Y",
            CNTRMASTER_PATHOLOGY: "Y",
            CNTRMASTER_ECG: "Y",
            CNTRMASTER_CHESTXRAY: "Y",
            CNTRMASTER_TMT: "Y",
            CNTRMASTER_USG: "Y",
            CNTRMASTER_HOMEVISIT: "Y",
            CNTRMASTER_URINE_NICOTINE: "Y",
            CNTRMASTER_DECHO: "Y",
            CNTRMASTER_SCANNING_FACILITY: "Y",
            CNTRMASTER_ISACTIVE: "Y",
            CNTRMASTER_CRTD_ON: "Jan 7, 2022 9:27:27 PM",
            CNTRMASTER_HOTLINE_NO: "080 2206 8608/080 2206 8612/080 4480 4499",
          },
          {
            CNTRMASTER_SRNO: 491706,
            CNTRMASTER_CUSTOMER_PINCODE: "421201",
            CNTRMASTER_SERVICE_PROVIDER: "UHC",
            CNTRMASTER_CENTER_GRADE: "A",
            CNTRMASTER_DIAGNOSTIC_CENTER: "Orion Multispeciality Hospital",
            CNTRMASTER_DC_ADDRESS1:
              "Nirmal Chhaya Chs   Opp Agrawal Hall   Manapada Road   Dombivali E   Opp Agrawal Hall",
            CNTRMASTER_DC_ADDRESS2:
              "Nirmal Chhaya Chs   Opp Agrawal Hall   Manapada Road   Dombivali E   Opp Agrawal Hall",
            CNTRMASTER_DC_LANDMARK:
              "Nirmal Chhaya Chs   Opp Agrawal Hall   Manapada Road   Dombivali E   Opp Agrawal Hall",
            CNTRMASTER_DC_CITY: "Thane",
            CNTRMASTER_DC_PINCODE: "421201",
            CNTRMASTER_DC_STATE: "Maharashtra",
            CNTRMASTER_STDCODE: "251",
            CNTRMASTER_TELEPHONENO: "2436689   9224564569",
            CNTRMASTER_DC_MOBILENO: "-",
            CNTRMASTER_DC_FAXNO: "-",
            CNTRMASTER_DC_EMAILID: "orion.hospital@gmail.com",
            CNTRMASTER_DC_CONT_PERSON: "Dr.Prasanna Mahajan",
            CNTRMASTER_DESIGNATION: "Centre Head",
            CNTRMASTER_WORKING_HOURS: "09:00-20:00 -",
            CNTRMASTER_OPEN_ON_SUNDAYS: "-",
            CNTRMASTER_CONTACT_PERSON1: "Uday Kulkarni",
            CNTRMASTER_CONTACT_PERSON2: "Prasanna Mahajan",
            CNTRMASTER_MER: "Y",
            CNTRMASTER_PATHOLOGY: "Y",
            CNTRMASTER_ECG: "N",
            CNTRMASTER_CHESTXRAY: "N",
            CNTRMASTER_TMT: "N",
            CNTRMASTER_USG: "N",
            CNTRMASTER_HOMEVISIT: "N",
            CNTRMASTER_URINE_NICOTINE: "N",
            CNTRMASTER_DECHO: "N",
            CNTRMASTER_SCANNING_FACILITY: "N",
            CNTRMASTER_ISACTIVE: "Y",
            CNTRMASTER_CRTD_ON: "Jan 7, 2022 9:27:27 PM",
            CNTRMASTER_VENDOR: "NJ",
          },
          {
            CNTRMASTER_SRNO: 488359,
            CNTRMASTER_CUSTOMER_PINCODE: "421201",
            CNTRMASTER_CUSTOMER_TALUK: "Kalyan",
            CNTRMASTER_CUSTOMER_DISTRICT: "Thane",
            CNTRMASTER_CUSTOMER_DIVISION: "Thane Central",
            CNTRMASTER_CUSTOMER_PO_HO: "Kalyan City H O",
            CNTRMASTER_SERVICE_PROVIDER: "UHC",
            CNTRMASTER_CENTER_GRADE: "A",
            CNTRMASTER_DIAGNOSTIC_CENTER: "Suburban Diagnostics",
            CNTRMASTER_DC_ADDRESS1: "Next To R Mall",
            CNTRMASTER_DC_ADDRESS2: "Next To R Mall",
            CNTRMASTER_DC_LANDMARK: "Next To R Mall",
            CNTRMASTER_DC_AREA: "Thane  W",
            CNTRMASTER_DC_CITY: "Thane",
            CNTRMASTER_DC_PINCODE: "400607",
            CNTRMASTER_DC_STATE: "Maharashtra",
            CNTRMASTER_INT_ZONE_WISE: "INT 2",
            CNTRMASTER_STDCODE: "22",
            CNTRMASTER_TELEPHONENO: "2585 1052 ? 65213141",
            CNTRMASTER_DC_MOBILENO: "9167733565",
            CNTRMASTER_DC_CONT_PERSON: "Dr  Amit Kumar Pandole",
            CNTRMASTER_DESIGNATION: "Center Head",
            CNTRMASTER_WORKING_HOURS: "9AM to 9 PM",
            CNTRMASTER_OPEN_ON_SUNDAYS: "No",
            CNTRMASTER_CONTACT_PERSON1: "Dr  Amit Kumar Pandole",
            CNTRMASTER_MER: "Y",
            CNTRMASTER_PATHOLOGY: "Y",
            CNTRMASTER_ECG: "Y",
            CNTRMASTER_CHESTXRAY: "Y",
            CNTRMASTER_TMT: "Y",
            CNTRMASTER_USG: "Y",
            CNTRMASTER_HOMEVISIT: "Y",
            CNTRMASTER_URINE_NICOTINE: "N",
            CNTRMASTER_DECHO: "Y",
            CNTRMASTER_SCANNING_FACILITY: "Y",
            CNTRMASTER_ISACTIVE: "Y",
            CNTRMASTER_CRTD_ON: "Jan 7, 2022 9:27:27 PM",
            CNTRMASTER_HOTLINE_NO: "080 2206 8608/080 2206 8612/080 4480 4499",
          },
        ],
        isMedical: "false",
        isTU: "true",
      },
    },
  ],
};
