/* eslint-disable no-unused-vars */
import { useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components/macro";
import * as mq from "../../../styles/mediaQueries";
import {
  ContentDisplaySection,
  Logo,
  NavHeaderWrapper,
  ProductName,
  SelectedProductWrapper,
} from "./EligibilityCard.style";
import Address from "./components/Address";
import PersonalDetails from "./components/PersonalDetails";
// import { BiArrowBack } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { CloseButton } from "../../../components";
import { getAnnualIncome } from "../../../components/navbar/helper";
import { amount } from "../../../utils";
import { selectICLogo } from "../../frontendBoot/frontendBoot.slice";
import OccupationDetails from "./components/OccupationDetails";
// import { useEffect } from "react";
import { usePlanDetails } from "../../riders/component/PlanCard/planCard.util";
import { sete_sum_insured } from "./Eligibility.slice";
// import useMediaQuery from "../../../custom-hooks/useMediaQuery";
const EligibilityCard = ({
  show,
  handleClose = () => {},
  quote,
  afterSubmit,
  totalPremium,
  fromComparePage,
}) => {
  const [contentToDisplay, setContentToDisplay] = useState(0);
  const dispatch = useDispatch();
  const { payFrequency, paymentOption } = usePlanDetails();
  const goBack = () => {
    if (contentToDisplay > 0) setContentToDisplay(contentToDisplay - 1);
  };

  const onClose = args => {
    setContentToDisplay(0);
    handleClose(args);
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      onShow={() => dispatch(sete_sum_insured(""))}
      className="px-1"
      centered
      css={`
        font-family: "Inter";
        font-weight: 400;
        height: 100vh;
        .modal-body {
          padding: 0px !important;
        }
        .modal-content {
          border-radius: 0.3rem;
        }
        @media (min-width: 600px) {
          .modal-dialog {
            /* max-width: 720px; */
            max-width: 600px;
          }
        }
      `}
    >
      <CloseButton onClick={onClose} />

      <Modal.Body>
        <div
          className="w-100 bg-white"
          style={{
            borderRadius: "0.3rem",
          }}
        >
          <NavHeaderWrapper
            css={`
              /* display: flex;
              align-items: center;
              justify-content: center; */
              position: relative;
              margin-bottom: 2em;
              ${mq.sm} {
                margin-bottom: 1em;
              }
            `}
          >
            {/* {contentToDisplay !== 0 && !matches && (
              <BackButton onClick={goBack} />
            )} */}
            <ProductHeader
              quote={quote}
              contentToDisplay={contentToDisplay}
              totalPremium={totalPremium}
              payFrequency={payFrequency}
              paymentOption={paymentOption}
            />
          </NavHeaderWrapper>
          <ContentDisplaySection
            className="w-100 container"
            css={`
              padding: 0 3em;
              padding-bottom: 1.5em;

              ${mq.sm} {
                padding: ${contentToDisplay === 0
                  ? "0 1em 2em 1em"
                  : "12px 1em 2em 1em"};
              }
            `}
          >
            <EligibilityPopupProgress contentToDisplay={contentToDisplay} />

            <Address
              handleSubmit={() => setContentToDisplay(1)}
              isActive={contentToDisplay === 0}
            />

            <OccupationDetails
              afterSubmit={() => setContentToDisplay(2)}
              onClose={onClose}
              goBack={goBack}
              isActive={contentToDisplay === 1}
            />

            <PersonalDetails
              isActive={contentToDisplay === 2}
              goBack={goBack}
              afterSubmit={afterSubmit}
            />
          </ContentDisplaySection>
        </div>
      </Modal.Body>
    </Modal>
  );
};

function ProductHeader({
  quote,
  totalPremium,
  payFrequency,
  paymentOption,
  contentToDisplay,
  ...props
}) {
  const {
    company_alias,
    product: { name: productName },
    term_pay_till,
    term_coverupto,
    term_sum_assured,
    // total_premium,
  } = quote;

  const logoSrc = useSelector(selectICLogo(company_alias));
  const [p1, p2, p3, ...rest] = productName?.split("-");
  return (
    <>
      <SelectedProductWrapper
        css={`
          flex: 1;
          padding: 1em 3em;

          ${mq.sm} {
            padding: ${contentToDisplay === 0 ? "1em" : "1em 1em"};
          }
        `}
        {...props}
      >
        <div
          css={`
            display: flex;
            flex-direction: column;
            margin: 0 -18px;
            padding: 5px 10px;
            width: 562px;
            background-color: #f6f7f8;
            ${mq.sm} {
              width: 100%;
              margin: 0.01em;
              height: 90px;
            }
          `}
        >
          <div
            css={`
              display: flex;
              justify-content: space-between;

              ${mq.sm} {
                display: flex;
                justify-content: space-between;
                height: 42px;
                width: auto;
              }
            `}
          >
            <Logo>
              <img
                src={logoSrc}
                alt="logo"
                css={`
                  max-height: 50px;
                  height: 90%;
                  width: 100%;
                `}
              />
            </Logo>
            <div
              css={`
                width: auto;
                height: 55px;
                padding: 12px;
                padding-top: 4px;
                border: 2px solid #6c757d;
                border-radius: 8px;
                text-align: center;
                margin-right: 10px;
                ${mq.sm} {
                  height: 45px;
                }
              `}
            >
              <div
                css={`
                  font-size: 0.9rem;
                  font-weight: 600;
                `}
              >
                {amount(totalPremium)}
              </div>
              <div
                css={`
                  font-size: 13px;
                  color: #6c757d;

                  ${mq.sm} {
                    font-size: 0.9em;
                    position: relative;
                    bottom: 4px;
                  }
                `}
              >
                {paymentOption === "Single Pay" ? "Pay Once" : payFrequency}
              </div>
            </div>
          </div>
          <div
            css={`
              position: absolute;
              left: 10.2rem;
              ${mq.sm} {
                position: relative;
                left: 0px;
              }
            `}
          >
            <ProductName
              css={`
                font-size: 15px;

                ${mq.sm} {
                  font-size: 14px;
                  display: inline-block;
                }
              `}
            >
              {p1}
            </ProductName>

            <ProductName
              css={`
                font-size: 15px;
                font-family: "Inter" !important;
                font-weight: 400; !important;
                padding-left: 0 !important;
                ${mq.sm} {
                  margin-left: 3px;
                  font-size: 12px;
                  display: inline;
                  color: var(--primary-color);
                }
              `}
            >
              {(p2 || p3) && (
                <b
                  css={`
                    display: none;
                    ${mq.sm} {
                      display: inline;
                      margin-right: -1px;
                    }
                  `}
                >
                  {"("}
                </b>
              )}
              {`${p2} ${p3 ? p3 : ""}`}
              {(p2 || p3) && (
                <b
                  css={`
                    display: none;
                    ${mq.sm} {
                      display: inline;
                      margin-left: -1px;
                    }
                  `}
                >
                  {")"}
                </b>
              )}
            </ProductName>
          </div>
        </div>
      </SelectedProductWrapper>
      <div
        css={`
          position: relative;
          bottom: 14px;
          background-color: #d0d1d2;
          height: 45px;
          margin: 0px 18px;
          ${mq.sm} {
            position: relative;
            bottom: 14px;
            width: auto;
            margin: 0px 12px;
          }
        `}
      >
        <div
          css={`
            flex: 1;
            display: flex;
            align-items: center;
            font-size: 13px;

            ${mq.sm} {
              justify-content: space-around;
              height: 25px;
            }
          `}
        >
          <div
            css={`
              margin-left: 35px;
              height: 20px;
              width: 150px;
              border-right: 2px solid #6c757d;
              margin-top: 15px;
              ${mq.sm} {
                font-size: 12px;
                display: flex;
                align-items: center;
                margin-left: 10px;
                height: 25px;
                width: 80px;
              }
            `}
          >
            <Detail heading="Life Cover">
              ₹ {getAnnualIncome(term_sum_assured)}
            </Detail>
          </div>

          <div
            css={`
              height: 20px;
              width: 170px;
              border-right: 2px solid #6c757d;
              margin-left: 30px;
              margin-top: 15px;
              ${mq.sm} {
                font-size: 12px;
                display: flex;
                align-items: center;
                // margin-bottom: 75px;
                margin-left: 3px;
                height: 25px;
                width: 95px !important;
              }
            `}
          >
            <Detail heading="Cover Upto">{term_coverupto} years</Detail>
          </div>

          <div
            css={`
              margin-left: 20px;
              margin-top: 15px;
              ${mq.sm} {
                font-size: 12px;
                display: flex;
                align-items: center;
                // margin-bottom: 75px;
                margin-left: 1px;
              }
            `}
          >
            {term_pay_till === 1 ? (
              <div
                css={`
                  font-family: "Inter";
                  font-weight: 700;
                `}
              >
                Single Pay
              </div>
            ) : (
              <Detail heading="Pay Till Age">{term_pay_till} years</Detail>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function Detail({ heading, children, ...props }) {
  return (
    <div
      css={`
        display: flex;
        gap: 0.5em;

        ${mq.sm} {
          flex-direction: column;
          gap: 0;
        }
      `}
      {...props}
    >
      <div
        css={`
          ${mq.sm} {
            color: #929296;
            font-weight: 600;
          }
        `}
      >
        {heading}:
      </div>
      <div
        css={`
          font-family: "Inter";
          font-weight: 700;
        `}
      >
        {children}
      </div>
    </div>
  );
}

export default EligibilityCard;

function EligibilityPopupProgress({ contentToDisplay, ...props }) {
  return (
    <div
      css={`
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.3em;
        ${mq.sm} {
          margin-bottom: ${contentToDisplay === 0 ? "" : "12px"};
        }
      `}
      {...props}
    >
      <Dot state={contentToDisplay >= 0 ? "past" : "future"} />
      <Line state={contentToDisplay >= 1 ? "past" : "future"} />
      <Dot state={contentToDisplay >= 1 ? "past" : "future"} />
      <Line state={contentToDisplay >= 2 ? "past" : "future"} />
      <Dot state={contentToDisplay >= 2 ? "past" : "future"} />
    </div>
  );
}

const PROGRESS_STATE_COLORS = {
  past: "var(--primary-color)",
  present: "white",
  future: "#dedede",
};

const Dot = styled.div`
  height: 0.76em;
  width: 0.76em;
  border-radius: 50%;
  background-color: ${({ state = "present" }) => PROGRESS_STATE_COLORS[state]};
`;

const Line = styled.div`
  height: 0.21em;
  width: 2em;
  border-radius: 1em;
  background-color: ${({ state = "present" }) => PROGRESS_STATE_COLORS[state]};
`;

// function BackButton(props) {
//   return (
//     <button
//       css={`
//         border: none;
//         background: none;
//         position: absolute;
//         top: 50%;
//         left: 1em;
//         transform: translateY(-50%);
//         font-size: 1.2rem;
//         color: #777;
//         ${mq.sm} {
//           top: 10%;
//           left: 0.5em;
//         }
//       `}
//       {...props}
//     >
//       <BiArrowBack />
//     </button>
//   );
// }

// <NavHeaderWrapper>
// <ul
//   className="nav nav-pills bg-white d-flex justify-content-between mx-auto "
//   role="tablist"
// >
//   <li
//     className={
//       contentToDisplay === "eligibility_details"
//         ? "active nav-item"
//         : "nav-item"
//     }
//     onClick={() => setContentToDisplay("eligibility_details")}
//   >
//     Eligibility Details
//   </li>
//   <li
//     className={
//       contentToDisplay === "personal_details"
//         ? "active nav-item"
//         : "nav-item"
//     }
//     onClick={() => setContentToDisplay("personal_details")}
//   >
//     Personal Details
//   </li>
// </ul>
// </NavHeaderWrapper>
