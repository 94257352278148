import styled from "styled-components/macro";
import { IoFunnelOutline } from "react-icons/io5";
import { BiPhoneCall, BiShareAlt } from "react-icons/bi";
import * as colors from "../../../../styles/colors";
import FilterModal from "./FilterModal";
import { Link, useLocation } from "react-router-dom";
import useUrlQuery from "../../../../custom-hooks/useUrlQuery";
import { sm } from "../../../../styles/mediaQueries";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShareQuotesCard from "../../ShareQuotes/ShareQuotesCard";
import { ModalBodyContent } from "../../../../components/navbar/TalkToUsBtn-component";
import ShareComparePopup from "../../../compare/components/ShareComparePopup";
import { FaDownload } from "react-icons/fa";
import { downloadCompare } from "../../../Landing/serviceApi";
import { download } from "../../../compare/components/DownloadCompareButton";
import { useRef } from "react";
import {
  initDownloading,
  clearDownloading,
} from "../../../compare/compare.slice";
import DotLoader from "../../../../components/CardSkeletonLoader/DotLoader/DotLoader";
import ShortListedPlansMobile from "../../Quotes/ShortListedPlansMobile";

function QuotesPageTabBar({ ...props }) {
  const [active, setActive] = useState(false);
  return (
    <div {...props}>
      <TabBar>
        <TabBar.Item id="filter" active={active} icon={<IoFunnelOutline />}>
          Filter
        </TabBar.Item>

        <div>
          <ShortListedPlansMobile />
        </div>

        <TabBar.Item id="share" icon={<BiShareAlt />}>
          Share
        </TabBar.Item>
        <TabBar.Item id="talk-to-us" icon={<BiPhoneCall />}>
          Talk To Us
        </TabBar.Item>
      </TabBar>
      <FilterModalControl active={active} setActive={setActive} />
      {/* <SortByModal /> */}
      <ShareModal />
      <ContactUsModal />
    </div>
  );
}

export default QuotesPageTabBar;

// function ShortListedQuotes({children,...props}){

// }

/**********************************Sort By***********************************/
// function SortByModal({ children, ...props }) {
//   const { checkIsActive } = useTabBar();

//   const isActive = checkIsActive("sort-by");

//   return (
//     <BottomSheet
//       open={isActive}
//       title={"Sorting Plans By"}
//       content={<SortByModalContent />}
//     />
//   );
// }

// function SortByModalContent() {
//   const options = [
//     {
//       code: "Low To High",
//       display_name: "Price Low To High",
//     },
//     {
//       code: "High To Low",
//       display_name: "Price High To Low ",
//     },
//     { code: "Claim Ratio", display_name: "Claim Ratio" },
//   ];

//   const selectedSortBy = useSelector(selectSortBy);

//   const dispatch = useDispatch();
//   const history = useHistory();
//   const enquiryId = useUrlQuery("enquiryId");
//   const handleClick = value => {
//     dispatch(setSortBy(value));
//     dispatch(sortQuotes());
//     history.push(`?enquiryId=${enquiryId}`);
//   };

//   return (
//     <div>
//       {options.map(({ code, display_name }) => (
//         <StyledLiWrapper
//           key={code}
//           name={display_name}
//           value={code}
//           id_for={code}
//           checked={code === selectedSortBy ? true : false}
//           onClick={evt => handleClick(code)}
//         />
//       ))}
//     </div>
//   );
// }

/********************************Filter************************************/
function FilterModalControl({ children, active, setActive, ...props }) {
  const { checkIsActive } = useTabBar();
  const isActive = checkIsActive("filter");

  return (
    <FilterModal
      show={isActive}
      active={active}
      setActive={setActive}
      {...props}
    />
  );
}

/***********************************Share************************************/
function ShareModal() {
  const { checkIsActive } = useTabBar();
  const [showModal, setShowModal] = useState(false);
  const [currentSection, setCurrentSection] = useState(0);
  const handleClose = () => {
    setCurrentSection(0);
    setShowModal(false);
  };

  const isActive = checkIsActive("share");

  useEffect(() => {
    setShowModal(isActive);
  }, [isActive]);
  return (
    <ShareQuotesCard
      show={showModal}
      isActive={isActive}
      handleClose={handleClose}
      currentSection={currentSection}
      setCurrentSection={setCurrentSection}
    />
  );
}
/********************************Contact Us***********************************/

function ContactUsModal() {
  const { checkIsActive } = useTabBar();
  const isActive = checkIsActive("talk-to-us");
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  useEffect(() => {
    setShowModal(isActive);
  }, [isActive]);
  return (
    <ModalBodyContent
      show={showModal}
      isActive={isActive}
      handleClose={handleClose}
    />
  );
}

/************************************/

function TabBar({ children, currentActive, ...props }) {
  return (
    <TabBarWrap {...props}>
      <TabItems>{children}</TabItems>
    </TabBarWrap>
  );
}

function useTabBar() {
  const { hash } = useLocation();

  const checkIsActive = idToCheck => hash === `#${idToCheck}`;

  const value = { checkIsActive };

  return value;
}

TabBar.Item = function Item({ id, icon, active, children, ...props }) {
  if (!id) throw new Error("missing a required argument `id` for TabItem");
  const { checkIsActive } = useTabBar();

  const isActive = checkIsActive(id);

  const enquiryId = useUrlQuery("enquiryId");

  return (
    <TabItemWrap {...props}>
      <StyledLink to={`?enquiryId=${enquiryId}#${id}`}>
        {isActive && <Indicator />}

        <div
          css={`
            font-size: 1.397em;
            color: ${colors.font1};
          `}
        >
          {icon}
        </div>
        <div
          css={`
            font-size: 0.721em;
            // font-family: ${isActive ? "Inter-Semibold" : "Inter-Regular"};
            font-weight: ${isActive ? "700" : "400"};
            font-family: "Inter";
            user-select: none;
            color: ${isActive ? colors.primary : colors.font1};
          `}
        >
          {children}
        </div>
      </StyledLink>
    </TabItemWrap>
  );
};

TabBar.ComparePage = function ComparePageItem({
  id,
  icon,
  active,
  children,
  ...props
}) {
  const enquiryId = useUrlQuery("enquiryId");

  return (
    <TabItemWrap {...props}>
      <StyledLink to={`?enquiryId=${enquiryId}#${id}`}>
        {active && <Indicator />}

        <div
          css={`
            font-size: 1.397em;
            color: ${colors.font1};
          `}
        >
          {icon}
        </div>
        <div
          css={`
            font-size: 0.721em;
            // font-family: ${active ? "Inter-Semibold" : "Inter-Regular"};
            font-weight: ${active ? "700" : "400"};
            font-family: "Inter";
            user-select: none;
            color: ${active ? colors.primary : colors.font1};
          `}
        >
          {children}
        </div>
      </StyledLink>
    </TabItemWrap>
  );
};

export function ComparePageTabBar({ isComparePage, ...props }) {
  const [show, setShow] = useState(false);
  const [active, setActive] = useState(false);
  // const { checkIsActive } = useTabBar();
  const { isDownloading } = useSelector(state => state.compare);
  const { addedRiders } = useSelector(state => state.quotes);
  const { showDifference, selectedFeatures } = useSelector(
    state => state.compare,
  );
  const ref = useRef();
  const dispatch = useDispatch();

  return (
    <div ref={ref} {...props}>
      <TabBar>
        <TabBar.ComparePage id="filter" icon={<IoFunnelOutline />}>
          Filters
        </TabBar.ComparePage>
        <TabBar.ComparePage
          id="share"
          active={show}
          onClick={e => setShow(!show)}
          icon={<BiShareAlt />}
        >
          Share
        </TabBar.ComparePage>
        <TabBar.ComparePage
          active={isDownloading}
          id="download"
          onClick={() => {
            dispatch(initDownloading());
            downloadCompare({
              selected_features: selectedFeatures,
              show_difference: showDifference,
              selected_riders: addedRiders,
            })
              .then(({ data }) => {
                download(data.data);
                dispatch(clearDownloading());
              })
              .catch(err => {
                console.error(err);
                dispatch(clearDownloading());
              });
          }}
          icon={<FaDownload />}
        >
          {isDownloading ? <DotLoader text={"Downloading"} /> : "Download"}
        </TabBar.ComparePage>
      </TabBar>
      <FilterModalControl
        isComparePage={isComparePage}
        active={active}
        setActive={setActive}
        {...props}
      />
      <ShareComparePopup showPopup={show} handleClose={() => setShow(false)} />
      {/* <ShareMobile show={show} /> */}
    </div>
  );
}

const StyledLink = styled(Link)`
  color: ${colors.font1};
  text-decoration: none;
`;

const TabBarWrap = styled.nav`
  border-radius: 10px 10px 0 0;
  box-shadow: rgb(0 0 0 / 16%) 0px 5px 15px 0px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  display: none;
  z-index: 100;
  ${sm} {
    display: block;
  }
`;

const TabItems = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
`;

const TabItemWrap = styled.li`
  flex: 1;
  list-style: none;
  text-align: center;
  padding-top: 0.37em;
  padding-bottom: 0.19em;
  color: ${colors.font1};
  position: relative;
`;

const Indicator = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  height: 0.37em;
  width: 2em;
  border-radius: 1em;
  background-color: var(--primary-color);
`;
