import { createSlice } from "@reduxjs/toolkit";
import { getLocations } from "./serviceApi";

const eligibility = createSlice({
  name: "eligibility",
  initialState: {
    occupationOptions: [],
    educationOptions: [],
    eligibilityGrid: {},
    e_sum_insured: "",
    cityOptions: [
      // {
      //   code: "1",
      //   display_name: "Delhi",
      // },
    ],
    stateOptions: [
      // {
      //   code: "1",
      //   display_name: "Delhi",
      // },
    ],
    errorMessage: "",
    isAddressFetching: false,
  },
  reducers: {
    setEducationOptions: (state, { payload }) => {
      state.educationOptions = payload;
    },
    setOccupationOptions: (state, { payload }) => {
      state.occupationOptions = payload;
    },
    setEligibilityGrid: (state, { payload }) => {
      state.eligibilityGrid = payload;
    },
    setCityOptions: (state, { payload }) => {
      state.cityOptions = payload;
    },
    setStateOptions: (state, { payload }) => {
      state.stateOptions = payload;
    },
    sete_sum_insured: (state, { payload }) => {
      state.e_sum_insured = payload;
    },
    setErrorMessage: (state, { payload }) => {
      state.errorMessage = payload;
    },
    initAddressFetching: state => {
      state.isAddressFetching = true;
    },
    clearAddressFetching: state => {
      state.isAddressFetching = false;
    },
  },
});
export const {
  setCityOptions,
  setStateOptions,
  setEducationOptions,
  sete_sum_insured,
  setOccupationOptions,
  setEligibilityGrid,
  setErrorMessage,
  initAddressFetching,
  clearAddressFetching,
} = eligibility.actions;
export const getStateandCity = req => {
  return async dispatch => {
    try {
      const { data } = await getLocations(req);
      dispatch(setCityOptions([data.city].flat()));
      dispatch(setStateOptions([data.state].flat()));
      dispatch(sete_sum_insured(data.annual_income));
      dispatch(setErrorMessage(""));
      dispatch(clearAddressFetching());
    } catch (err) {
      console.error(err);
      const { data } = err.response;
      dispatch(setErrorMessage(data.message));
      dispatch(clearAddressFetching());
    }
  };
};
export default eligibility.reducer;

export const selectEligibiltyGrid = state => state.eligibility.eligibilityGrid;
export const selectOccupationOptions = state =>
  state.eligibility.occupationOptions;
export const selectEducationOptions = state =>
  state.eligibility.educationOptions;
