import React from "react";
import { useSelector } from "react-redux";
import "styled-components/macro";
import { getAnnualIncome } from "../../../navbar/helper";
function MobileUIHeader() {
  const { selectedQuote } = useSelector(state => state.quotes);
  const { otherDetails } = useSelector(state => state.quotes.seeDetailsData);
  const [product_name, plan_name] = selectedQuote?.product?.name?.split("-");
  const {
    frontendData: { termcompanies },
  } = useSelector(state => state.frontendBoot);

  return (
    <div
      css={`
        display: flex;
        position: fixed;
        flex-wrap: wrap;
        z-index: 100;
        top: 0;
        height: 130px;
        margin-bottom: 40px;
        background: #fff;
        width: 100%;
        box-shadow: rgb(111 100 111 / 20%) 29px 7px 29px 0px;
      `}
    >
      <img
        css={`
          width: 20%;
          max-height: 3em;
          margin-top: 7px;
          margin-left: 15px;
        `}
        src={termcompanies[selectedQuote.company_alias]?.logo}
        alt="Company Logo"
      />

      <div
        css={`
          display: block;
          margin-left: 12px;
          margin-top: 8px;
          font-weight: 600;
        `}
      >
        <div>
          <p
            css={`
              margin: 0px;
            `}
          >
            {product_name}
          </p>
        </div>
        <div>
          <p
            css={`
              color: var(--primary-color);
              margin: 0px;
            `}
          >
            {plan_name}
          </p>
        </div>
      </div>

      <div
        css={`
          width: 100%;
          height: 5em;
          background: #fff;
          border-radius: 10px;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          margin: 15px;
          padding: 10px;
          display: flex;
          justify-content: space-around;
        `}
      >
        <div
          css={`
            display: flex;
            gap: 0.5em;
            flex-direction: column;
          `}
        >
          <span
            css={`
              display: flex;
              gap: 1em;
              justify-content: space-between;
            `}
          >
            <span
              css={`
                color: #4d4d4f;
                font-size: 10px;
              `}
            >
              Life Cover
            </span>{" "}
            <span
              css={`
                font-weight: bold;
                font-size: 11px;
              `}
            >
              {getAnnualIncome(otherDetails.term_sum_assured)}{" "}
            </span>
          </span>

          <span
            css={`
              display: flex;
              gap: 1em;
              justify-content: space-between;
            `}
          >
            <span
              css={`
                color: #4d4d4f;
                font-size: 10px;
              `}
            >
              Claim Ratio
            </span>{" "}
            &nbsp;
            <span
              css={`
                font-weight: bold;
                font-size: 11px;
              `}
            >
              {otherDetails.csr} %
            </span>
          </span>
        </div>
        <div
          css={`
            border: 0.5px solid grey;
          `}
        ></div>
        <div
          css={`
            display: flex;
            gap: 0.5em;
            flex-direction: column;
          `}
        >
          <span
            css={`
              display: flex;
              gap: 1em;
              justify-content: space-between;
            `}
          >
            <span
              css={`
                color: #4d4d4f;
                font-size: 10px;
              `}
            >
              Cover till age
            </span>{" "}
            <span
              css={`
                font-weight: bold;
                font-size: 11px;
              `}
            >
              {otherDetails.term_coverupto} years
            </span>
          </span>
          <span
            css={`
              display: flex;
              gap: 1em;
            `}
          >
            <span
              css={`
                color: #4d4d4f;
                font-size: 10px;
              `}
            >
              Pay till age
            </span>{" "}
            &nbsp;
            <span
              css={`
                font-weight: bold;
                font-size: 11px;
              `}
            >
              {otherDetails.pay_till_age} years
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default MobileUIHeader;
