import { useState } from "react";
import { useDispatch } from "react-redux";
import { setDiabeticFalse } from "../../../product-page/Quotes/quotes.slice";
// import styled from "styled-components";
import CardPopup from "../CardPopup";
import { SpousePopup } from "../IndiaFirst/SpousePopup";
import RiderCard from "./RiderCard";

function shouldCheckDiabetic(rider, alias) {
  const { rider_shortname } = rider;
  if (alias === "max_life" && ["CI", "CIP", "WOP"].includes(rider_shortname)) {
    return true;
  }
}

function RiderCardWithoutCovers({
  rider,
  quote: {
    product: {
      company: { alias },
      id: product_id,
    },
  },
  onPremiumClick = () => {},
  isAdded,
  isHidden,
  isRiderPremiumLoading,
  children,
  CIVariant,
  setCIVariant,
  ...props
}) {
  const [show, setShow] = useState(null);
  const dispatch = useDispatch();
  const closePopup = () => {
    setShow(null);
    dispatch(setDiabeticFalse());
  };

  const handlePremiumClick = rider => {
    if (shouldCheckDiabetic(rider, alias)) {
      setShow("diabetic");
      return;
    }

    const isIndiaFirstProduct = alias === "indiafirst_life";
    if (isIndiaFirstProduct) {
      setShow("spouse-popup");
      return;
    }

    onPremiumClick(rider);
  };

  return (
    <>
      <RiderCard
        rider={rider}
        isAdded={isAdded}
        isHidden={isHidden}
        onPremiumClick={handlePremiumClick}
        isLoading={isRiderPremiumLoading}
        product_id={product_id}
        {...props}
      >
        {children}
        <SpousePopup show={show === "spouse-popup"} onHide={closePopup} />
        <CardPopup
          show={show === "diabetic"}
          handleClose={closePopup}
          handleContinue={handlePremiumClick}
          rider={rider}
          handleCICountChange={setCIVariant}
          isLoading={isRiderPremiumLoading}
          isAdded={isAdded}
          CIVariant={CIVariant}
        />
      </RiderCard>
    </>
  );
}

export default RiderCardWithoutCovers;
