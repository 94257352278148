import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import "styled-components/macro";

import CancelCircular from "../../../../assets/svg-icons/CancelCircular";
import * as mq from "../../../../styles/mediaQueries";
import { capitalize } from "../../../../utils";

import ShareShortlistedQuotePopup from "./ShareShortlistedQuotePopup";

const ShareShortlistedQuotes = ({ show, handleClose }) => {
  const { data } = useSelector(state => state.inputForm);
  const proposerName = capitalize(data?.name?.split(" ")[0]);
  return (
    <Modal
      css={`
        & .modal-dialog {
          max-width: 650px;
        }
      `}
      show={show}
      onHide={handleClose}
      centered
    >
      <Modal.Header>
        <Modal.Title
          css={`
            font-size: 1.2rem;

            font-family: Inter;
            font-weight: 500;
            padding-right: 2.67em;
            ${mq.sm} {
              font-size: 0.8rem;
            }

            &::before {
              content: "";
              position: absolute;
              height: 30px;
              width: 10px;
              top: 1;
              left: 0;
              background: #ffcb00;
              border-radius: 0 45px 45px 0;
            }
          `}
        >
          Hi {proposerName}, Please choose the way you wish to share your Life
          Insurance Quotes.
        </Modal.Title>
        <CancelCircular
          width="27"
          onClick={handleClose}
          css={`
            position: absolute;
            right: 20px;
            top: 15px;
            ${mq.sm} {
              right: 1px;
              top: 2px;
            }
          `}
        />
      </Modal.Header>
      <Modal.Body
        css={`
          padding: 0.7rem;
          ${mq.sm} {
            padding: 0.7rem 0.2rem;
          }
        `}
      >
        <ShareShortlistedQuotePopup />
      </Modal.Body>
      <Modal.Footer
        css={`
          padding: 0;
        `}
      ></Modal.Footer>
    </Modal>
  );
};

export default ShareShortlistedQuotes;
