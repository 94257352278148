import styled from "styled-components/macro";

export const AWrapper = styled.div`
  height: 400px;
  background: red;
`;

export const AHead = styled.div`
  text-align: center;
  font-family: "Inter";
  font-weight: 700;
  font-size: 30px;
  margin-top: 30px;
  position: relative;
  & .a-logo {
    position: absolute;
    left: 0px;
    height: 40%;
    width: auto;
  }
`;
