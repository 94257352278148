import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CancelCircular from "../../assets/svg-icons/CancelCircular";
import { ErrorMsg } from "../../pages/product-page/EligibilityCard/EligibilityCard.style";
import { changeFilter } from "../../pages/product-page/Filters/Filters.slice";
import * as mq from "../../styles/mediaQueries";
import { extractNumbers, numberToWords, useAmountInput } from "../../utils";
import TextInput from "../../pages/product-page/EligibilityCard/components/TextInput/TextInput";

const CustomLifeCoverModal = ({ showCustom, setShowCustom, onChange }) => {
  const [customValue, setCustomValue] = useState();
  const { filterOptions } = useSelector(state => state.filters);
  const [customError, setCustomError] = useState();
  const [ai, setAi, annualIncomeDigits] = useAmountInput(customValue);
  const dispatch = useDispatch();
  useEffect(() => {
    setCustomError(null);
  }, []);

  return (
    <>
      <Modal show={showCustom} onHide={() => setShowCustom(false)} centered>
        <Modal.Header
          style={{
            backgroundColor: "var(--tertiary-shade)",
            fontSize: "1rem",
            fontFamily: "Inter",
            fontWeight: "500",
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <Modal.Title>Enter Desired Cover Amount</Modal.Title>

          <CancelCircular
            width="27"
            onClick={() => {
              setShowCustom(false);
            }}
            css={`
              position: absolute;
              right: 20px;
              top: 15px;
              ${mq.sm} {
                right: 1px;
                top: 2px;
              }
            `}
          />
        </Modal.Header>
        <Modal.Body>
          <form
            className="custom-cover__inner"
            onSubmit={e => {
              e.preventDefault();
              const [min] = filterOptions.term_sum_assured.map(
                item => item.min,
              );
              const [max] = filterOptions.term_sum_assured.map(
                item => item.max,
              );
              const maxCoverAmount =
                filterOptions.max_cover_amount < max
                  ? max
                  : filterOptions.max_cover_amount;
              if (customValue < min) {
                setCustomError(`Minimum Sum Assured should be ${min}`);
                return;
              } else if (customValue > maxCoverAmount) {
                setCustomError(
                  `Maximum Sum Assured should be ${maxCoverAmount}`,
                );
                return;
              } else {
                setCustomError(false);
                setShowCustom(false);
                dispatch(changeFilter({ term_sum_assured: customValue }), true);
              }
              onChange && onChange();
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <TextInput
                // autoFocus
                type="text"
                // className="custom-cover__input"
                value={ai}
                label="Enter custom amount"
                onChange={e => {
                  setCustomValue(extractNumbers(e.target.value));
                  setAi(e);
                  setCustomError(null);
                }}
                inputProps={{ maxLength: 15 }}
              />
            </div>
            <div className="mt-2">
              {customError ? (
                <ErrorMsg rel>{customError}</ErrorMsg>
              ) : (
                <div className="mx-auto">
                  {numberToWords(annualIncomeDigits)}
                </div>
              )}
            </div>

            <button type="submit" className="custom-cover__button rounded-0">
              Enter
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CustomLifeCoverModal;
