import React from "react";
import { useState } from "react";
import styled from "styled-components/macro";
import { amount } from "../../../utils";
import {
  usePlanCard,
  usePlanDetails,
} from "../../riders/component/PlanCard/planCard.util";
import PlanCardPopup from "../../riders/component/PlanCardPopup";
import ShareProposal from "../ShareProposal";
function ProposalPageFooterMobile() {
  const [showDetails, setShowDetails] = useState(false);
  let proposalFooter = true;
  const handleClose = () => setShowDetails(false);

  const { totalPremium } = usePlanCard();
  const { paymentOption, payFrequency } = usePlanDetails();
  return (
    <ProposalFooterWrapper>
      <div
        css={`
          font-family: Inter;
          font-weight: 500;
          color: #444445;
          font-size: 12px;
          align-items: center;
        `}
      >
        Total Premium Payable
        <PremiumValue>
          {amount(totalPremium)} /{" "}
          {paymentOption === "Single Pay" ? "Pay Once" : payFrequency}
        </PremiumValue>
      </div>

      <ViewDetailsButton onClick={() => setShowDetails(true)}>
        View Details
      </ViewDetailsButton>
      <ShareProposal />
      <PlanCardPopup
        isProposalFooter={proposalFooter}
        show={showDetails}
        handleClose={handleClose}
      />
    </ProposalFooterWrapper>
  );
}

export default ProposalPageFooterMobile;

const ProposalFooterWrapper = styled.div`
  background-color: #fff;
  box-shadow: rgba(111, 100, 111, 0.2) 29px 7px 29px 0px;
  bottom: 0;
  position: fixed;
  width: 100%;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
  align-items: center;
  z-index: 9;
`;
const PremiumValue = styled.div`
  font-weight: 600;
  font-size: 12.5px;
`;
const ViewDetailsButton = styled.div`
  background: #f4f4f4;
  font-size: 11px;
  font-weight: 600;
  border-radius: 5px;
  padding: 10px;
`;
