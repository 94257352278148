import styled from "styled-components";
export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 2px solid #e1dfe6;
`;

const whenSectionActive = `&::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 6px;
    top: 0;
    left: -6px;
    background: #ffcb00;
    border-radius:45px 0 0 45px;

  }`;
export const DetailSection = styled.div`
  width: 100%;
  padding: 12px;
  height: 100px;
  box-shadow: ${props =>
    props.active && " rgb(100 100 111 / 20%) 0px 3px 12px 0px"};
  position: relative;
  color: ${props => (props.active ? "var(--primary-color)" : "")};
  &:hover {
    box-shadow: rgb(100 100 111 / 20%) 0px 3px 12px 0px;
  }
  ${props => props.active && whenSectionActive};
`;
export const DetailTitle = styled.div`
  font-size: clamp(12px, 2vw, 20px);
  font-weight: 600;
  font-family: "Inter";
`;
export const DetailDescription = styled.div`
  font-family: "Inter";
  font-weight: 400;
  font-size: 13px;
  color: var(--primary-text);
`;
export const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 380px;
  background-color: var(--tertiary-shade);

  overflow: auto;
  ::-webkit-scrollbar {
    display: block;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
    margin: 16px 0 16px 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #dbdad5;
    outline: none;
    display: block;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #babac0;
  }
`;
export const ValueSection = styled.div`
  width: 100%;
  height: 100px;
  padding: 16px;
`;
export const ValueTitle = styled.div`
  color: var(--secondary-text);
  font-weight: 600;
  font-size: clamp(12px, 2vw, 18px);
  font-family: "Inter";
`;
