import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Select from "../EligibilityCard/components/Select/Select";
import { Wrapper } from "../EligibilityCard/EligibilityCard.style";
import { changeFilter } from "../Filters/Filters.slice";
import { getValue } from "../Filters/helper";
import CardModal from "./NomineeGetsModal";

const Body = handleClose => {
  const {
    filterOptions: {
      lumpsum_monthly_option: { term, increasing, percent },
      term_sum_assured,
    },
    filters,
  } = useSelector(state => state.filters);
  const [formData, setFormData] = useState({
    term_sum_assured: 10000000,
    lumpsum_percentage: "1",
    income_period: 3,
    increasing_percentage: "N",
  });
  const [error, setErrors] = useState({
    term_sum_assured: false,
    income_period: false,
    lumpsum_percentage: false,
    increasing_percentage: false,
  });
  const options = term_sum_assured.some(
    item => item.code === filters?.term_sum_assured,
  )
    ? term_sum_assured
    : [
        ...term_sum_assured,
        {
          code: filters.term_sum_assured,
          display_name: getValue(filters.term_sum_assured),
        },
      ].sort((a, b) => a.code - b.code);

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      term_sum_assured: filters.term_sum_assured,
      income_period: filters.income_period || 3,
      increasing_percentage: filters.increasing_percentage || "N",
      lumpsum_percentage: filters.lumpsum_percentage || "1",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.term_sum_assured]);
  useEffect(() => {
    if (formData?.term_sum_assured === null)
      return setErrors(prev => ({
        ...prev,
        term_sum_assured: true,
      }));
    if (formData?.income_period === null)
      return setErrors(prev => ({
        ...prev,
        income_period: true,
      }));
    if (formData?.increasing_percentage === null)
      return setErrors(prev => ({
        ...prev,
        increasing_percentage: true,
      }));
    if (formData?.lumpsum_percentage === null)
      return setErrors(prev => ({
        ...prev,
        lumpsum_percentage: true,
      }));
    else
      setErrors(prev => ({
        ...prev,
        term_sum_assured: false,
        income_period: false,
        lumpsum_percentage: false,
        increasing_percentage: false,
      }));
  }, [
    formData?.income_period,
    formData?.increasing_percentage,
    formData?.lumpsum_percentage,
    formData?.term_sum_assured,
  ]);
  // const onChange = e => {
  //   setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
  // };

  const dispatch = useDispatch();
  const handleConfirm = () => {
    if (
      Object.values(formData).includes(null) === false &&
      (term.length !== 0 || increasing.length !== 0 || percent !== 0)
    ) {
      dispatch(
        changeFilter({
          ...formData,
          term_payout_option: "LMI",
        }),
      );
      handleClose();
    } else return null;
  };
  return (
    <>
      <div className="container nominee_container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            Please choose the cover amount that you want your nominee to
            receive.
          </div>
          <div className="col-lg-6 col-md-6">
            <Wrapper fullWidth editDetails>
              <Select
                noSearch
                label={"Cover Amount"}
                name="term_sum_assured"
                options={options}
                handleChange={val =>
                  setFormData(prev => {
                    return {
                      ...prev,
                      term_sum_assured: val,
                    };
                  })
                }
                error={error.term_sum_assured && "Field is required."}
                selectedValue={formData.term_sum_assured}
              />
            </Wrapper>

            {/* <select
              className="nominee_select"
              value={formData.term_sum_assured}
              name="term_sum_assured"
              onChange={onChange}
            >
              {options.map(item => (
                <option value={item.code}>{item.display_name}</option>
              ))}
            </select>
            {error.term_sum_assured ? (
              <ErrorMsg>Field is required.</ErrorMsg>
            ) : (
              <></>
            )} */}
          </div>
          <div className="col-lg-6 col-md-6">
            What percentage of the cover amount would you like your nominee to
            receive as Lumpsum?
          </div>
          <div className="col-lg-6 col-md-6">
            <Wrapper fullWidth editDetails>
              <Select
                noSearch
                label={"Percentage"}
                name="lumpsum_percentage"
                options={percent.map(item => ({
                  code: item.toString(),
                  display_name: `${item}%`,
                }))}
                handleChange={val =>
                  setFormData(prev => {
                    return {
                      ...prev,
                      lumpsum_percentage: val,
                    };
                  })
                }
                error={error.term_sum_assured && "Field is required."}
                selectedValue={formData.lumpsum_percentage}
              />
            </Wrapper>
            {/* <select
              className="nominee_select"
              value={formData.lumpsum_percentage}
              name="lumpsum_percentage"
              onChange={onChange}
            >
              {percent.map(item => (
                <option value={item}>{item} %</option>
              ))}
            </select>{" "}
            {error.lumpsum_percentage || percent?.length === 0 ? (
              <ErrorMsg>Field is required.</ErrorMsg>
            ) : (
              <></>
            )} */}
          </div>
          <div className="col-lg-6 col-md-6">
            Choose the period that you want your nominee to receive the monthly
            income?
          </div>
          <div className="col-lg-6 col-md-6">
            <Wrapper fullWidth editDetails>
              <Select
                noSearch
                autoComplete="off"
                name="income_period"
                label={"Income Period"}
                options={term
                  .map(item => parseInt(item))
                  .sort((a, b) => a - b)
                  .map(item => ({
                    code: item,
                    display_name:
                      item === 100 ? item + " months" : item + " years",
                  }))}
                handleChange={val =>
                  setFormData(prev => {
                    return {
                      ...prev,
                      income_period: val,
                    };
                  })
                }
                error={
                  (error.income_period || term.length === 0) &&
                  "Field is required."
                }
                selectedValue={formData.income_period}
              />
            </Wrapper>
            {/* <select
              className="nominee_select"
              onChange={onChange}
              name="income_period"
              value={formData.income_period}
            >
              {term
                .map(item => parseInt(item))
                .sort((a, b) => a - b)
                .map(item => (
                  <option value={item}>
                    {item === 100 ? item + " months" : item + " years"}
                  </option>
                ))}
            </select>
            {error.income_period || term?.length === 0 ? (
              <ErrorMsg>Field is required.</ErrorMsg>
            ) : (
              <></>
            )} */}
          </div>
          <div className="col-lg-6 col-md-6">
            Would you like to increase the monthly income every year?
          </div>
          <div className="col-lg-6 col-md-6">
            <Wrapper fullWidth editDetails>
              <Select
                noSearch
                name={"increasing_percentage"}
                label={"Increasing Percentage"}
                options={increasing.map(item => ({
                  code: item === "Yes" ? "Y" : "N",
                  display_name: item,
                }))}
                handleChange={val =>
                  setFormData(prev => {
                    return {
                      ...prev,
                      increasing_percentage: val,
                    };
                  })
                }
                error={
                  (error.increasing_percentage || increasing?.length === 0) &&
                  "Field is required."
                }
                selectedValue={formData.increasing_percentage}
              />
            </Wrapper>

            {/* <select
              className="nominee_select"
              onChange={onChange}
              name={"increasing_percentage"}
              value={formData.increasing_percentage}
            >
              {increasing.map(item => (
                <option value={item[0]}>{item}</option>
              ))}
            </select>
            {error.increasing_percentage || increasing?.length === 0 ? (
              <ErrorMsg>Field is required.</ErrorMsg>
            ) : (
              <></>
            )} */}
          </div>
        </div>
        <div className="nominee_submit_wrapper">
          <button
            className="nominee_submit"
            onClick={Object.values(error).includes(true) ? null : handleConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </>
  );
};
const MonthlyPopup = ({ show, setShow, onClose, onChange }) => {
  const handleClose = () => {
    setShow(false);
    onChange && onChange();
  };
  return (
    <>
      <CardModal
        show={show}
        handleClose={handleClose}
        onClose={onClose}
        heading={
          <>
            Nominee Gets <br /> (Lumpsum + Income)
          </>
        }
        bodyContent={Body(handleClose)}
        footerContent={<></>}
      />
    </>
  );
};

export default MonthlyPopup;
