import {
  Paper,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import premium from "../../../assets/images/premium.png";
import annualCost from "../../../assets/images/annual-cost.png";
import alarm from "../../../assets/images/alarm.png";
import information from "../../../assets/images/information.png";
import investment from "../../../assets/images/investment.png";
import frequency from "../../../assets/images/frequency.png";
import wallet from "../../../assets/images/wallet.png";
import { useSelector } from "react-redux";
import CompareTableQuote from "./CompareTableQuote";
import styled from "styled-components";
import { useState } from "react";
import EligibilityCard from "../../product-page/EligibilityCard/EligibilityCard";
import { setSelectedQuoteToBuy } from "../slices/NewCompare.slice";
import { useGetAdditionalPremium } from "../../../hooks/useGetAdditionalPremium";
import CompareTableMobileQuote from "./CompareTableMobileQuote";
const CompareTable = ({ isMobile }) => {
  const { quotesList, selectedQuoteToBuy } = useSelector(
    state => state?.newCompare,
  );
  const [showEligibilityPopup, setShowEligibilityPopup] = useState(false);
  const totalPremium =
    selectedQuoteToBuy?.premium +
    useGetAdditionalPremium(selectedQuoteToBuy?.product?.id);

  return (
    <>
      <TableContainerStyled
        component={Paper}
        style={{ padding: 0, marginTop: "1.5rem" }}
      >
        <TableStyled aria-label="simple table">
          {isMobile ? (
            <></>
          ) : (
            <TableHeadStyled>
              <TableRow>
                <TableCellStyled>
                  <TableCellStackStyled spacing={1}>
                    <img
                      src={information}
                      alt="information"
                      width={20}
                      height={20}
                    />
                    <TypographyStyled variant="body2">
                      Product Details
                    </TypographyStyled>
                  </TableCellStackStyled>
                </TableCellStyled>
                <TableCellStyled>
                  <TableCellStackStyled spacing={1}>
                    <img
                      src={investment}
                      alt="investment"
                      width={20}
                      height={20}
                    />
                    <TypographyStyled variant="body2">
                      Sum Assured
                    </TypographyStyled>
                  </TableCellStackStyled>
                </TableCellStyled>
                <TableCellStyled>
                  <TableCellStackStyled spacing={1}>
                    <img
                      src={frequency}
                      alt="frequency"
                      width={20}
                      height={20}
                    />
                    <TypographyStyled variant="body2">
                      Cover till age
                    </TypographyStyled>
                  </TableCellStackStyled>
                </TableCellStyled>
                <TableCellStyled>
                  <TableCellStackStyled spacing={1}>
                    <img src={wallet} alt="wallet" width={20} height={20} />
                    <TypographyStyled variant="body2">
                      Pay till age
                    </TypographyStyled>
                  </TableCellStackStyled>
                </TableCellStyled>
                <TableCellStyled>
                  <TableCellStackStyled spacing={1}>
                    <img src={alarm} alt="alarm" width={20} height={20} />
                    <TypographyStyled variant="body2">
                      Frequency
                    </TypographyStyled>
                  </TableCellStackStyled>
                </TableCellStyled>
                <TableCellStyled>
                  <TableCellStackStyled spacing={1}>
                    <img
                      src={annualCost}
                      alt="annual-cost"
                      width={20}
                      height={20}
                    />
                    <TypographyStyled variant="body2">
                      Annual Cost per Cr
                    </TypographyStyled>
                  </TableCellStackStyled>
                </TableCellStyled>
                <TableCellStyled>
                  <TableCellStackStyled spacing={1}>
                    <img src={premium} alt="premium" width={20} height={20} />
                    <TypographyStyled variant="body2">Premium</TypographyStyled>
                  </TableCellStackStyled>
                </TableCellStyled>
              </TableRow>
            </TableHeadStyled>
          )}
          <TableBodyStyled isMobile={isMobile}>
            {isMobile
              ? quotesList.map((quote, i) => {
                  return (
                    <CompareTableMobileQuote
                      quote={quote}
                      key={i}
                      i={i}
                      setShowEligibilityPopup={setShowEligibilityPopup}
                    />
                  );
                })
              : quotesList.map((quote, i) => {
                  return (
                    <CompareTableQuote
                      quote={quote}
                      key={i}
                      i={i}
                      setShowEligibilityPopup={setShowEligibilityPopup}
                    />
                  );
                })}
          </TableBodyStyled>
        </TableStyled>
      </TableContainerStyled>
      <EligibilityCard
        show={showEligibilityPopup}
        handleClose={() => {
          setShowEligibilityPopup(false);
          setSelectedQuoteToBuy({});
        }}
        quote={selectedQuoteToBuy}
        totalPremium={totalPremium}
      />
    </>
  );
};

export default CompareTable;

const TableContainerStyled = styled(TableContainer)`
  border: none !important;
  box-shadow: 6px 12px 20px #e7f0f7 !important;
  padding: 0 !important;
`;
const TableStyled = styled(Table)`
  border: none !important;
`;
const TableHeadStyled = styled(TableHead)`
  background-color: #ebf2f6 !important;
  border-radius: 10px !important;
`;
const TableBodyStyled = styled(TableBody)`
  box-shadow: ${props =>
    props?.isMobile ? "6px 12px 20px #e7f0f7" : "none"} !important;
  justify-content: center !important;
`;
const TableCellStyled = styled(TableCell)`
  border: none !important;
  font-size: 0.8rem !important;
`;
const TableCellStackStyled = styled(Stack)`
  display: flex !important;
  align-items: center !important;
  flex-direction: row !important;
  text-align: center !important;
  line-height: 0.5rem !important;
`;
const TypographyStyled = styled(Typography)`
  font-size: 0.8rem !important;
  font-weight: 600 !important;
  color: #333 !important;
  margin-left: 5px !important;
  margin-bottom: 5px !important;
  white-space: nowrap !important;
`;
