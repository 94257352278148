import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { changeFilter } from "../Filters/Filters.slice";
import CardModal from "./NomineeGetsModal";
import { Wrapper } from "../EligibilityCard/EligibilityCard.style";
import Select from "../EligibilityCard/components/Select/Select";
import { getValue } from "../Filters/helper";

const Body = handleClose => {
  const {
    filterOptions: {
      monthly_option: { term, increasing },
      term_sum_assured,
    },
    filters,
  } = useSelector(state => state.filters);
  const [formData, setFormData] = useState({
    income_period: 3,
    term_sum_assured: filters.term_sum_assured,
    increasing_percentage: "Y",
  });
  const [error, setError] = useState({
    term_sum_assured: false,
    income_period: false,
    increasing_percentage: false,
  });

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      term_sum_assured: filters.term_sum_assured,
      increasing_percentage: filters.increasing_percentage || "N",
      income_period: parseInt(filters.income_period) || 3,
    }));
  }, [
    filters.term_sum_assured,
    filters.income_period,
    filters.increasing_percentage,
  ]);
  const options = term_sum_assured.some(
    item => item.code === filters?.term_sum_assured,
  )
    ? term_sum_assured
    : [
        ...term_sum_assured,
        {
          code: filters.term_sum_assured,
          display_name: getValue(filters.term_sum_assured),
        },
      ].sort((a, b) => a.code - b.code);

  // const onChange = e => {
  //   setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
  // };

  useEffect(() => {
    if (formData?.term_sum_assured === null)
      return setError(prev => ({
        ...prev,
        term_sum_assured: true,
      }));
    if (formData?.income_period === null)
      return setError(prev => ({
        ...prev,
        income_period: true,
      }));
    if (formData?.increasing_percentage === null)
      return setError(prev => ({
        ...prev,
        increasing_percentage: true,
      }));
    else
      setError(prev => ({
        ...prev,
        term_sum_assured: false,
        income_period: false,
        increasing_percentage: false,
      }));
  }, [
    formData?.income_period,
    formData?.increasing_percentage,
    formData?.term_sum_assured,
  ]);

  const dispatch = useDispatch();

  const handleConfirm = () => {
    if (
      Object.values(formData).includes(null) === false &&
      (term.length !== 0 || increasing.length !== 0)
    ) {
      dispatch(
        changeFilter({
          ...formData,
          term_payout_option: "MI",
        }),
      );

      handleClose();
    } else return null;
  };
  return (
    <>
      <div className="container nominee_container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            Please choose the cover amount that you want your nominee to receive
            in monthly installments
          </div>
          <div className="col-lg-6 col-md-6">
            <Wrapper fullWidth editDetails>
              <Select
                noSearch
                name="term_sum_assured"
                label={"Cover Amount"}
                options={options}
                handleChange={val =>
                  setFormData(prev => {
                    return {
                      ...prev,
                      term_sum_assured: val,
                    };
                  })
                }
                error={error.term_sum_assured && "Field is required."}
                selectedValue={filters?.term_sum_assured}
              />
            </Wrapper>
          </div>
          {/* <select
              className="nominee_select"
              value={formData.term_sum_assured || filters.term_sum_assured}
              name="term_sum_assured"
              onChange={onChange}
            >
              {options.map(item => (
                <option value={item.code}>{item.display_name}</option>
              ))}
            </select>
            {error.term_sum_assured ? (
              <ErrorMsg>Field is required.</ErrorMsg>
            ) : (
              <></>
            )} */}

          <div className="col-lg-6 col-md-6">
            Choose the period that you want your nominee to receive the monthly
            income?
          </div>
          <div className="col-lg-6 col-md-6">
            <Wrapper fullWidth editDetails>
              <Select
                noSearch
                name="income_period"
                label={"Income Period"}
                autoComplete="off"
                options={term
                  .map(item => parseInt(item))
                  .sort((a, b) => a - b)
                  .map(item => ({
                    code: item,
                    display_name:
                      item === 100 ? item + " months" : item + " years",
                  }))}
                handleChange={val =>
                  setFormData(prev => {
                    return {
                      ...prev,
                      income_period: val,
                    };
                  })
                }
                error={error.income_period && "Field is required."}
                selectedValue={
                  formData?.income_period || filters?.income_period
                }
              />
            </Wrapper>
          </div>
          {/* <select
              className="nominee_select"
              onChange={onChange}
              name="income_period"
              value={formData.income_period}
            >
              {term
                .map(item => parseInt(item))
                .sort((a, b) => a - b)
                .map(item => (
                  <>
                    <option value={item}>
                      {item === 100 ? item + " months" : item + " years"}
                    </option>
                  </>
                ))}
            </select>
            {error.income_period ? (
              <ErrorMsg>Field is required.</ErrorMsg>
            ) : (
              <></>
            )} */}

          <div className="col-lg-6 col-md-6">
            Would you like to increase the monthly income every year?
          </div>
          <div className="col-lg-6 col-md-6">
            <Wrapper fullWidth editDetails>
              <Select
                noSearch
                name="increasing_percentage"
                label={"Increasing Income"}
                autoComplete="off"
                options={increasing.map(item => ({
                  code: item === "Yes" ? "Y" : "N",
                  display_name: item,
                }))}
                handleChange={val =>
                  setFormData(prev => {
                    return {
                      ...prev,
                      increasing_percentage: val,
                    };
                  })
                }
                error={error.increasing_percentage && "Field is required."}
                selectedValue={
                  formData?.increasing_percentage ||
                  filters?.increasing_percentage
                }
              />
            </Wrapper>
          </div>
          {/* <select
              className="nominee_select"
              onChange={onChange}
              name={"increasing_percentage"}
              value={formData.increasing_percentage}
            >
              {increasing.map(item => (
                <option value={item[0]}>{item}</option>
              ))}
            </select>
            {error.increasing_percentage ? (
              <ErrorMsg>Field is required.</ErrorMsg>
            ) : (
              <></>
            )} */}
        </div>
        <div className="nominee_submit_wrapper">
          <button className="nominee_submit" onClick={handleConfirm}>
            Confirm
          </button>
        </div>
      </div>
    </>
  );
};
const MonthlyPopup = ({ show, setShow, onChange, onClose, handleReplace }) => {
  const handleClose = () => {
    setShow(false);
    onChange && onChange();
  };
  return (
    <>
      <CardModal
        show={show}
        handleClose={handleClose}
        onClose={onClose}
        heading={
          <>
            Nominee Gets <br /> (Monthly Income)
          </>
        }
        bodyContent={Body(handleClose)}
        footerContent={<></>}
      />
    </>
  );
};

export default MonthlyPopup;
