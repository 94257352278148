import React from "react";
import styled from "styled-components";

const Title = ({ label, isQuestion }) => {
  return <TitleText isQuestion={isQuestion}>{label}</TitleText>;
};

export default Title;

export const TitleText = styled.div`
  font-size: ${props => (props.isQuestion ? "16px" : "18px")};
  color: #000;
  font-family: "Inter";
  font-weight: ${props => (props.isQuestion ? "400" : "700")};
  margin-bottom: 2px;
`;
