import {
  AgeWrapper,
  Input,
  InputWrapper,
  LabelImage,
  Radio,
  RadioLabel,
  // ErrorMsg,
} from "../../../input/component/Fieldset1.style";
import { RidersPopup } from "../RidersPopupLayout";
import { FieldWrap, Label } from "./IndiaFirstQuestions";
import male from "../../../input/icon/male.png";
import female from "../../../input/icon/female.png";
import smoker from "../../../input/icon/cigarrete.png";
import nosmoker from "../../../input/icon/no-smoking.png";
import styled from "styled-components/macro";
import { dateUtil } from "../../../input/validations";
import { useRef, useState } from "react";
import { ContinueButton } from "../PlanCard.style";
import { useSelector } from "react-redux";
import { selectTermFilters } from "../../../product-page/Filters/Filters.slice";
import { amount } from "../../../../utils";
import { useGetEnquiriesQuery } from "../../services/rider";
import { useDidMountEffect } from "../../../../custom-hooks";

function SpousePopup({
  rider,
  show,
  onHide,
  handleContinue,
  fetchRiderPremium,
  ...props
}) {
  const { data } = useGetEnquiriesQuery();
  const [age, setAge] = useState(null);
  const { term_sum_assured } = useSelector(selectTermFilters);
  const dobRef = useRef(null);

  const handleSubmit = evt => {
    evt.preventDefault();
    handleContinue && handleContinue({});
  };

  const [tobacco, setTobacco] = useState(null);

  useDidMountEffect(() => {
    if (age > 18 && tobacco) {
      fetchRiderPremium({
        extra_input: {
          secondary_dob: dobRef.current.value.split(" ").join(""),
          secondary_tobacco: tobacco,
          secondary_gender: data.data.input.gender === "M" ? "F" : "M",
          isStaff: "No",
          existing_customer: "No",
        },
      });
    }
  }, [age, tobacco]);

  return (
    <RidersPopup title="Rider name" show={show} onHide={onHide} {...props}>
      <RiderDescription>Rider Description</RiderDescription>
      <form onSubmit={handleSubmit}>
        <FieldWrap>
          <Label>Gender</Label>
          <InputWrapper>
            <Radio
              type="radio"
              name="gender"
              value="M"
              id="gender_male"
              checked={data?.data.input.gender === "F"}
              readOnly
            />
            <RadioButton
              htmlFor="gender_male"
              style={{ pointerEvents: "none" }}
            >
              <LabelImage
                src={male}
                css={`
                  margin: 0;
                `}
              />
              <span>Male</span>
            </RadioButton>
            <Radio
              type="radio"
              name="gender"
              value="F"
              id="gender_female"
              readOnly
              checked={data?.data.input.gender === "M"}
            />
            <RadioButton
              htmlFor="gender_female"
              style={{ pointerEvents: "none" }}
            >
              <LabelImage
                src={female}
                css={`
                  margin: 0;
                `}
              />
              <span>Female</span>
            </RadioButton>
          </InputWrapper>
        </FieldWrap>
        <FieldWrap>
          <Label>Tobacco Habit</Label>
          <InputWrapper>
            <Radio
              type="radio"
              name="smoker"
              value="S"
              id="smoker_yes"
              checked={tobacco === "S"}
              onChange={evt => setTobacco(evt.target.checked ? "S" : "NS")}
            />
            <RadioButton htmlFor="smoker_yes">
              <LabelImage src={smoker} />
              <span>Yes</span>
            </RadioButton>
            <Radio
              type="radio"
              name="smoker"
              value="NS"
              id="smoker_no"
              checked={tobacco === "NS"}
              onChange={evt => setTobacco(evt.target.checked ? "NS" : "S")}
            />
            <RadioButton htmlFor="smoker_no">
              <LabelImage src={nosmoker} />
              <span>No</span>
            </RadioButton>
          </InputWrapper>
        </FieldWrap>
        <FieldWrap>
          <Label>Date of Birth</Label>
          <InputWrapper>
            <SpusePopupInput
              type="text"
              onChange={e => {
                const age = dateUtil(e);
                setAge(age);
              }}
              placeholder="DD/MM/YYYY"
              ref={dobRef}
            />
            {age && <AgeWrapper>{age} years</AgeWrapper>}
            {age !== undefined && age < 18 && (
              <p
                css={`
                  position: absolute;
                  left: 12px;
                  padding: 0;
                  margin: 0;
                  color: #d8000c;
                  top: 100%;
                `}
              >
                Age must be 18 or above
              </p>
            )}
          </InputWrapper>
        </FieldWrap>

        <FieldWrap>
          <Label>Sum Assured</Label>
          <SpusePopupInput
            type="text"
            readOnly
            aria-readonly
            value={amount(parseInt(term_sum_assured) / 2)}
          />
        </FieldWrap>
        <ContinueButton
          type="submit"
          disabled={age === undefined || age < 18}
          css={`
            padding: 0.67em;
          `}
        >
          Contine
        </ContinueButton>
      </form>
    </RidersPopup>
  );
}

const RadioButton = styled(RadioLabel)`
  width: 7em;
`;

const SpusePopupInput = styled(Input)`
  width: 20em;
`;

const RiderDescription = styled.p`
  font-size: 0.86em;
`;

export { SpousePopup };
