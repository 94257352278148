/* eslint-disable react-hooks/exhaustive-deps */
import styled from "styled-components/macro";
import { TiPlus } from "react-icons/ti";
import { useEffect, useState } from "react";
import { AddPlanPopup } from "./components/AddPlanPopup";
import { CloseButton } from "../../components";
import {
  useGetCompareProductsQuery,
  useGetQuoteQuery,
  usePutCompareProductsMutation,
} from "../riders/services/rider";
import { useDispatch, useSelector } from "react-redux";
import { selectICLogo } from "../frontendBoot/frontendBoot.slice";
import { amount } from "../../utils";
import { removeCompareFeaturesData } from "./compare.slice";
import EligibilityCard from "../product-page/EligibilityCard/EligibilityCard";
import { useQuoteBuyNow } from "../../custom-hooks/useQuoteBuyNow";
import { images } from "../../assets/images/logo/logo";
import { ComparePageLoader } from "../../components/CardSkeletonLoader/ComparePageLoader/ComparePageLoader";
import * as mq from "../../styles/mediaQueries";
import { usePlanDetails } from "../riders/component/PlanCard/planCard.util";
import { selectTermFilters } from "../product-page/Filters/Filters.slice";
import useMediaQuery from "../../custom-hooks/useMediaQuery";
import ButtonLoader from "../../components/CardSkeletonLoader/ButtonLoader/ButtonLoader";
import { FaNotEqual } from "react-icons/fa";

function ProductCard({ product, ...props }) {
  const { product_id, differentQuote, different_quote, company_alias } =
    product;
  const { payFrequency, paymentOption } = usePlanDetails();
  const dispatch = useDispatch();
  const { selected_insurers, ...termFilters } = useSelector(selectTermFilters);
  const { data: compareProductsData } = useGetCompareProductsQuery();
  const [updateCompareQuotes] = usePutCompareProductsMutation();

  const { data, isLoading, isFetching } = useGetQuoteQuery(
    company_alias
      ? {
          company_alias,
          termFilters,
          differentQuote: (differentQuote || different_quote) === "Y",
        }
      : {
          product_id,
          differentQuote: (differentQuote || different_quote) === "Y",
        },
  );
  const matches = useMediaQuery(mq.smForHook);

  const quotes =
    data?.data === undefined
      ? []
      : data?.data?.filter(item => item?.product.id === product_id);
  const quote = quotes[0];

  const { products } = compareProductsData.data;

  useEffect(() => {
    if (!quote && !isLoading) {
      const updatedCompareQuotes = products.filter(
        product => product.product_id !== product_id,
      );

      updateCompareQuotes({ products: updatedCompareQuotes });
      dispatch(removeCompareFeaturesData(product_id));
    }
  }, [quote]);

  useEffect(() => {
    if (products.length >= 3 && matches) {
      const updatedCompareQuotes = products.filter(
        (product, index) => index < products.length - 1,
      );
      updateCompareQuotes({ products: updatedCompareQuotes });
      dispatch(
        removeCompareFeaturesData(products[products.length - 1].product_id),
      );
    }
  }, [matches]);

  if (isLoading)
    return (
      <ProductCardContainer>
        <ComparePageLoader />
      </ProductCardContainer>
    );

  if (!quote) return null;
  return (
    <ProductCardContainer {...props}>
      {(differentQuote || different_quote) === "Y" && (
        <div
          css={`
            position: absolute;
            background: #fff;
            border: 2px solid red;
            border-radius: 50%;
            padding: 0px 6px;
            top: 12px;
            left: 20px;
            color: red;
            font-size: 16px;
            z-index: 11;
          `}
          title="not equal"
        >
          <FaNotEqual />
        </div>
      )}
      <ProductCardDetails
        payFrequency={payFrequency}
        paymentOption={paymentOption}
        quote={quote}
        isLoading={isLoading}
        isFetching={isFetching}
      />
    </ProductCardContainer>
  );
}

function ProductCardDetails({
  quote,
  payFrequency,
  paymentOption,
  isLoading,
  isFetching,

  ...props
}) {
  const dispatch = useDispatch();
  const [updateCompareQuotes] = usePutCompareProductsMutation();
  // const ridersAddedForThisProduct = useSelector(
  //   selectRidersAdded(quote?.product_id),
  // );
  const { addedRiders } = useSelector(state => state.quotes);
  const { term_pay_till } = useSelector(state => state.filters.filters);

  const { data: compareProductsData } = useGetCompareProductsQuery();
  // const { filters } = useSelector(state => state.filters);
  const [showBuyNow, setShowBuyNow] = useState(false);
  const [fromComparePage, setFromComparePage] = useState(false);
  const { handleBuyNowClick } = useQuoteBuyNow({
    quoteData: quote,
    onBuyNowClick: () => {
      setShowBuyNow(true);
      setFromComparePage(true);
    },
  });

  // const { quotes } = useSelector(state => state.quotes);

  const removeQuoteFromCompare = () => {
    const updatedCompareQuotes = products.filter(
      product => product.product_id !== product_id,
    );
    updateCompareQuotes({ products: updatedCompareQuotes });
    dispatch(removeCompareFeaturesData(product_id));
  };

  const { company_alias, total_premium } = quote;

  const { name: productName, id: product_id } = quote.product;

  const logoSrcUrl = useSelector(selectICLogo(quote?.company_alias));
  const logoSrc = logoSrcUrl || images[company_alias];

  // const totalRidersPremium = ridersAddedForThisProduct.reduce(
  //   (sum, riderAdded) => sum + parseInt(riderAdded.total_premium),
  //   0,
  // );
  const getAdditionalPremium = productId => {
    if (!addedRiders[productId]) return 0;
    else
      return addedRiders[productId].reduce(function (a, b) {
        const data = { ...b };
        if (isNaN(a)) a = 0;
        if (data.total_premium === undefined) data.total_premium = 0;
        return a + data.total_premium;
      }, 0);
  };

  const totalPremium =
    parseInt(total_premium) + getAdditionalPremium(product_id);

  const { products } = compareProductsData.data;

  return (
    <ProductCardWrap {...props}>
      <CloseButton
        css={`
          box-shadow: 0 0 0.1em #00000033;
          ${mq.sm} {
            box-shadow: 0 0 0.2em 0.2em #00000033;
            top: -20px;
            right: -10px;
            font-size: 0.8rem;
          }
        `}
        onClick={removeQuoteFromCompare}
      />
      <LogoWrap>
        <Logo src={logoSrc} alt="insurance-company" />
      </LogoWrap>
      <ProductName>{productName}</ProductName>
      <PremiumButton onClick={handleBuyNowClick}>
        {isFetching ? (
          <ButtonLoader />
        ) : (
          <div
            css={`
              display: flex;
              flex: 2;
              align-items: center;
              justify-content: center;
            `}
          >
            <div>{amount(totalPremium)} </div>
            {window.innerWidth > 1260 ? (
              <div
                css={`
                  font-size: 10px;
                  display: flex;
                  margin-top: 6px;
                  margin-left: 5px;
                `}
              >
                {`${term_pay_till === 1 ? "" : payFrequency}`}
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </PremiumButton>
      <EligibilityCard
        show={showBuyNow}
        handleClose={() => setShowBuyNow(false)}
        quote={quote}
        totalPremium={totalPremium}
        fromComparePage={fromComparePage}
      />
    </ProductCardWrap>
  );
}

function SelectProductCard({ ...props }) {
  const [addPlanPopup, setAddPlanPopup] = useState(false);

  const showAddPlanPopup = () => setAddPlanPopup(true);
  const hideAddPlanPopup = () => setAddPlanPopup(false);

  return (
    <ProductCardContainer {...props}>
      <button
        css={`
          background: none;
          border: none;
          color: var(--primary-color);
          height: 100%;
          width: 100%;
          display: block;
        `}
        onClick={showAddPlanPopup}
      >
        <ProductCardWrap
          css={`
            border: 1px dashed var(--primary-color);
            box-shadow: none;
            border-radius: 6px;
            justify-content: center;
          `}
        >
          <div
            css={`
              box-shadow: 0 10px 20px rgb(211 220 232 / 52%);
              font-size: 1.37rem;
              border-radius: 50%;
              height: 2.16em;
              width: 2.16em;
              display: flex;
              align-items: center;
              justify-content: center;
            `}
          >
            <TiPlus />
          </div>
          <div
            css={`
              font-family: "Inter";
              font-weight: 700;
            `}
          >
            Add Plan
          </div>
          {/* <div
            css={`
              color: #000;
            `}
          >
            Select Insurer
          </div> */}
        </ProductCardWrap>
      </button>
      <AddPlanPopup show={addPlanPopup} onClose={hideAddPlanPopup} />
    </ProductCardContainer>
  );
}

export { ProductCard, SelectProductCard };

const ProductCardContainer = styled.div`
  border-left: 1px solid #f3f5f7;
  padding: 1.79em 2em;
  position: relative;
  ${mq.sm} {
    border: 2px solid #e6e6e6;
    border-radius: 10px;
    margin: 15px 5px;
    height: 155px;
    padding: 1.5em 0.7em;
  }
`;

const ProductCardWrap = styled.div`
  position: relative;
  /* border: 1px solid #d4d9e5; */
  /* border-radius: 12px; */
  /* box-shadow: 1px 1px 6px 1px #ebecf4; */
  text-align: center;
  /* padding: 1em; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  height: 100%;
`;

const LogoWrap = styled.div``;

const Logo = styled.img`
  max-height: 3.67em;
  ${mq.sm} {
    object-fit: contain;
    height: 4em;
    width: 9em;
  }
`;

const ProductName = styled.p`
  margin: 0;
  font-family: "Inter";
  font-weight: 700;
  ${mq.sm} {
    font-size: 0.63rem;
    min-height: 26px;
  }
`;

const PremiumButton = styled.button`
  background: none;
  border: none;
  background-color: var(--primary-color);
  color: #fff;
  font-family: "Inter";
  font-weight: 700;
  border-radius: 6px;
  height: 2.65em;
  width: 76%;
  font-size: 1.127rem;
  ${mq.sm} {
    width: 100%;
    border-radius: 3px;
    padding: 6px;
    font-size: 0.8rem;
    max-height: 2.9em;
  }
`;
