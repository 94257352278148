import "./ComparePageLoader.scss";

const ComparePageLoader = ({ noOfCards = 1 }) =>
  Array(noOfCards)
    .fill("-")
    .map((_, i) => (
      <div id="u_0_v" key={i}>
        <div className="lightui">
          <div className="lightui-shimmer">
            <div className="_1irr"></div>
            <div className="_1irs"></div>
            <div className="_1irt"></div>
          </div>
        </div>
      </div>
    ));

const AddCompareLoader = ({ noOfCards = 1 }) =>
  Array(noOfCards)
    .fill("-")
    .map((_, i) => (
      <div id="u_0_v" key={i}>
        <div className="_1iry"></div>
        <div className="_1irz"></div>
      </div>
    ));

export { ComparePageLoader, AddCompareLoader };
