import React from "react";
import "./buttonLoader.scss";
const ButtonLoader = ({ colored }) => {
  return (
    <div id={colored ? "dots-loader-colored" : "dots-loader"}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default ButtonLoader;
