import { Box, Stack, Typography, Button, Divider, Grid } from "@mui/material";
import React from "react";
import styled from "styled-components";
import premium from "../../../assets/images/premium.png";
import annualCost from "../../../assets/images/annual-cost.png";
import alarm from "../../../assets/images/alarm.png";
import frequency from "../../../assets/images/frequency.png";
import wallet from "../../../assets/images/wallet.png";
import trash from "../../../assets/images/trash.png";
import { images } from "../../../assets/images/logo/logo";
import useCompareTableQuote from "../hooks/useCompareTableQuote";
import { removeQuoteInList } from "../slices/NewCompare.slice";
import { useDispatch } from "react-redux";
import { memo } from "react";
import CustomCompareSkeletonLoaderMobile from "../../../components/CardSkeletonLoader/CustomCompareSkeletonLoader/CustomCompareSkeletonLoaderMobile";

const CompareTableMobileQuote = ({ quote, i, setShowEligibilityPopup }) => {
  const {
    isLoading,
    data,
    companyAlias,
    product,
    termSumAssured,
    termCoverupto,
    termPayTill,
    termPayFrequency,
    handlePremiumClick,
  } = useCompareTableQuote(quote, i, setShowEligibilityPopup);

  const dispatch = useDispatch();

  return isLoading && !data ? (
    <CustomCompareSkeletonLoaderMobile />
  ) : (
    <>
      <QuoteCardContainer>
        <QuoteWrapper>
          <Header>
            <Grid
              container
              spacing={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Grid
                item
                xs={8}
                sm={6}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <img
                  src={images?.[companyAlias?.code]}
                  alt="IC Logo"
                  height={21}
                  width={46}
                />
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "12px",
                    paddingLeft: "0.625rem",
                  }}
                >
                  {product?.display_name}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sm={6}
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Button
                  variant="contained"
                  size="small"
                  onClick={handlePremiumClick}
                  sx={{ whiteSpace: "nowrap" }}
                >
                  {`₹ ${Number(data?.data?.[0]?.total_premium).toLocaleString(
                    "en-IN",
                  )}`}
                </Button>
              </Grid>
            </Grid>

            <Divider
              sx={{
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
                backgroundColor: "#e7f0f7",
              }}
            />
          </Header>

          <Body>
            <Stack
              direction="row"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
            >
              <Stack
                direction="column"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <Stack direction="row" spacing={0.5}>
                  <img src={premium} alt="premium" width={11} height={11} />
                  <Typography
                    sx={{
                      fontSize: "0.6rem",
                    }}
                  >
                    Sum Assured
                  </Typography>
                </Stack>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.75rem",
                    fontWeight: "600",
                  }}
                >
                  {termSumAssured?.display_name}
                </Typography>
              </Stack>
              <Stack
                direction="column"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <Stack direction="row" spacing={0.5}>
                  <img src={frequency} alt="premium" width={11} height={11} />
                  <Typography
                    sx={{
                      fontSize: "0.6rem",
                    }}
                  >
                    Cover till age
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    fontWeight: "600",
                  }}
                >
                  {termCoverupto?.display_name}
                </Typography>
              </Stack>
              <Stack
                direction="column"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <Stack direction="row" spacing={0.5} display="flex">
                  <img src={wallet} alt="premium" width={11} height={11} />
                  <Typography
                    sx={{
                      fontSize: "0.6rem",
                    }}
                  >
                    Pay till age
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    fontWeight: "600",
                  }}
                >
                  {termPayTill?.display_name}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
            >
              <Stack
                direction="column"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1.1,
                }}
              >
                <Stack direction="row" spacing={0.5}>
                  <img src={alarm} alt="premium" width={11} height={11} />
                  <Typography
                    sx={{
                      fontSize: "0.6rem",
                    }}
                  >
                    Frequency
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    fontWeight: "600",
                  }}
                >
                  {termPayFrequency?.display_name}
                </Typography>
              </Stack>
              <Stack
                direction="column"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 2,
                }}
              >
                <Stack direction="row" spacing={0.5}>
                  <img src={annualCost} alt="premium" width={11} height={11} />
                  <Typography
                    sx={{
                      fontSize: "0.6rem",
                    }}
                  >
                    Annual Cost per Cr
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    fontWeight: "600",
                  }}
                >
                  ₹{" "}
                  {Math.round(
                    (10000000 / termSumAssured?.code) *
                      data?.data?.[0]?.total_premium,
                  )}
                </Typography>
              </Stack>
              <Stack
                direction="column"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 0,
                }}
              >
                <TrashIconWrapper>
                  <img
                    src={trash}
                    alt="trash"
                    height={16}
                    width={16}
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => dispatch(removeQuoteInList(i))}
                  />
                </TrashIconWrapper>
              </Stack>
            </Stack>
          </Body>
        </QuoteWrapper>
      </QuoteCardContainer>
    </>
  );
};

export default memo(CompareTableMobileQuote);

const QuoteCardContainer = styled(Box)`
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
  justify-content: center;
  align-items: center;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const QuoteWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 6px 12px 20px #e7f0f7;
  padding: 1rem;
`;

const Header = styled(Box)`
  width: 100%;
`;
const Body = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const TrashIconWrapper = styled(Box)`
  background-color: #e7f0f7;
  border-radius: 50%;
  padding: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
