import React, { useEffect } from "react";
import useUrlQuery from "../../custom-hooks/useUrlQuery";
import { redirectToIc } from "../riders/helpers";
import {
  useGetCartQuery,
  useGetRedirectionMutation,
} from "../riders/services/rider";
const RedirectionPage = () => {
  const enquiryId = useUrlQuery("enquiryId");

  const { data: cartData } = useGetCartQuery();
  const [getRedirection] = useGetRedirectionMutation();

  useEffect(() => {
    if (enquiryId && cartData) {
      getRedirection({
        product_id: cartData?.data?.product?.id,
        term_insurance_id: cartData?.data?.term_insurance_id,
      })
        .then(res => {
          if (res.data) {
            const { data } = res.data;
            if (
              data[0] &&
              ["R"].includes(data[0].journey_type) &&
              data[0].redirection_url
            ) {
              redirectToIc(res.data.data[0]);
              return;
            }
          }
        })
        .catch(err => console.err(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData]);

  return <div></div>;
};

export default RedirectionPage;
