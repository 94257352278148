import { Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import ButtonLoader from "../../../../components/CardSkeletonLoader/ButtonLoader/ButtonLoader";
import * as mq from "../../../../styles/mediaQueries";
import { amount } from "../../../../utils";
import { generateBI } from "../../../ProposalPage/serviceApi";
import { clearBiLoading, initBiLoading } from "../../../cart/cart.slice";
import { useFrontendData } from "../../../frontendBoot/useFrontendData";
import { useGetCartQuery, useGetQuoteQuery } from "../../services/rider";
import { ContinueButton } from "../PlanCard.style";
import { usePlanCard, usePlanDetails } from "./planCard.util";
import { useRedirection } from "../../helpers";
function PlanCard({ hideBuy, loader, load }) {
  const { selectedProduct, totalPremium, premiumLoading } = usePlanCard();
  let disabledButton = selectedProduct?.term_riders
    ?.map(item => item.premium === "0" || load === true || loader === true)
    .includes(true);

  return (
    <PlanCardWrap>
      <Header
        selectedProduct={selectedProduct}
        premiumLoading={premiumLoading}
      />
      <PlanInfoWrap>
        <PlanDetails />
        <PremiumDetails />
        {selectedProduct.product.company && (
          <InbuiltBenefits selectedProduct={selectedProduct} />
        )}
        <TotalPremium
          totalPremium={totalPremium}
          premiumLoading={premiumLoading}
        />
        {!hideBuy && <ContinueToBuy btn={disabledButton} />}
      </PlanInfoWrap>
    </PlanCardWrap>
  );
}

const PlanCardWrap = styled.div`
  box-shadow: 0 0 12px 0px #00000021;
  width: calc(100% - 1em);
  font-size: 0.76rem;
  position: sticky;
  top: 0;

  ${mq.sm} {
    width: 100%;
    box-shadow: none;
    position: unset;
    top: unset;
  }
`;

const PlanInfoWrap = styled.div`
  padding: 15px;
`;

function Header({ selectedProduct, premiumLoading }) {
  const { getCompanyLogo } = useFrontendData();
  const product = selectedProduct?.product;
  const alias = product?.company?.alias;
  const name = product?.name;
  const logoSrc = getCompanyLogo(alias);
  const { ridersList } = useSelector(state => state.quotes);
  const [planName, planVariant, planVariant2] = name?.split("-") || [
    "",
    "",
    "",
  ];
  const isWOPAdded = selectedProduct?.term_riders
    ?.map(ele => ele?.short_name || ele?.rider_shortname)
    ?.includes("WOP");
  const isACIAdded = selectedProduct?.term_riders
    ?.map(ele => ele?.short_name || ele?.rider_shortname)
    ?.includes("ACI");
  const ACIPlanName = ridersList[product?.id]?.find(
    ele => ele?.rider_shortname === "ACI",
  )?.plan_name;
  const WOPPlanName = ridersList[product?.id]?.find(
    ele => ele?.rider_shortname === "WOP",
  )?.plan_name;

  return (
    <HeaderWrap>
      <CompanyLogoWrap>
        {logoSrc && premiumLoading === false ? (
          <CompanyLogo src={logoSrc} alt="m" />
        ) : (
          <Skeleton variant="rect" height="60px" width="8.3rem" />
        )}
      </CompanyLogoWrap>
      <PlanDetailsWrap>
        <div
          css={`
            color: #333;
            margin-bottom: 0.3em;
            font-family: "Inter";
            font-weight: 700;
          `}
        >
          {planName && premiumLoading === false ? (
            planName
          ) : (
            <Skeleton
              variant="text"
              width="100px"
              sx={{
                mx: "auto",
              }}
            />
          )}
        </div>
        <div
          css={`
            color: #666;
            margin-bottom: 0.3em;
          `}
        >
          {alias === "pnb_metlife" ? (
            isACIAdded ? (
              ACIPlanName
            ) : isWOPAdded ? (
              WOPPlanName
            ) : (
              planVariant
            )
          ) : planVariant && premiumLoading === false ? (
            planVariant + " " + (planVariant2 ? planVariant2 : "")
          ) : (
            <Skeleton variant="text" width="60px" sx={{ mx: "auto" }} />
          )}
        </div>
        <DownloadBrochure />
      </PlanDetailsWrap>
    </HeaderWrap>
  );
}

function DownloadBrochure() {
  const { data: cartData, isLoading: isCartLoading } = useGetCartQuery();

  if (isCartLoading) return <p>Loading brochure...</p>;

  const { brochure_url } = cartData;

  return (
    <a href={brochure_url} target="_blank" rel="noopener noreferrer">
      <div
        css={`
          color: #333;
          text-decoration: underline;
          cursor: pointer;
        `}
      >
        Download Brochure
      </div>
    </a>
  );
}

const HeaderWrap = styled.div`
  background-color: var(--tertiary-shade);
  padding: 1em;
  display: flex;
  border-bottom: 1px solid #dedeff;
  ${mq.sm} {
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
  }
`;

const CompanyLogoWrap = styled.div`
  max-width: 8.3em;
`;

const CompanyLogo = styled.img`
  width: 100%;
`;

const PlanDetailsWrap = styled.div`
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

function PlanDetails(props) {
  const {
    coverAmount,
    coverUptoObject,
    paymentOption,
    payFrequency,
    isLoading,
  } = usePlanDetails();

  return (
    <DetailsWrap {...props}>
      <DetailTitle>Plan Details</DetailTitle>
      <div>
        <DetailRow
          label="Life Cover"
          value={coverAmount}
          isLoading={isLoading}
        />
        <DetailRow
          label="Cover Upto Age"
          value={coverUptoObject}
          isLoading={isLoading}
          year="years"
        />
        <DetailRow
          label="Payment Option"
          value={paymentOption}
          isLoading={isLoading}
        />
        <DetailRow
          label="Pay Frequency"
          value={paymentOption === "Single Pay" ? "Pay Once" : payFrequency}
          isLoading={isLoading}
        />
      </div>
    </DetailsWrap>
  );
}

function InbuiltBenefits({ selectedProduct, ...props }) {
  const {
    company: { alias },
  } = selectedProduct.product;

  const { isLoading, data } = useGetQuoteQuery({
    company_alias: alias,
    differentQuote: false,
  });

  if (isLoading) return <p>Checking inbuilt riders...</p>;

  if (!data.data[0]) return null;

  const { riders_list } = data.data[0];

  const inbuiltRiders = riders_list.filter(
    rider => rider.rider_type === "Free",
  );

  if (!inbuiltRiders.length) return null;

  return (
    <DetailsWrap {...props}>
      <DetailTitle>Inbuilt Benefits</DetailTitle>
      <div>
        {inbuiltRiders.map(rider => (
          <DetailRow label={rider.rider_name} />
        ))}
      </div>
    </DetailsWrap>
  );
}

function DetailRow({ label, value, isLoading, year }) {
  return (
    <DetailRowWrap>
      <div
        css={`
          width: 70%;
          color: #666;
        `}
      >
        {label}
      </div>
      {isLoading ? (
        <Skeleton variant="text" width="80px" />
      ) : (
        <div
          css={`
            color: #333;
            text-transform: capitalize;
            ${mq.sm} {
              font-weight: 600;
              font-size: 1em;
            }
          `}
        >
          {value} {year}
        </div>
      )}
    </DetailRowWrap>
  );
}

function PremiumDetails(props) {
  const { totalGst, basePremium, ridersAdded } = usePlanCard();

  return (
    <DetailsWrap {...props}>
      <DetailTitle>Premium Details</DetailTitle>
      <div>
        <DetailRow
          label="Base Premium"
          value={amount(basePremium)}
          isLoading={!basePremium}
        />
        {ridersAdded.map(rider => (
          <RiderRow rider={rider} />
        ))}
        <DetailRow
          label={"Goods & Service Tax"}
          value={amount(totalGst)}
          isLoading={!totalGst}
        />
      </div>
    </DetailsWrap>
  );
}

function RiderRow({ rider }) {
  return <DetailRow label={rider.name} value={amount(rider.premium)} />;
}

const DetailsWrap = styled.div`
  margin-bottom: 1em;
`;

const DetailTitle = styled.h2`
  font-size: 1rem;
  color: var(--primary-color);
  border-bottom: 1px solid #dedeff;
  padding-bottom: 0.79em;
`;

const DetailRowWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.6em;
`;

function TotalPremium({ totalPremium, premiumLoading }) {
  // const { payFrequency } = usePlanDetails();

  return (
    <TotalPremiumWrap>
      <div
        css={`
          color: #666;
        `}
      >
        Total Premium Payable
      </div>
      {premiumLoading ? (
        <Skeleton variant="text" width="60px" />
      ) : (
        <div>{amount(totalPremium)}</div>
      )}
    </TotalPremiumWrap>
  );
}

const TotalPremiumWrap = styled.div`
  background-color: var(--tertiary-shade);
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1em;
`;

export const getBi = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(initBiLoading());
      let flscodedetails = getState().proposal.distributor_summary;
      let url = "";

      const {
        data: { data },
      } = await generateBI(flscodedetails && { flscodedetails });

      url = data[0].bi_url;

      return url;
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(clearBiLoading());
    }
  };
};

function ContinueToBuy({ btn }) {
  const { isCartLoading, handleClick, cartData } = useRedirection();
  const { biLoading, redirectionLoading } = useSelector(state => state.cart);
  const { indiaFirstRiderVaildationError } = useSelector(state => state.quotes);
  if (isCartLoading || !cartData) return <p>Loading...</p>;

  return (
    <>
      <ContinueButton
        disabled={
          redirectionLoading ||
          biLoading ||
          btn ||
          indiaFirstRiderVaildationError
        }
        onClick={handleClick}
      >
        {redirectionLoading || biLoading ? <ButtonLoader /> : "CONTINUE TO BUY"}
      </ContinueButton>
    </>
  );
}

export default PlanCard;
