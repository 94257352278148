import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import useUrlQuery from "../../custom-hooks/useUrlQuery";
import { setRedirectionTriggered } from "../ProposalPage/ProposalPage.slice";
import { getBi } from "./component/PlanCard/PlanCard";
import {
  riderApi,
  useGetCartQuery,
  useGetRedirectionMutation,
} from "./services/rider";
import { useEffect } from "react";
import { setRedirectionLoading } from "../cart/cart.slice";

export function sendProductFormat(product) {
  const riders = product.riders || [];
  return {
    total_premium: product.total_premium,
    tax_amount: product.service_tax,
    term_coverupto: product.term_coverupto,
    term_pay_till: product.term_pay_till,
    term_sum_assured: product.term_sum_assured,
    premium: product.premium,
    service_tax: product.service_tax,
    term_insurance_id: product.term_insurance_id,
    product_id: product.product.id,
    riders,
  };
}
export function sendUpdatedProductFormat(product, cart) {
  const riders = cart.riders || [];

  return {
    total_premium: product?.total_premium,
    tax_amount: product?.tax_amount,
    term_coverupto: product?.term_coverupto,
    term_pay_till: product?.term_pay_till,
    term_sum_assured: product?.term_sum_assured,
    premium: product?.premium,
    service_tax: product?.tax_amount,
    term_insurance_id: product?.term_insurance_id,
    product_id: product?.product.id,
    riders,
  };
}
export function sendRiderFormat({
  sum_assured,
  policy_term,
  premiumPayingTerm,
  max_cover_amount,
  min_cover_amount,
  ...rider
}) {
  return {
    id: rider.rider_id,
    premium: rider.premium,
    term_policy_term: policy_term || rider.default_policy_term,
    term_limited_pay_term: premiumPayingTerm || rider.default_ppt || 0,
    term_sum_assured: sum_assured || rider.default_sum_assured || 0,
    service_tax: rider.tax_amount,
    total_premium: rider.net_premium || rider.total_premium,
    rider_name: rider.rider_name || rider.name,
    name: rider.rider_name || rider.name,
    rider_id: rider.rider_id,
    max_cover_amount,
    min_cover_amount,
    recalc_rider: rider.recalc_rider,
    hide_riders: rider?.hide_riders,
    extra_input: rider?.extra_input,
    rider_shortname: rider.rider_shortname || rider.short_name,
  };
}

export function riderResponseFormat(sendRider) {
  return {
    description: sendRider.description,
    name: sendRider.rider_name,
    rider_shortname: sendRider.rider_shortname,
    premium: sendRider.premium,
    rider_id: sendRider.id,
    tax_amount: sendRider.tax_amount || sendRider.service_tax,
    term_limited_pay_term: sendRider.term_limited_pay_term,
    term_policy_term: sendRider.term_policy_term,
    term_sum_assured: sendRider.term_sum_assured,
    total_premium: sendRider.total_premium,
    max_cover_amount: sendRider.max_cover_amount,
    min_cover_amount: sendRider.min_cover_amount,
  };
}

export function redirectToIc(redirectionData) {
  if (redirectionData) {
    const { redirection_url, redirection_method, redirection_params } =
      redirectionData;

    const form = document.createElement("form");
    if (redirection_method === "POST") {
      form.setAttribute("action", redirection_url);
      form.setAttribute("method", redirection_method);

      Object.entries(redirection_params).forEach(([key, value]) => {
        const inputData = document.createElement("input");
        inputData.name = key;
        inputData.value = value;
        form.appendChild(inputData);
      });

      document.body.appendChild(form);

      form.submit();

      document.body.removeChild(form);
    } else if (redirection_method === "GET") {
      window.open(redirection_url, "_self");
    }
  }
}

export function useRedirection() {
  const history = useHistory();
  const enquiryId = useUrlQuery("enquiryId");
  const [getRedirection, { isLoading }] = useGetRedirectionMutation();
  const { data: cartData, isLoading: isCartLoading } = useGetCartQuery();
  const dispatch = useDispatch();
  const { distributor_summary } = useSelector(state => state.proposal);
  const { selectedQuote } = useSelector(state => state.quotes);
  const isSell =
    selectedQuote?.journey_name === "S" ||
    selectedQuote?.journey_name === "POS";
  useEffect(() => {
    dispatch(setRedirectionLoading(isLoading));
  }, [isLoading, dispatch]);
  if (isCartLoading || !cartData) return { isCartLoading };
  const termInsuranceId = cartData?.term_insurance_id;
  const productId = cartData?.product?.id;
  const company_alias = cartData?.product?.company?.alias;

  function redirection() {
    getRedirection({
      company_alias,
      product_id: productId,
      term_insurance_id: termInsuranceId,
    }).then(res => {
      if (res.data) {
        const { data } = res.data;
        if (
          data[0] &&
          ["R", "PF"].includes(data[0].journey_type) &&
          data[0].redirection_url
        ) {
          redirectToIc(res.data.data[0]);
          return;
        } else {
          dispatch(riderApi.util.invalidateTags(["Enquiry"]));
          if (company_alias !== "pnb_metlife")
            history.push(`/proposal?enquiryId=${enquiryId}`);
        }
      } else {
        toast.error(
          res?.error?.data?.message
            ? res?.error?.data?.message
            : "Something went wrong",
          {
            hideProgressBar: true,
            toastId: "failed1",
            autoClose: 3000,
            position: "bottom-center",
            theme: "colored",
          },
        );
      }
    });
  }

  const handleClick = () => {
    if (
      company_alias === "icici_pru" ||
      company_alias === "pnb_metlife" ||
      company_alias === "tata_aia" ||
      company_alias === "hdfc_life"
    ) {
      if (isSell && company_alias === "hdfc_life") {
        if (!distributor_summary) {
          dispatch(setRedirectionTriggered(true));
          return;
        }
      }
      dispatch(getBi()).then(data => {
        if (data) {
          const link = document.createElement("a");
          link.href = data;
          link.download = "BenefitIllustration";
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          if (selectedQuote?.is_user_journey !== "N") redirection();
        }
      });
    } else {
      if (selectedQuote?.is_user_journey !== "N") redirection();
    }
  };

  return {
    handleClick,
    isCartLoading,
    cartData,
  };
}

export const ciRiders = ["CI", "CIP"];

export const isCIRider = rider => {
  if (!rider) return false;
  const { rider_shortname } = rider;

  return ciRiders.includes(rider_shortname);
};

export function calculateRidersPremium(riders = []) {
  return riders.reduce(
    (totalPremum, rider) => totalPremum + parseInt(rider.net_premium),
    0,
  );
}
