import api from "../../api/api";

export const proposalFields = () => api.get("term/proposal-fields");
export const saveProposalApi = data => api.post("term/proposals", data);
export const getProposalApi = () => api.get("term/proposals");
export const submitProposalApi = () =>
  api.post("companies/term_insurances/proposals");
export const generateBI = () => api.post("/term/generate-bi");
export const proposalUpdate = data =>
  api.post("term/proposal-update", {
    status: data,
  });
