import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  clearFilterLoading,
  setMaxCoverAmount,
  updateFilter,
} from "../../product-page/Filters/Filters.slice";
import {
  saveDifferentQuotes,
  saveQuotes,
  setAddedRiders,
  setQuotesLoading,
  setShowDifferentQuotes,
  updateRidersCollection,
} from "../../product-page/Quotes/quotes.slice";
import { riderResponseFormat } from "../helpers";
import { setData, setTermDefaultFilters } from "../../Landing/input.slice";

const { REACT_APP_API_BASE_URL: baseUrl } = process.env;

function quoteForSort(quote = {}) {
  return {
    company_alias: quote?.company_alias,
    total_premium: quote?.total_premium,
    product: {
      id: quote?.product?.id,
      company: { csr: quote?.product?.company.csr },
    },
  };
}

export const riderApi = createApi({
  reducerPath: "riderApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: headers => {
      const urlQueryStrings = new URLSearchParams(window.location.search);
      const EnquiryId = urlQueryStrings.get("enquiryId");
      headers.set("Enquiry-Id", EnquiryId);
      return headers;
    },
  }),
  tagTypes: [
    "Cart",
    "Quote",
    "Enquiry",
    "Rider",
    "DifferentQuote",
    "Compare",
    "GenerateOTP",
    "Proposal",
    "ApplicationNumber",
    "NewCompareProducts",
    "NewCompareFilters",
    "NewCompareQuotePremium",
    "RMDetails",
  ],
  endpoints: builder => ({
    getRiders: builder.query({
      query: ({ productId, termInsuranceId }) => ({
        url: `products/${productId}/insurance/${termInsuranceId}/riders`,
      }),
      transformResponse: res => res.data,
      providesTags: ["Rider"],
      onQueryStarted: async ({ productId }, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(
          updateRidersCollection({
            [productId]: data,
          }),
        );
      },
    }),
    getRiderPremium: builder.mutation({
      query: ({ productId, termInsuranceId, ...body }) => {
        return {
          url: `products/${productId}/insurance/${termInsuranceId}/riders`,
          method: "POST",
          body,
        };
      },
    }),
    getCart: builder.query({
      query: () => ({
        url: `term/cart-items`,
        method: "GET",
      }),

      transformResponse: res => ({ ...res, ...res.data }),
      providesTags: ["Cart"],
    }),
    updateCart: builder.mutation({
      query: ({ cartId, ...body }) => ({
        url: `term/cart-items/${cartId}`,
        method: "PUT",
        body,
      }),
      onQueryStarted: async (
        { riders, ...cartData },
        { dispatch, queryFulfilled },
      ) => {
        const updateResult = dispatch(
          riderApi.util.updateQueryData("getCart", undefined, draft => {
            Object.assign(draft, {
              ...cartData,
              term_riders: riders.map(riderResponseFormat),
            });
          }),
        );
        dispatch(setAddedRiders(cartData?.term_riders || {}));
        queryFulfilled.catch(() => updateResult.undo());
      },
    }),
    getRedirection: builder.mutation({
      query: body => ({
        url: `quote/redirection`,
        method: "POST",
        body,
      }),
    }),
    getQuote: builder.query({
      query: ({ differentQuote = false, company_alias, product_id }) => ({
        url: differentQuote
          ? `companies/different/life-quotes`
          : `companies/life-quotes`,
        method: "POST",
        body: { company_alias, product_id },
      }),
      providesTags: ["Quote"],
      keepUnusedDataFor: 1000000,
      onQueryStarted: async (
        { company_alias, product_id },
        { dispatch, queryFulfilled, getState },
      ) => {
        // dispatch(clearShortListedQuotesData());
        dispatch(clearFilterLoading());
        dispatch(setQuotesLoading({ [company_alias]: true }));
        const { data } = await queryFulfilled;
        const { ridersList } = getState().quotes;
        if (data) {
          const { data: quotes } = data;
          if (quotes.length) {
            dispatch(
              saveQuotes({
                [company_alias]: quotes.map(quoteForSort),
              }),
            );
            dispatch(setQuotesLoading({ [company_alias]: false }));
            let riders_list = {};
            quotes.forEach(item => {
              dispatch(setMaxCoverAmount(item?.max_cover_amount));
              if (!ridersList[item?.product?.id])
                riders_list = {
                  ...riders_list,
                  [item?.product?.id]: item?.riders_list,
                };
            });
            if (!product_id)
              dispatch(
                updateRidersCollection({
                  ...riders_list,
                }),
              );
            return;
          }
        }
        dispatch(setQuotesLoading({ [company_alias]: false }));
        dispatch(saveQuotes({ [company_alias]: null }));
      },
    }),
    getDifferentQuote: builder.query({
      query: ({ company_alias, product_id }) => ({
        url: `companies/different/life-quotes`,
        method: "POST",
        body: { company_alias, product_id },
      }),
      keepUnusedDataFor: 1000000,
      providesTags: ["DifferentQuote"],
      onQueryStarted: async (
        { company_alias },
        { dispatch, queryFulfilled },
      ) => {
        const { data } = await queryFulfilled;

        if (data) {
          const { data: quotes } = data;
          if (quotes.length) {
            dispatch(setShowDifferentQuotes(true));
            dispatch(
              saveDifferentQuotes({
                [company_alias]: quotes.map(quote => ({
                  ...quoteForSort(quote),
                  differentQuote: true,
                })),
              }),
            );
            let rider_list = {};
            quotes.map(item => {
              rider_list = {
                ...rider_list,
                [item?.product?.id]: item?.riders_list,
              };
              return item;
            });
            dispatch(
              updateRidersCollection({
                ...rider_list,
                differentQuote: true,
              }),
            );
            return;
          }
        }

        dispatch(saveDifferentQuotes({ [company_alias]: null }));
      },
    }),
    getEnquiries: builder.query({
      query: () => ({ url: `enquiries`, method: "GET" }),
      providesTags: ["Enquiry"],
      invalidatesTags: ["Compare"],
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        const {
          gender,
          dob,
          pincode,
          tobacco_habit,
          occupation,
          education,
          age_band_name,
          term_annual_income,
          selected_addons,
          shortlistedIds,
          params,
          suitability,
          ...filters
        } = data?.data?.input;
        const newData = {
          gender,
          dob,
          pincode,
          tobacco_habit,
          occupation,
          education,
          age_band_name,
          term_annual_income,
          name: data?.data.name,
          email: data?.data.email,
          mobile: data?.data.mobile,
          params,
          payment_status: data?.data?.proposals[0]?.payment_status,
          links: data?.data?.links,
          shortlistedIds: [
            ...new Set(
              (shortlistedIds ? [...shortlistedIds] : [])?.map(item => item),
            ),
          ],
          suitability,
        };

        dispatch(setData(newData));
        dispatch(setTermDefaultFilters(data?.termdefaultfilters));
        dispatch(
          updateFilter(
            filters,
            data?.termdefaultfilters,
            data?.nominee_gets_table,
          ),
        );
        dispatch(setAddedRiders(selected_addons || {}));
      },
    }),
    patchEnquiries: builder.mutation({
      query: body => ({
        url: `enquiries`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Enquiry"],
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const updateResult = dispatch(
          riderApi.util.updateQueryData("getEnquiries", undefined, draft => {
            Object.assign(draft, {
              ...args,
              input: {
                ...args.input,
                selected_insurers: args.input.selected_insurers,
              },
            });
          }),
        );
        queryFulfilled.catch(() => updateResult.undo());
      },
    }),
    shareQuote: builder.mutation({
      query: ({ page = "quotes", ...body }) => ({
        url: `quotes/term/${page === "quotes" ? "shareCQ" : "shareCQ"}`,
        method: "POST",
        body,
      }),
    }),
    putCompareProducts: builder.mutation({
      query: body => ({
        url: `term_comparisons`,
        body,
        method: "PUT",
      }),
      onQueryStarted: async ({ products }, { dispatch, queryFulfilled }) => {
        const updateCompareQuotes = dispatch(
          riderApi.util.updateQueryData(
            "getCompareProducts",
            undefined,
            draft => {
              Object.assign(draft, { data: { products } });
            },
          ),
        );

        queryFulfilled.catch(() => updateCompareQuotes.undo());
      },
    }),
    getCompareProducts: builder.query({
      query: () => ({
        url: `term_comparisons`,
        method: "GET",
      }),
    }),
    getRiderDescription: builder.query({
      query: ({ product, term_insurance }) => ({
        url: `products/${product}/insurance/${term_insurance}/riders`,
        method: "GET",
      }),
    }),
    getCompareFeatures: builder.query({
      query: ({ product_id }) => ({
        url: `term_products/${product_id}/term_compare_features`,
        method: "GET",
      }),
      providesTags: ["Compare"],
    }),
    getApplicationNumber: builder.query({
      query: () => ({
        url: "term/get-user-proposal",
        method: "GET",
      }),
      providesTags: ["ApplicationNumber"],
    }),
    getGenerateOTP: builder.mutation({
      query: ({ ...body }) => ({
        url: "renew_buy/communications",
        method: "POST",
        body,
      }),
      providesTags: ["GenerateOTP"],
    }),
    getProposalData: builder.query({
      query: () => ({
        url: "term/proposals",
        method: "GET",
      }),
      providesTags: ["Proposal"],
    }),
    getSellerInfo: builder.query({
      query: ({ token }) => ({
        url: "dashboard/details/user",
        method: "POST",
        body: {
          token,
        },
      }),
    }),
    getDistributorSummary: builder.query({
      query: () => ({
        url: "term/hdfc_distributor_summary",
        method: "GET",
      }),
    }),
    getProductsName: builder.query({
      query: ({ alias }) => ({
        url: `/term_products/${alias}/get_products`,
        method: "GET",
      }),
      providesTags: ["NewCompareProducts"],
    }),
    getCompareFilters: builder.query({
      query: ({ id }) => ({
        url: `/term_products/${id}/get_filters
    `,
        method: "GET",
      }),
      providesTags: ["NewCompareFilters"],
    }),
    getCompareQuotePremium: builder.query({
      query: ({
        company_alias,
        product_id,
        term_payout_option,
        term_sum_assured,
        term_coverupto,
        term_pay_till,
        term_pay_frequency,
      }) => ({
        url: `companies/compare/life-quotes`,
        method: "POST",
        body: {
          company_alias,
          product_id,
          term_payout_option,
          term_sum_assured,
          term_coverupto,
          term_pay_till,
          term_pay_frequency,
        },
      }),
      providesTags: ["NewCompareQuotePremium"],
    }),
    // RM Details
    getRMDetails: builder.query({
      query: () => ({
        url: "/term/tata_aia_rm_mapping",
        method: "GET",
      }),
      providesTags: ["RMDetails"],
    }),
    getWhitelistedEmails: builder.mutation({
      query: body => ({
        url: `whitelist/verify`,
        method: "POST",
        body,
      }),
      providesTags: ["WhitelistedEmails"],
    }),
  }),
});

export const {
  useGetRidersQuery,
  useUpdateCartMutation,
  useGetRiderPremiumMutation,
  useGetRedirectionMutation,
  useGetCartQuery,
  useGetQuoteQuery,
  useGetDifferentQuoteQuery,
  usePatchEnquiriesMutation,
  useGetEnquiriesQuery,
  useShareQuoteMutation,
  usePutCompareProductsMutation,
  useGetCompareProductsQuery,
  useLazyGetQuoteQuery,
  useGetRiderDescriptionQuery,
  useGetCompareFeaturesQuery,
  useGetApplicationNumberQuery,
  useGetProposalDataQuery,
  useGetGenerateOTPMutation,
  useGetSellerInfoQuery,
  useGetDistributorSummaryQuery,
  useGetProductsNameQuery,
  useGetCompareFiltersQuery,
  useGetCompareQuotePremiumQuery,
  useGetRMDetailsQuery,
  useGetWhitelistedEmailsMutation,
} = riderApi;
