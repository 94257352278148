import styled from "styled-components";
import React, { useRef } from "react";
import "styled-components/macro";
import wrong from "../../../assets/images/wrong.png";
import useOutsideClickRef from "../../../custom-hooks/useOutsideClickRef";
import { Modal } from "react-bootstrap";

function CancerPopUp({ show, handleClose }) {
  const ref = useRef();
  useOutsideClickRef(ref, () => {
    handleClose();
  });
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body
        css={`
          padding: 0;
          padding-bottom: 1em;
        `}
      >
        <div
          css={`
            width: 498px;
            display: flex;
            align-items: center;
            padding: 10px 15px;
            border-radius: 8px;
            background-color: #f3f4f9;

            @media (max-width: 768px) {
              width: 320px;
            }
          `}
        >
          <p>Cancer Benefit</p>

          <CloseButton
            onClick={() => {
              handleClose();
            }}
          >
            <img
              css={`
                height: 25px;
              `}
              src={wrong}
              alt=""
            />
          </CloseButton>
        </div>

        <Container>
          <div
            css={`
              padding-left: 30px;
            `}
          >
            <ol>
              <li
                css={`
                  margin-bottom: 12px;
                  font-size: 12px;
                  color: gray;
                  &::marker {
                    color: black;
                    background: #f3f4f9;
                    padding: 5px;
                    border-radius: 50x;
                  }
                `}
              >
                Carcinoma in situ or Malignant Cancer
              </li>

              <li
                css={`
                                margin-bottom:12px;
                                font-size:12px;
                                color:gray;
                                &::marker{
                                    color:black;
                                    backgroun#f3f4f9d
                                    padding:5px;
                                    border-radius:50x;
                                }
                                `}
              >
                Early Stage Cancers
              </li>

              <li
                css={`
                                margin-bottom:12px;
                                font-size:12px;
                                color:gray;
                                &::marker{
                                    color:black;
                                    backgroun#f3f4f9d
                                    padding:5px;
                                    border-radius:50x;
                                }
                                `}
              >
                Major Cancers
              </li>
            </ol>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default CancerPopUp;

// const PopupWrapper = styled.div`
//   width: 100%;
//   height: 100%;
//   position: fixed;
//   top: 0;
//   left: 0;
//   background-color: rgba(0, 0, 0, 0.5);
//   z-index: 99999;
// `;
// const Popup = styled.div`
//   position: absolute;
//   top: 30%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   background: #fff;
//   box-shadow: rgb(0 0 0 / 50%) 0px 5px 15px;
//   border: 1px solid rgba(0, 0, 0, 0.2);
//   max-width: 500px;
//   border-radius: 8px;

//   @media (max-width: 767px) {
//     width: 100%;
//     max-width: 320px;
//     padding: 15px;
//   }
// `;

const CloseButton = styled.button`
  width: 26px;
  height: 26px;
  position: absolute;
  right: 12px;
  top: 12px;
  border: 1px solid #dce2ea;
  border-radius: 50%;
  font-size: 16px;
  & i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
const Container = styled.div`
  padding: 10px 20px;
  height: 360px;
  overflow-y: scroll;
  & p {
    font-weight: 400;
    font-family: "pf_handbook_proregular";
    font-size: 20px;
  }
  & span {
    text-transform: capitalize;
    font-weight: 600;
  }

  @media (max-width: 767px) {
    padding: 14px 16px;
    width: 95%;
    & p {
      font-weight: 400;
      font-family: "pf_handbook_proregular";
      font-size: 18px;
      line-height: 1.4;
    }
  }
`;
