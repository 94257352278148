import { createSlice } from "@reduxjs/toolkit";
// import { setPayFrequencyOptions } from "../product-page/Filters/Filters.slice";
import { frontendBootApi } from "./serviceApi";

const frontendBoot = createSlice({
  name: "frontendBoot",
  initialState: {
    frontendData: {},
    isLoading: true,
  },
  reducers: {
    setFrontendData: (state, action) => {
      state.frontendData = action.payload;
      state.isLoading = false;
    },
    initLoader: state => {
      state.isLoading = true;
    },
  },
});

const { setFrontendData, initLoader } = frontendBoot.actions;

export const fetchFrontendData = () => {
  return async dispatch => {
    try {
      dispatch(initLoader());

      const { data } = await frontendBootApi();
      // const payFreq = data.termdefaultfilters.find(
      //   (item) => item.group_name === "Pay Frequency"
      // );
      dispatch(setFrontendData(data));
      // dispatch(setPayFrequencyOptions(payFreq.options));
    } catch (err) {
      console.error(err);
    }
  };
};
export default frontendBoot.reducer;

export const selectFrontendData = state => state.frontendBoot.frontendData;

export const selectICLogo = alias => state =>
  state.frontendBoot.frontendData.termcompanies[alias]?.logo;

export const selectCompanies = state =>
  state.frontendBoot.frontendData.termcompanies;
