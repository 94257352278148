import React from "react";
import TableCell from "@mui/material/TableCell";
import styled from "styled-components/macro";
import "./styles.scss";
import { TableRow } from "@mui/material";

const ShareLoader = ({ noOfCards = 1 }) =>
  Array(noOfCards)
    .fill("-")
    .map((_, i) => (
      <TableRow>
        <TableCell align="right">
          <div className="_3iwxz"></div>
        </TableCell>
        <TableCell
          align="left"
          sx={{
            border: "1px solid #607a8d",
            borderRadius: "10px",
            padding: "3px 16px",
            minWidth: "340px",
          }}
        >
          <div className="_3iwr"></div>
          <div className="_3iws"></div>
          <div className="_3iwt"></div>
          <div className="_3iwws"></div>
        </TableCell>

        <TableCell align="left">
          <CellValue>
            <div className="_3iwu"></div>
          </CellValue>
          <br />
          <CellDesc>
            <div className="_3iwv"></div>
          </CellDesc>
        </TableCell>
        <TableCell align="left">
          <CellValue>
            <div className="_3iww"></div>
          </CellValue>
          <br />
          <CellDesc>
            <div className="_3iwx"></div>
          </CellDesc>
        </TableCell>
        <TableCell align="left">
          <CellValue>
            <div className="_3iwy"></div>
          </CellValue>

          <br />
          <CellDesc>
            <div className="_3iwz"></div>
          </CellDesc>
        </TableCell>

        <TableCell
          align="center"
          css={`
            background: rgba(0, 75, 131, 0.14);
            border-radius: 6px;
          `}
        >
          <span
            css={`
              color: #2e4d62;
              font-size: 20px;
              font-weight: bold;
            `}
          >
            <div className="_3iwxx"></div>
          </span>
          <br />

          <div className="_3iwxy"></div>
        </TableCell>
      </TableRow>
    ));

export const ShareLoaderMobile = ({ noOfCards = 1 }) => {
  return Array(noOfCards)
    .fill("-")
    .map((_, i) => (
      <>
        <CardWrapper>
          <Upper>
            <div className="company_logo _3ir"></div>
            <span
              css={`
                text-align: left;
              `}
            >
              <div
                css={`
                  font-weight: 600;
                  font-size: 0.8em;
                `}
                className="_3is"
              ></div>
              <span
                css={`
                  text-decoration: underline;
                  color: var(--primary-color);
                `}
              >
                <div className="_3it"></div>
              </span>
            </span>
            <div className="checkbox">
              <div className="_3iu"></div>
            </div>
          </Upper>
          <HR />

          <Lower>
            <Section>
              <span className="section_name">
                <div className="_3iez"></div>
              </span>
              <span className="section_value">
                <div className="_3iv"></div>
              </span>
              <span className="section_desc">
                <div className="_3iw"></div>
              </span>
            </Section>
            <Section>
              <span className="section_name">
                <div className="_3idz"></div>
              </span>
              <span className="section_value">
                <div className="_3ix"></div>
              </span>
              <span className="section_desc">
                <div className="_3iy"></div>
              </span>
            </Section>
            <Section>
              <span className="section_name">
                <div className="_3icz"></div>
              </span>
              <span className="section_value">
                <div className="_3iz"></div>
              </span>
              <span className="section_desc">
                <div className="_3iaz"></div>
              </span>
            </Section>
            <Premium>
              <div className="_3ibz"></div>
            </Premium>
          </Lower>
        </CardWrapper>
      </>
    ));
};

const CardWrapper = styled.div`
  padding: 1.5em 1em;

  box-shadow: 0 5px 15px 0 rgb(0 0 0 / 16%);

  margin-bottom: 0 10px 13px 10px;
`;
const Upper = styled.div`
  display: flex;
  gap: 1em;
  justify-content: flex-start;
  & .checkbox {
    margin-left: auto;
  }
  & .company_logo {
    max-width: 4em;
    height: 2em;
  }
`;
const HR = styled.div`
  background: #e6e6e6;
  margin: 4px 0;
  height: 0.5px;
`;
const Lower = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Premium = styled.div`
  text-align: center;
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  & .section_name {
    font-size: 7px;
  }
  & .section_value {
    font-weight: 600;
  }
  & .section_desc {
    font-size: 7px;
  }
`;
const CellValue = styled.span`
  font-weight: bold;
  font-size: 18px;
`;
const CellDesc = styled.span`
  font-size: 13px;
`;

export default ShareLoader;
