import { Button, Stack, TableCell, TableRow } from "@mui/material";
import { images } from "../../../assets/images/logo/logo";
import trash from "../../../assets/images/trash.png";
import { removeQuoteInList } from "../slices/NewCompare.slice";
import styled from "styled-components";
import { memo } from "react";
import { Link } from "react-router-dom";
import useCompareTableQuote from "../hooks/useCompareTableQuote";
import { useDispatch } from "react-redux";
import CustomCompareSkeletonLoader from "../../../components/CardSkeletonLoader/CustomCompareSkeletonLoader/CustomCompareSkeletonLoader";

const CompareTableQuote = ({ quote, i, setShowEligibilityPopup }) => {
  const {
    isLoading,
    data,
    companyAlias,
    product,
    enquiryId,
    handleKnowYourPlanOnClick,
    termSumAssured,
    termCoverupto,
    termPayTill,
    termPayFrequency,
    handlePremiumClick,
  } = useCompareTableQuote(quote, i, setShowEligibilityPopup);
  const dispatch = useDispatch();
  return isLoading && !data ? (
    <CustomCompareSkeletonLoader />
  ) : (
    <TableRowStyled>
      <TableCellICWrapperStyled>
        <div>
          <img src={images?.[companyAlias?.code]} alt="IC Logo" />
        </div>
        <div>
          {product?.display_name}
          <div>
            <Link
              to={`/know-your-plan?enquiryId=${enquiryId}`}
              style={{ cursor: "pointer" }}
              onClick={handleKnowYourPlanOnClick}
            >
              Plan Details
            </Link>
          </div>
        </div>
      </TableCellICWrapperStyled>
      <TableCellStyled>{termSumAssured?.display_name}</TableCellStyled>
      <TableCellStyled>{termCoverupto?.display_name}</TableCellStyled>
      <TableCellStyled>{termPayTill?.display_name}</TableCellStyled>
      <TableCellStyled>{termPayFrequency?.display_name}</TableCellStyled>
      <TableCellStyled>
        ₹{" "}
        {Math.round(
          (10000000 / termSumAssured?.code) * data?.data?.[0]?.total_premium,
        )}
      </TableCellStyled>
      <TableCellButtonStyled>
        <Button
          variant="contained"
          sx={{ backgroundColor: "var(--primary-color)" }}
          onClick={handlePremiumClick}
        >
          {`₹ ${Number(data?.data?.[0]?.total_premium).toLocaleString(
            "en-IN",
          )}`}
        </Button>
        <TableCellDeleteButtonStyled
          onClick={() => {
            dispatch(removeQuoteInList(i));
          }}
        >
          <img
            src={trash}
            alt="trash"
            height={18}
            width={18}
            sx={{ cursor: "pointer" }}
          />
        </TableCellDeleteButtonStyled>
      </TableCellButtonStyled>
    </TableRowStyled>
  );
};

export default memo(CompareTableQuote);

const TableRowStyled = styled(TableRow)`
  &:last-child td,
  &:last-child th {
    border: none !important;
  }
  border-bottom: none !important;
`;
const TableCellStyled = styled(TableCell)`
  text-align: center !important;
  border: none !important;
  font-size: 0.8rem !important;
  font-weight: 600 !important;
  padding: 0;
`;
const TableCellICWrapperStyled = styled(TableCell)`
  border: none !important;
  font-size: 0.8rem !important;
  font-weight: 600 !important;
  display: flex !important;
  align-items: center;
  img {
    height: 35px;
    width: 80px;
    object-fit: contain;
    margin-right: 5px;
  }
  div {
    a {
      text-decoration: none;
      font-size: 0.7rem;
    }
  }
`;
const TableCellButtonStyled = styled(TableCell)`
  border: none !important;
  font-weight: 600 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
  button {
    background-color: #367cff !important;
    width: 6.4rem !important;
  }
`;
const TableCellDeleteButtonStyled = styled(Stack)`
  height: 30px;
  width: 30px;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ebf2f6;
  margin-left: 10px;
  cursor: pointer;
`;
