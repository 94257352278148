import React, { useState } from "react";

const RidersContext = React.createContext();

RidersContext.displayName = "RidersContext";

function RidersProvider({ children, ...props }) {
  const [ridersAdded, setRidersAdded] = useState([]);

  const addRider = riderToAdd =>
    setRidersAdded(ridersAdded => [...ridersAdded, riderToAdd]);

  const removeRider = riderToRemove =>
    setRidersAdded(ridersAdded =>
      ridersAdded.filter(
        riderAdded => riderAdded.rider_id !== riderToRemove.rider_id,
      ),
    );

  const checkRiderAdded = riderToCheck =>
    ridersAdded.some(
      riderAdded => riderAdded.rider_id === riderToCheck.rider_id,
    );

  const isRiderHidden = riderToCheck =>
    ridersAdded.some(addedRider =>
      riderToCheck.hide_riders !== null
        ? riderToCheck.hide_riders.includes(addedRider.rider_shortname)
        : false,
    );

  const refetechRiderPremium = riderToCheck =>
    ridersAdded.some(addedRider =>
      riderToCheck.recalc_rider !== null
        ? riderToCheck.recalc_rider.includes(addedRider.rider_shortname)
        : false,
    );

  const toggleRider = rider => {
    if (checkRiderAdded(rider)) {
      removeRider(rider);
      return;
    }
    addRider(rider);
  };

  const value = {
    toggleRider,
    addRider,
    removeRider,
    checkRiderAdded,
    ridersAdded,
    isRiderHidden,
    refetechRiderPremium,
  };
  return (
    <RidersContext.Provider {...props} value={value}>
      {children}
    </RidersContext.Provider>
  );
}

export function useRiderContext() {
  const context = React.useContext(RidersContext);

  if (!context)
    throw new Error("useRiderContext must be in scope with <RiderProvider/>");

  return context;
}

export default RidersProvider;
