export const addressProofOptions = [
  { code: "1023010128", display_name: "Driving License" },
  { code: "1023010129", display_name: "Passport" },
  { code: "1023010133", display_name: "Aadhar card, UID" },
  { code: "1023010131", display_name: "Voter ID Card" },
  { code: "1023010724", display_name: "Bank KYC" },
  { code: "1026010511", display_name: "Job Card by NREGA" },
];

export const residentialStatusOptions = [
  { code: "FN", display_name: "FN" },
  { code: "NRI", display_name: "NRI" },
  { code: "OCI", display_name: "OCI" },
  { code: "PIO", display_name: "PIO" },
  { code: "RI", display_name: "Resident-Others" },
];
