import { createSlice } from "@reduxjs/toolkit";
import { generateBI } from "../proposel-page/serviceApi";
import {
  getProposalApi,
  saveProposalApi,
  submitProposalApi,
} from "./serviceApi";
import { toast } from "react-toastify";
const proposal = createSlice({
  name: "proposalForm",
  initialState: {
    proposalData: {},
    redirectData: {},
    position: 0,
    currentICFormCount: 0,
    isBi: false,
    isProposalLoading: true,
    isProposalSubmitLoading: false,
    isDocumentUploadLoading: false,
    isFinalSubmitEnabled: false,
    distributor_summary: null,
    isRedirecting: false,
    doc_required: [],
    doc_length: 0,
    proposal_reference_id: "",
    isLoading: false,
  },
  reducers: {
    setProposalData: (state, { payload }) => {
      state.proposalData = { ...state.proposalData, ...payload };
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setDocRequired: (state, { payload }) => {
      state.doc_required = payload;
    },
    setDocLength: (state, { payload }) => {
      state.doc_length = payload;
    },
    setProposalRefId: (state, { payload }) => {
      state.proposal_reference_id = payload;
    },

    setFinalSubmitEnabled: (state, { payload }) => {
      state.isFinalSubmitEnabled = payload;
    },
    setRedirectData: (state, { payload }) => {
      state.redirectData = { ...state.redirectData, ...payload };
    },
    setPosition: (state, { payload }) => {
      state.position = payload;
    },
    setCurrentICFormCount: (state, { payload }) => {
      state.currentICFormCount = payload;
    },
    clearData: (state, { payload }) => {
      state.proposalData = {};
    },
    setIsBi: (state, { payload }) => {
      state.isBi = payload;
    },
    clearProposalLoading: state => {
      state.isProposalLoading = false;
    },
    initProposalLoading: state => {
      state.isProposalLoading = true;
    },
    initProposalSubmitLoading: state => {
      state.isProposalSubmitLoading = true;
    },
    clearProposalSubmitLoading: state => {
      state.isProposalSubmitLoading = false;
    },
    initDocumentUploadLoading: state => {
      state.isDocumentUploadLoading = true;
    },
    clearDocumentUploadLoading: state => {
      state.isDocumentUploadLoading = false;
    },
    setDistributorSummary: (state, action) => {
      state.distributor_summary = action.payload;
    },
    //used in rider page
    setRedirectionTriggered: (state, action) => {
      state.redirection_triggered = action.payload;
    },
  },
});
export const {
  setPosition,
  setCurrentICFormCount,
  setProposalData,
  setRedirectData,
  setSubmitEnabled,
  clearData,
  setIsBi,
  clearProposalLoading,
  initProposalLoading,
  initProposalSubmitLoading,
  clearProposalSubmitLoading,
  initDocumentUploadLoading,
  clearDocumentUploadLoading,
  setDistributorSummary,
  setRedirectionTriggered,
  setDocRequired,
  setIsLoading,
  setProposalRefId,
  setDocLength,
  setFinalSubmitEnabled,
} = proposal.actions;

export const getProposalData = () => {
  return async (dispatch, getState) => {
    try {
      const { data } = await getProposalApi();
      dispatch(setProposalData(data.data));
      if (data.data instanceof Array) {
        dispatch(clearData());
      }
      dispatch(setPosition(Object.keys(data.data).length));
      dispatch(clearProposalLoading());
    } catch (err) {
      console.error(err);
      toast.error(`${err.response.data.message}`, {
        hideProgressBar: true,
        toastId: "failed1",
        autoClose: 3000,
        position: "top-center",
        theme: "colored",
      });
    }
  };
};
export const clearProposalData = () => {
  return async (dispatch, getState) => {
    try {
      const { data } = await getProposalApi();
      dispatch(clearData(data));
      dispatch(setPosition(0));
      dispatch(setCurrentICFormCount(0));
    } catch (err) {
      console.error(err);
      toast.error(`${err.response.data.message}`, {
        hideProgressBar: true,
        toastId: "failed1",
        autoClose: 3000,
        position: "top-center",
        theme: "colored",
      });
    }
  };
};
export const saveProposalData = (req, next = () => {}, isBI) => {
  return async (dispatch, getState) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const { data } = await saveProposalApi(req);
      let url = "";
      if (isBI) {
        const {
          data: { data },
        } = await generateBI();
        dispatch(setIsBi(false));
        url = data[0].bi_url;
      }
      dispatch(setProposalData(req));
      next();
      return url;
    } catch (err) {
      console.error(err);
      toast.error(`${err.response.data.message}`, {
        hideProgressBar: true,
        toastId: "failed1",
        autoClose: 3000,
        position: "top-center",
        theme: "colored",
      });
    }
  };
};
export const submitProposalData = (req, next = () => {}) => {
  return async dispatch => {
    try {
      const { data } = await submitProposalApi(req);
      dispatch(setRedirectData(data.data[0]));
      next(data.data[0]);
      dispatch(clearProposalSubmitLoading());
    } catch (err) {
      // toast.error("Failed to save proposal.");
      toast.error(`${err.response.data.message}`, {
        hideProgressBar: true,
        toastId: "failed1",
        autoClose: 3000,
        position: "top-center",
        theme: "colored",
      });
      console.error(err);
      dispatch(clearProposalSubmitLoading());
    }
  };
};
export default proposal.reducer;
