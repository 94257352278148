import styled from "styled-components";
// { keyframes }
import * as mq from "../../../styles/mediaQueries";
// const fade = keyframes`
//   0% {
//        opacity: 0;
//     }

//      100% {
//        opacity: 1;
//     }
// `;
export const Form = styled.form`
  animation: none;
`;
export const AgeWrapper = styled.div`
  position: absolute;
  right: 16px;
`;
export const InputLabel = styled.label`
  width: 100%;

  font-family: Inter;
  font-weight: 500;
  color: ${props => (props.edit ? "#6c757d" : "#333846")};
  font-size: 14px;
  margin: 0;
  margin-bottom: 12px;
  margin-left: 15px;
  line-height: 11px;
  padding-bottom: 0px;
  padding-top: 10px;
  position: relative;
  /* &::before {
    position: absolute;
    content: "";
    width: 6px;
    height: 100%;
    background-color: var(--primary-color);

    border-radius: 0 6px 6px 0px;
    left: -16px;
    top: 2px;
  } */
  & span {
    font-weight: bolder;
    color: #24272c;
  }
`;

export const Select = styled.select`
  display: block;

  padding: 0.375rem 0.75rem;
  font-size: 12px;
  width: 266px;
  line-height: 1.6;
  color: #000;
  height: 40px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  font-size: 14px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  font-weight: 600;
  appearance: none;
  margin: ${props => (props.edit ? "0 10px" : "10px")};
  &:focus {
    outline: none;
    border: 2px solid rgb(43 71 139);
  }
`;
export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${props => (props.edit ? "50px" : "40px")};
  justify-content: ${props => props.flexEnd && "flex-end"};
  justify-content: ${props => props.flexCenter && "center"};
  margin-top: ${props => props.flexEnd && "10px"};
  position: relative;
  margin-bottom: 24px;
  padding: ${props => (props.button ? "10px" : "")};
`;
export const Radio = styled.input`
  display: none;
  &:checked + label {
    border: 2px solid rgb(43 71 139);
    color: rgb(43 71 139);
    background: #fff;
  }
`;
export const Input = styled.input`
  display: block;

  height: ${props => (props.edit ? "40px" : "40px")};
  width: ${props => (props.edit ? "100%" : "260px")};
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  line-height: 2.6;
  color: #000;
  background-size: 11px 10px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  font-weight: 600;
  margin: ${props => (props.edit ? "0 10px" : "10px")};
  &:focus {
    outline: none;
    border: 2px solid rgb(43 71 139);
  }
`;
export const ErrorMsg = styled.p`
  color: red;
  margin-left: 10px;
  margin-top: -20px;
  font-size: 12px;
  ${mq.sm} {
    font-size: 10px;
  }
`;
export const RadioLabel = styled.label`
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 10px;
  border: 1px solid lightgrey;
  transition: all 0.3s ease;
  height: ${props => (props.edit ? "40px" : "40px")};
`;
export const LabelImage = styled.img`
  height: 30px;
  margin-left: -20px;
`;
export const Button = styled.button`
  border: none;
  display: inline-flex;
  height: 40px;
  width: 120px;
  align-items: center;
  margin-top: -10px;
  cursor: pointer;
  color: ${props => (props.back ? "var(--secondary-text)" : "#fff")} !important;
  position: relative;
  padding-left: ${props => (props.padding ? "17px" : "34px")};
  padding-left: ${props => props.back && "12px"};
  background: ${props => (props.back ? "#fff" : "#0069d9")};
  text-decoration: none;
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.3s;
  border-radius: 6px;
  font-size: 16px;
  & i {
    margin-right: 8px;
  }
  &:hover {
    background-color: ${props =>
      props.back ? "#fff" : "var(--primary-color)"};
  }
`;
