import React from "react";
import Button from "@mui/material/Button";
import Modal from "react-bootstrap/Modal";
import { Grid, Stack } from "@mui/material";
import CompareFilterDropdown from "./CompareFilterDropdown";
import refresh from "../../../assets/images/refresh.png";
import { useDispatch, useSelector } from "react-redux";
import {
  setInsuranceCompany,
  resetStates,
  setCoverTillAge,
  setFrequency,
  setPayTillAge,
  setProduct,
  setSumAssured,
} from "../slices/NewCompare.slice";
import ButtonLoader from "../../../components/CardSkeletonLoader/ButtonLoader/ButtonLoader";
import useNewCompare from "../hooks/useNewCompare";
import { ButtonStyled } from "./CompareFilters";

function CompareFiltersMobile({ isFirst, show, setShow }) {
  const {
    insuranceCompany,
    product,
    sumAssured,
    coverTillAge,
    payTillAge,
    frequency,
  } = useSelector(state => state?.newCompare);

  const {
    termCompaniesList,
    productsList,
    sumAssuredList,
    coverTillAgeList,
    payTillAgeList,
    frequencyList,
    isFilterLoading,
    isProductLoading,
    checkValues,
    handleAddToList,
    isCompany,
    isProduct,
    isSumAssured,
    isCoverTillAge,
    isPayTillAge,
    isFrequency,
  } = useNewCompare();

  const closeButtonStyle = {
    fontSize: "24px", // Adjust the font size as needed
    borderBottom: "none",
    paddingBottom: "0",
  };

  const AddToListButtonStyle = {
    backgroundColor: "var(--primary-color)",
    color: "#fff",
    border: "none",
    height: "40px",
    borderRadius: "4px",
    padding: "12px 18px",
    fontSize: ".8rem",
    fontWeight: "bold",
    cursor: "pointer",
    textTransform: "none",
  };

  const resetButtonStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    height: "40px",
    textTransform: "none",
    border: "2px solid #dfdfdf",
  };

  const bodyStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "10px",
  };

  const footerStyle = {
    borderTop: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const YellowHeaderStyle = {
    content: "",
    position: "absolute",
    width: "4px",
    height: "30px",
    left: "0",
    top: "21px",
    background: "#FFA800",
    borderRadius: "8px 0 0 8px",
    transform: "rotate(180deg)",
  };

  const selectItemsStyle = {
    border: "1px solid #E3E8EC",
    borderRadius: "4px",
    padding: "4px 10px",
  };

  const handleClose = () => {
    setShow(false);
  };

  const dispatch = useDispatch();

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={closeButtonStyle}>
          <Modal.Title>
            <span style={YellowHeaderStyle}></span>
            <span style={{ fontSize: "1rem" }}>
              {isFirst ? "Add New Quote" : "Generate Quote"}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={bodyStyle}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack spacing={0} style={selectItemsStyle}>
                <>Insurance Company</>
                <CompareFilterDropdown
                  options={termCompaniesList}
                  isReset={isCompany}
                  isFullWidthOnMobile
                  previousSelectedValue={insuranceCompany?.code}
                  onChangeHandler={setInsuranceCompany}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack spacing={0} style={selectItemsStyle}>
                <>Product Name</>
                {isProductLoading ? (
                  <ButtonLoader colored />
                ) : (
                  <>
                    <CompareFilterDropdown
                      options={productsList}
                      isReset={isProduct}
                      isFullWidthOnMobile
                      previousSelectedValue={product?.code}
                      onChangeHandler={setProduct}
                    />
                  </>
                )}
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={6}>
              <Stack spacing={0} style={selectItemsStyle}>
                <>Sum Assured</>
                {isFilterLoading ? (
                  <ButtonLoader colored />
                ) : (
                  <>
                    <CompareFilterDropdown
                      options={isProduct ? sumAssuredList : []}
                      isReset={isSumAssured}
                      isProduct={isProduct}
                      previousSelectedValue={sumAssured?.code}
                      onChangeHandler={setSumAssured}
                    />
                  </>
                )}
              </Stack>
            </Grid>
            <Grid item sm={6} xs={6}>
              <Stack style={selectItemsStyle}>
                <>Cover till age </>
                {isFilterLoading ? (
                  <ButtonLoader colored />
                ) : (
                  <>
                    <CompareFilterDropdown
                      options={isProduct ? coverTillAgeList : []}
                      isReset={isCoverTillAge}
                      previousSelectedValue={coverTillAge?.code}
                      onChangeHandler={setCoverTillAge}
                    />
                  </>
                )}
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={6}>
              <Stack spacing={0} style={selectItemsStyle}>
                <>Pay till age</>
                {isFilterLoading ? (
                  <ButtonLoader colored />
                ) : (
                  <CompareFilterDropdown
                    options={isProduct ? payTillAgeList : []}
                    isReset={isPayTillAge}
                    isProduct={isProduct}
                    previousSelectedValue={payTillAge?.code}
                    onChangeHandler={setPayTillAge}
                  />
                )}
              </Stack>
            </Grid>
            <Grid item sm={6} xs={6}>
              <Stack spacing={0} style={selectItemsStyle}>
                <>Frequency</>
                {isFilterLoading ? (
                  <ButtonLoader colored />
                ) : (
                  <CompareFilterDropdown
                    options={isProduct ? frequencyList : []}
                    isReset={isFrequency}
                    isProduct={isProduct}
                    isDisable={payTillAge?.code === 1 ? true : false}
                    previousSelectedValue={frequency?.code}
                    onChangeHandler={setFrequency}
                  />
                )}
              </Stack>
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer style={footerStyle}>
          <Button
            variant="secondary"
            onClick={() => dispatch(resetStates())}
            style={resetButtonStyle}
          >
            <img src={refresh} alt="Refresh" />
            Reset
          </Button>
          <ButtonStyled
            onClick={() => {
              handleAddToList();
              handleClose();
            }}
            style={AddToListButtonStyle}
            disabled={checkValues()}
          >
            Add to List
          </ButtonStyled>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CompareFiltersMobile;
