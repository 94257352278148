import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import {
  Input,
  InputWrapper,
  Radio,
  RadioLabel,
} from "../../../input/component/Fieldset1.style";
import { setIndiaFirstRiderVaildationError } from "../../../product-page/Quotes/quotes.slice";
import {
  allowAlphaNumeric,
  allowOnlyNumbers,
} from "../../../ProposalPage/inputUtils/onInput";

function IndiaFirstQuestions({ onSubmit = () => {} }) {
  const { handleSubmit, watch, register } = useForm({
    defaultValues: {
      isEmployee: "N",
      existingCustomer: "N",
      policyOrId: "clientId",
    },
  });

  const dispatch = useDispatch();
  // useEffect(()=>{})
  const indiaFirstError =
    (watch("isEmployee") === "Y" &&
      (!watch("employeeCode") ||
        watch("currentOrganization") === "Please Select")) ||
    (watch("existingCustomer") === "Y" && !watch("policyNumberOrClientId"));

  useEffect(() => {
    if (indiaFirstError) {
      dispatch(setIndiaFirstRiderVaildationError(true));
    } else dispatch(setIndiaFirstRiderVaildationError(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indiaFirstError]);

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        css={`
          margin: 1em 0;
        `}
      >
        <FieldWrap>
          <Label>
            Is the Customer an employee of Bank of Baroda, Union Bank of India,
            eDena, eVijay, IndiaFirst Life Insurance Co. Ltd. Or an Individual
            agent or their family member?
          </Label>
          <InputWrapper>
            <Radio
              type="radio"
              value="Y"
              id="is_employee_yes"
              {...register("isEmployee")}
            />
            <RidersRadioLabel htmlFor="is_employee_yes">Yes</RidersRadioLabel>
            <Radio
              type="radio"
              value="N"
              id="is_employee_no"
              {...register("isEmployee")}
            />
            <RidersRadioLabel htmlFor="is_employee_no">No</RidersRadioLabel>
          </InputWrapper>
        </FieldWrap>

        {watch("isEmployee") === "Y" && (
          <div
            css={`
              display: flex;
              justify-content: space-between;
            `}
          >
            <FieldWrap
              css={`
                width: 50%;
              `}
            >
              <Label>Current Organization</Label>
              <select {...register("currentOrganization")}>
                <option>Please Select</option>
                <option>Bank of Baroda</option>
                <option>IndiaFirst Life</option>
                <option>Individual Agent</option>
                <option>Regional Rural Bank</option>
                <option>Union Bank of India</option>
              </select>
              {/* {watch("currentOrganization") === "Please Select" && (
              <div>require</div>
            )} */}
            </FieldWrap>

            <FieldWrap>
              <RidersInput
                type="text"
                placeholder="Employee Code"
                maxLength={12}
                onInput={allowAlphaNumeric}
                {...register("employeeCode")}
              />
              {/* {!watch("employeeCode") && <div>require</div>} */}
            </FieldWrap>
          </div>
        )}

        <FieldWrap>
          <Label>Are you an existing customer of IndiaFirst Life?</Label>
          <InputWrapper>
            <Radio
              type="radio"
              value="Y"
              id="existing_customer_yes"
              {...register("existingCustomer")}
            />
            <RidersRadioLabel htmlFor="existing_customer_yes">
              Yes
            </RidersRadioLabel>
            <Radio
              type="radio"
              value="N"
              id="existing_customer_no"
              {...register("existingCustomer")}
            />
            <RidersRadioLabel htmlFor="existing_customer_no">
              No
            </RidersRadioLabel>
          </InputWrapper>
        </FieldWrap>

        {watch("existingCustomer") === "Y" && (
          <div
            css={`
              display: flex;
              justify-content: space-between;
            `}
          >
            <FieldWrap
              css={`
                width: 50%;
              `}
            >
              <Label>Policy Number / Client ID</Label>
              <select {...register("policyOrId")}>
                <option value="clientId">Client ID</option>
                <option value="policyNumber">Policy Number</option>
              </select>
            </FieldWrap>

            <FieldWrap>
              <RidersInput
                type="text"
                placeholder={
                  watch("policyOrId") === "clientId"
                    ? "Enter Client ID"
                    : "Enter Policy Number"
                }
                onInput={allowOnlyNumbers}
                maxLength={8}
                {...register("policyNumberOrClientId")}
              />
            </FieldWrap>
          </div>
        )}
      </form>
      {indiaFirstError && (
        <ErrorMsg>Please fill all the details correctly.</ErrorMsg>
      )}
    </div>
  );
}

export { IndiaFirstQuestions };

export const Label = styled.label`
  font-size: 0.87em;
`;

export const RidersRadioLabel = styled(RadioLabel)`
  padding: 1em 2em;
  margin: 0.3em;
`;

export const RidersInput = styled(Input)`
  margin: 0;
`;

export const FieldWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1em 0;
`;
const ErrorMsg = styled.div`
  color: red;
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
  margin-bottom: 10px;
`;
