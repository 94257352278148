import api from "../../api/api";

export const proposalFields = () => api.get("term/proposal-fields");
export const saveProposalApi = data => api.post("term/proposals", data);
export const getProposalApi = () => api.get("term/proposals");
export const submitProposalApi = () =>
  api.post("companies/term_insurances/proposals");
export const generateBI = data => api.post("/term/generate-bi", data);
export const getOccupationList = data =>
  api.get(`/term/occupation?company_alias=${data.company_alias}`);
export const otpService = data => api.post("term/otp-validate", data);
export const getBankDetails = ({ alias, ifsc_code }) =>
  api.get(`term/bank_details?company_alias=${alias}&ifsc_code=${ifsc_code}`);
