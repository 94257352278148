import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import { riderApi } from "../pages/riders/services/rider";

const middleware = [...getDefaultMiddleware(), riderApi.middleware];

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware,
});

export { store };
