const { useSelector } = require("react-redux");
const { selectFrontendData } = require("./frontendBoot.slice");

function useFrontendData() {
  const { termcompanies } = useSelector(selectFrontendData);

  function getCompanyLogo(company_alias) {
    const companyData = termcompanies[company_alias];

    if (!companyData) return null;

    return companyData.logo;
  }

  return { getCompanyLogo };
}

export { useFrontendData };
