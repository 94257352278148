import max_life from "./max_life.png";
import hdfc_life from "./hdfc_life.png";
import icici_pru from "./icici_pru.png";
import kotak_life from "./kotak.png";
import edelweiss_tokio_life from "./tokio.png";
import canara_hsbc from "./canara_hsbc.png";
import bajaj_life from "./bajaj_life.png";
import tata_aia from "./tata_aia.png";
import indiafirst_life from "./India_first.png";
import aditya_birla from "./aditya_birla.png";
import birla_sun_life from "./aditya_birla.png";
import digit from "./digit.jpg";
import pnb_metlife from "./pnb_metlife.png";
import bandan_logo from "./bandan_logo.jpeg";
const images = {
  max_life,
  hdfc_life,
  kotak_life,
  icici_pru,
  edelweiss_tokio_life,
  canara_hsbc,
  aegon_life: bandan_logo,
  bajaj_life,
  tata_aia,
  indiafirst_life,
  digit,
  aditya_birla,
  birla_sun_life,
  pnb_metlife,
};

export { images };
