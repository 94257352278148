import { createSlice } from "@reduxjs/toolkit";
import { getSeeDetails } from "../Quotes/serviceApi";

const compareQuotes = createSlice({
  name: "compareQuotes",
  initialState: {
    compareQuotes: [],
    compareData: {},
    downloading: false,
  },
  reducers: {
    addQuote: (state, { payload }) => {
      state.compareQuotes = [...state.compareQuotes, payload];
    },
    removeQuote: (state, { payload }) => {
      let leftQuotes = state.compareQuotes.filter(
        quote => quote.product.id !== payload.product.id,
      );
      state.compareQuotes = [...leftQuotes];
    },
    clearQuotes: state => {
      state.compareQuotes = [];
    },
    setCompare: (state, { payload }) => {
      state.compareData = {
        ...state.compareData,
        ...{ [payload.productId]: payload.data },
      };
    },
    requestDownload(state) {
      state.downloading = true;
    },
    requestDownloadSuccess(state) {
      state.downloading = false;
    },
    resetCompare(state) {
      state.compareData = {};
    },
  },
});

export const {
  addQuote,
  removeQuote,
  clearQuotes,
  setCompare,
  requestDownload,
  requestDownloadSuccess,
  resetCompare,
} = compareQuotes.actions;

export const fetchOne = () => {};
export const fetchAll = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(resetCompare());
      const { compareQuotes } = getState().compareQuotes;

      // let compare = {}; // if not
      compareQuotes.forEach(async item => {
        try {
          let productId = item?.product.id;
          const { data } = await getSeeDetails(productId);
          dispatch(setCompare({ productId, data: data.compare }));
        } catch (err) {
          console.log(err);
        }
      });
    } catch (err) {
      console.error(err);
    }
  };
};

export default compareQuotes.reducer;

export const selectCompareQuotes = state => state.compareQuotes.compareQuotes;
