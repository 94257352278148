import { createSlice } from "@reduxjs/toolkit";
import {
  generateBI,
  getProposalApi,
  proposalFields,
  proposalUpdate,
  saveProposalApi,
  submitProposalApi,
} from "./serviceApi";
import {
  setDocRequired,
  setIsLoading,
  setProposalRefId,
  setDocLength,
} from "../ProposalPage/ProposalPage.slice";
import { download } from "../compare/components/DownloadCompareButton";
export const proposalPage = createSlice({
  name: "proposal",
  initialState: {
    schema: {},
    proposalData: {},
    position: 0,
    asyncValues: {},
    asyncOptions: {},
    isBI: false,
    isLoading: true,
    doc_required: [],
  },
  reducers: {
    setSchema: (state, { payload }) => {
      state.schema = payload;
      state.isLoading = false;
    },
    setProposalData: (state, { payload }) => {
      state.proposalData = { ...state.proposalData, ...payload };
    },
    setPosition: (state, { payload }) => {
      state.position = payload;
    },
    setAsyncValues: (state, { payload }) => {
      state.asyncValues = { ...state.asyncValues, ...payload };
    },
    setAsyncOptions: (state, { payload }) => {
      state.asyncOptions = { ...state.asyncOptions, ...payload };
    },
    setIsBI: (state, { payload }) => {
      state.isBI = payload;
    },
    initLoader: state => {
      state.isLoading = true;
    },
    clearData: state => {
      state.position = 0;
      state.proposalData = {};
    },
  },
});

export const {
  setSchema,
  setProposalData,
  setPosition,
  setAsyncOptions,
  setAsyncValues,
  setIsBI,
  initLoader,
  clearData,
} = proposalPage.actions;

export const getProposalFields = () => {
  return async dispatch => {
    try {
      const { data } = await proposalFields();
      dispatch(initLoader());
      dispatch(setSchema(data.data));
    } catch (err) {
      console.error(err);
    }
  };
};
export const getProposalData = () => {
  return async (dispatch, getState) => {
    try {
      const { data } = await getProposalApi();
      dispatch(setProposalData(data.data));
      dispatch(setPosition(Object.keys(data.data).length));
    } catch (err) {
      console.error(err);
    }
  };
};
export const clearProposalData = () => {
  return async (dispatch, getState) => {
    try {
      const { data } = await getProposalApi();
      dispatch(clearData(data));
      dispatch(setPosition(0));
    } catch (err) {
      console.error(err);
    }
  };
};
export const saveProposalData = (req, next = () => {}) => {
  return async (dispatch, getState) => {
    try {
      const { isBI } = getState().proposal;
      // eslint-disable-next-line no-unused-vars
      const { data } = await saveProposalApi(req);
      if (isBI) {
        const {
          data: { data },
        } = await generateBI();
        dispatch(setIsBI(false));
        window.open(data[0].bi_url);
      }
      dispatch(setProposalData(req));
      next();
    } catch (err) {
      console.error(err);
    }
  };
};
export const submitProposalData = (req, next = () => {}) => {
  return async dispatch => {
    try {
      const { data } = await submitProposalApi(req);
      next(data.data[0]);
    } catch (err) {
      console.error(err);
    }
  };
};
export const savePosition = data => {
  return async dispatch => {
    try {
      dispatch(setPosition(data));
    } catch (err) {
      console.error(err);
    }
  };
};
export const updateProposal = data => {
  return async dispatch => {
    try {
      dispatch(setIsLoading(true));
      const res = await proposalUpdate(data);
      dispatch(setDocRequired(res?.data?.proposal?.document_list));
      dispatch(setDocLength(res?.data?.proposal?.document_list?.length));
      dispatch(setProposalRefId(res?.data?.proposal?.proposal_reference_id));
      if (res?.data?.proposal?.extras?.document_list) {
        dispatch(setIsLoading(false));
        res?.data?.proposal?.extras?.document_list?.proposal_pdf &&
          download(res?.data?.proposal?.extras?.document_list?.proposal_pdf);
      }
      return res;
    } catch (error) {
      console.error(error);
    }
  };
};
export const proposal_status = {
  PROPOSAL_SUBMITTED: "proposal_submitted",
  POLICY_ISSUED: "policy_issued",
  POLICY_REJECTED: "policy_rejected",
  POLICY_CANCELLED: "policy_cancelled",
  DOCUMENT_PENDING: "document_pending",
  DOCUMENT_SUBMITTED: "document_submitted",
  MEDICAL_PENDING: "medical_pending",
  MEDICAL_SUBMITTED: "medical_submitted",
  OTP_PENDING: "otp_pending",
  OTP_VERIFIED: "otp_verified",
};
export default proposalPage.reducer;
