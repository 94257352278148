export const ageProofOption = [
  {
    code: "EAADHAR",
    display_name: "Aadhaar eKYC",
  },
  {
    code: "NSAAAD",
    display_name: "Aadhar Card - Standard",
  },
  {
    code: "NSAAADH",
    display_name: "Aadhar Card - Non-Standard",
  },
  {
    code: "NSAAFF",
    display_name: "Affidavit",
  },
  {
    code: "NSABPP",
    display_name: "Bank passbook with photograph",
  },
  {
    code: "SAPBAP",
    display_name: "Baptism certificate",
  },
  {
    code: "SAPBCM",
    display_name: "Birth Cert by Municipal/Hosp",
  },
  {
    code: "SAPBCN",
    display_name: "Certc Birth NursgHom/Hosp/Gyne",
  },
  {
    code: "SAPCID",
    display_name: "College ID",
  },
  {
    code: "NSACIP",
    display_name: "Cpy Insur polcy Pvt Insur comp",
  },
  {
    code: "DEFCSC",
    display_name: "Defence Canteen Smart Card",
  },
  {
    code: "DEFSC",
    display_name: "Defence Serving Certificate",
  },
  {
    code: "SAPDOC",
    display_name: "Domicile certificate",
  },
  {
    code: "SAPDRV",
    display_name: "Driving Licence",
  },
  {
    code: "SAPEID",
    display_name: "Employee ID card with DOB - Government / PSU",
  },
  {
    code: "SAPEMP",
    display_name: "Employer certificate",
  },
  {
    code: "SAPPMS",
    display_name: "Employer Certificate -Govt/PSU/MNC staff",
  },
  {
    code: "SAPEMC",
    display_name: "Employer ID Card",
  },
  //
  {
    code: "NSAESI",
    display_name: "ESI Card",
  },
  {
    code: "SAPEXS",
    display_name: "Existing Policy (Std Proof)",
  },
  {
    code: "NSAGRA",
    display_name: "Gram Panchayat Certificate",
  },
  {
    code: "SAPIDC",
    display_name: "ID Cards of defense forces",
  },
  {
    code: "SAPJKB",
    display_name: "J&K Bank A/C holder / Employee",
  },
  {
    code: "SAPLPO",
    display_name: "LIC Policy - Post 1996",
  },
  {
    code: "NSALPR",
    display_name: "LIC Policy - Pre 1996",
  },
  {
    code: "SAPMCC",
    display_name: "Marriage certificate issued by Church",
  },
  {
    code: "NOTAPP",
    display_name: "Not Applicable",
  },
  {
    code: "NSAOSD",
    display_name: "Org Stp Dcl Age NJd SpAtt Nt",
  },
  {
    code: "SAPPAN",
    display_name: "PAN",
  },
  {
    code: "NSAPCE",
    display_name: "Panchayat Certificate",
  },
  {
    code: "PARCON",
    display_name: "Partners Confirmation",
  },
  {
    code: "NSAPPT",
    display_name: "Passport",
  },
  {
    code: "SAPPAY",
    display_name: "Payslip - Govt/PSU/MNC",
  },
  {
    code: "NSAPAE",
    display_name: "Photo attestation by Employer",
  },
  {
    code: "NSAPFA",
    display_name: "Photo attestation by FA",
  },
  {
    code: "NSAPCC",
    display_name: "Photo Credit Card",
  },
  {
    code: "NSAPDC",
    display_name: "Photo Debit Card",
  },
  {
    code: "SAPPNB",
    display_name: "PNB KYC Certificate",
  },
  {
    code: "SAPPLP",
    display_name: "Previous Life Insurance Policy",
  },
  {
    code: "NSAPNB",
    display_name: "Punjab National Bank Certified Age Proof",
  },
  //
  {
    code: "SAPRPP",
    display_name: "Railway & PSU salary slip",
  },
  {
    code: "NSARAT",
    display_name: "Ration Card",
  },
  {
    code: "SAPRRB",
    display_name: "RRB KYC Certificate <= 10 lacs",
  },
  {
    code: "NSARRB",
    display_name: "RRB KYC Certificate > 10 lacs",
  },
  {
    code: "SAPSCC",
    display_name: "School Certificate",
  },
  {
    code: "NSASAI",
    display_name: "Self Attested Photo ID",
  },
  {
    code: "SAPSER",
    display_name: "Service Extracts",
  },
  {
    code: "SAPSRP",
    display_name: "Statement Record of PF",
  },
  {
    code: "NSATCE",
    display_name: "Tehsildar Certificate",
  },
  {
    code: "TELCAL",
    display_name: "Voice Tag",
  },
  {
    code: "NSAVID",
    display_name: "Voters ID",
  },
];

export const addressProofOption = [
  {
    code: "EAADHAR",
    display_name: "Aadhaar eKYC",
  },
  {
    code: "NSAAAD",
    display_name: "Aadhar Card - Standard",
  },
  {
    code: "OVDMOE",
    display_name:
      "An officially valid document in respect of managers, officers or employees holding an attorney to transacts on its behalf",
  },
  {
    code: "S03",
    display_name: "Bank account or Post Office savings bank account statement.",
  },
  {
    code: "BAPSAS",
    display_name:
      "Bank account or Post Office savings bank account statement-HUF",
  },
  {
    code: "COIMAS",
    display_name:
      "Certificate of Incorporation and Memorandum & Articles of Association",
  },
  {
    code: "DEF-SC",
    display_name: "Defence Serving Certificate",
  },
  {
    code: "S06",
    display_name: "Documents issued by Government of foreign jurisdictions",
  },
  {
    code: "03",
    display_name: "Driving License",
  },
  {
    code: "LTRNPR",
    display_name:
      "Letter Issued by the National Population Register containing details of name and address with photograph",
  },
  {
    code: "S05",
    display_name: "Letter of allotment of accommodation",
  },
  {
    code: "05",
    display_name: "NREGA Job Card",
  },
  {
    code: "S04",
    display_name: "Pension or family pension payment orders (PPOs)",
  },
  {
    code: "02",
    display_name: "Passport",
  },
  {
    code: "PARKYC",
    display_name: "Partner KYC",
  },
  {
    code: "POAMOE",
    display_name:
      "Power of Attorney granted to its Manager, officers or its employees to transact business of its behalf",
  },
  {
    code: "S02",
    display_name: "Property or Municipal Tax receipt.",
  },
  {
    code: "RESBOD",
    display_name: "Resolution of Board of Directors",
  },
  {
    code: "01",
    display_name: "UID (Aadhar Card)",
  },
  {
    code: "S01",
    display_name: "Utility bill",
  },
  {
    code: "04",
    display_name: "Voters Identity Card",
  },
  {
    code: "RESBOD",
    display_name: "Resolution of Board of Directors",
  },
  {
    code: "99",
    display_name: "Other",
  },
];

export const idProofOptions = [
  { code: "EAADHAR", display_name: "Aadhaar eKYC" },
  { code: "NSAAAD", display_name: "Aadhar Card - Standard" },
  { code: "DEF-CSC", display_name: "Defence Canteen Smart Card" },
  { code: "DEF-SC", display_name: "Defence Serving Certificate" },
  { code: "03", display_name: "Driving License" },
  { code: "SI1", display_name: "Identity card with applicants photograph" },
  { code: "SI2", display_name: "Letter issued by a gazetted officer" },
  {
    code: "LTRNPR",
    display_name:
      "Letter Issued by the National Population Register containing details of name and address with photograph",
  },
  { code: "05", display_name: "NREGA Job Card" },
  { code: "99", display_name: "Other" },
  { code: "PARCON", display_name: "Other - Partners Confirmation" },
  { code: "PANCAD", display_name: "PAN Card" },
  { code: "02", display_name: "Passport" },
  { code: "01", display_name: "UID (Aadhar Card)" },
  { code: "04", display_name: "Voters Identity Card" },
];

export const incomeProofOptions = [
  {
    code: "OIP-FDMF",
    display_name: "Any other investment proofs (e.g. FD, MF, PPF, NSC etc.)",
  },
  { code: "AUDICAF", display_name: "Audited Company accounts / Firms" },
  {
    code: "BAFCNFM",
    display_name:
      "BAF signed by Bank's authorized signatory with minimum latest six months bank statement",
  },
  {
    code: "BSC",
    display_name: "Bank Statement reflecting latest salary credits",
  },
  {
    code: "CACINMF",
    display_name: "Chartered Accountant''s Certificate in Metlife format",
  },
  { code: "CREDRPT", display_name: "Credit Score Report" },
  { code: "EMPLRCER", display_name: "Employers Certificate" },
  {
    code: "FAFSIGN",
    display_name:
      "FAF signed by relevant  authorized signatory with minimum latest six months bank statement",
  },
  { code: "FORM16A", display_name: "Form -16 A , Form -16,Recent Payslips" },
  { code: "F26AS", display_name: "Form 26 AS" },
  { code: "F3CA3CD", display_name: "Form 3CA& 3CD" },
  {
    code: "FORMJ712",
    display_name: "Form J ., 7/12 issued by Government Authorities",
  },
  {
    code: "FJCLRD",
    display_name: "Form J with credits & Land revenue documents",
  },
  { code: "GSTRETRN", display_name: "GST Returns Papers" },
  {
    code: "INTAOITR",
    display_name: "Income tax assessment orders/Income Tax Returns",
  },
  { code: "NOTAPPL", display_name: "Not Applicable" },
  { code: "PD", display_name: "Pension documents" },
  {
    code: "PP/RAWR",
    display_name: "Property papers / Rent agreement with rent receipts",
  },
];
