export const selectedDetailsFields = [
  "cover_upto_age",
  "pay_till_age",
  "cover_amt",
];
export const basicFeaturesFields = [
  "max_cover_amt",
  "max_entry_age",
  "max_maturity_age",
  "max_pt",
];
export const planTypeFields = [
  "basic_life_cover",
  "rop",
  "survival_benefit",
  "increasing_cover",
  "reducing_cover",
];
export const premiumPaymentFields = [
  "regular_pay",
  "limited_pay",
  "single_pay",
];
export const planBenefitsFields = ["inbuilt_benefits", "add_on_benefits"];
export const insurerFields = ["claims_ratio", "formation_year"];
