import { TableCell, TableRow } from "@mui/material";
import React from "react";
import styled from "styled-components";

const CustomCompareSkeletonLoader = () => {
  return (
    <TableRow>
      <TableCellICWrapperStyled>
        <div className="ic-lg"></div>
        <div className="ic-name">
          <div></div>
          <div></div>
        </div>
      </TableCellICWrapperStyled>
      <TableCellStyled>
        <div></div>
      </TableCellStyled>
      <TableCellStyled>
        <div></div>
      </TableCellStyled>
      <TableCellStyled>
        <div></div>
      </TableCellStyled>
      <TableCellStyled>
        <div></div>
      </TableCellStyled>
      <TableCellStyled>
        <div></div>
      </TableCellStyled>
      <TableCellStyled>
        <div></div>
      </TableCellStyled>
    </TableRow>
  );
};

export default CustomCompareSkeletonLoader;

const TableCellStyled = styled(TableCell)`
  div {
    height: 20px;
    width: 50%;
    margin: auto;
    background-color: #efefef;
    animation: zoomAnimation 0.6s infinite alternate;
  }
  @keyframes zoomAnimation {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2);
    }
  }
`;
const TableCellICWrapperStyled = styled(TableCell)`
  display: flex !important;
  .ic-lg,
  .ic-name {
    animation: icZoomAnimation 0.4s infinite alternate;
    @keyframes icZoomAnimation {
      0% {
        transform: scale(0.95);
      }
      100% {
        transform: scale(1);
      }
    }
  }
  .ic-lg {
    height: 40px;
    width: 70px;
    background-color: #efefef;
  }
  .ic-name {
    width: 100%;
    div {
      width: 90%;
      height: 15px;
      margin: 3px 10px 5px;
      background-color: #efefef;
    }
  }
`;
