import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import useUrlQuery from "../../../../custom-hooks/useUrlQuery";
import { useNameInput, useNumberInput } from "../../../../utils";
import {
  saveData,
  setData,
  updateEnquiriesOnInput,
} from "../../../Landing/input.slice";
import { patchEnquiries } from "../../../product-page/Filters/serviceApi";
import { useGetSellerInfoQuery } from "../../../riders/services/rider";
import { useInputFormsHook } from "../../hooks";
import { communicationFormSchema } from "./validation";

const useCommunicationForm = () => {
  const { getCommunicationFormData, setCommunicationFormData } =
    useInputFormsHook();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    trigger,
    clearErrors,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(communicationFormSchema),
    mode: "onChange",
    defaultValues: {
      ...getCommunicationFormData(),
      domain_whitelisted: false,
      whitelisted_data: [],
    },
  });
  const dispatch = useDispatch();
  const enquiryId = useUrlQuery("enquiryId");
  const USERTYPE = useUrlQuery("USERTYPE");
  const USERID = useUrlQuery("USERID");
  const token = useUrlQuery("token");
  const { data } = useGetSellerInfoQuery({ token: token }, { skip: !token });
  const params = {
    USERTYPE,
    USERID,
    token,
    other_req_data: data?.other_req_data,
  };
  const [name, onNameChange] = useNameInput(getCommunicationFormData().name);
  const [email, setEmail] = useState(getCommunicationFormData().email);
  const [mobileNumber, setMobileNumber] = useNumberInput(
    getCommunicationFormData().mobile,
  );
  const {
    otpResponse,
    isEnquiryLoading,
    data: { preferred_ic = null },
  } = useSelector(state => state.inputForm);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otp, setOtp] = useState(false);

  useEffect(() => {
    if (otpResponse?.status === true && otpResponse.message === "Verified") {
      setOtpVerified(true);
      toast.success("OTP Verified");
    }
  }, [otpResponse?.status, otpResponse.message]);
  const onSubmit = data => {
    dispatch(setData({ ...data, params }));
    setCommunicationFormData(name, email, mobileNumber);
    if (enquiryId) {
      dispatch(
        updateEnquiriesOnInput(() => {
          history.push(`/quote?enquiryId=${enquiryId}`);
        }),
      );
    } else
      dispatch(
        saveData(enquiryId => {
          history.push(`/quote?enquiryId=${enquiryId}`);
          if (preferred_ic) {
            patchEnquiries({
              input: { selected_insurers: [`${preferred_ic}`] },
            });
          }
        }),
      );
  };

  return {
    handleSubmit,
    onSubmit,
    register,
    errors,
    trigger,
    clearErrors,
    setValue,
    email,
    setEmail,
    mobileNumber,
    setMobileNumber,
    otp,
    setOtp,
    name,
    onNameChange,
    isEnquiryLoading,
    otpResponse,
    otpVerified,
  };
};

export default useCommunicationForm;
