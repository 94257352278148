import React from "react";
import { Wrapper } from "../ProposalPage.style";

const InputWrapper = ({
  show = true,
  children,
  fullWidth,
  summary,
  input,
  isSummary,
  margin,
  ...props
}) => {
  return (
    <>
      {show && (
        <Wrapper margin={margin} fullWidth={fullWidth}>
          {isSummary ? summary : input}
        </Wrapper>
      )}
    </>
  );
};

export default InputWrapper;
