import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { getAnnualIncome } from "../../../../components/navbar/helper";
// import * as colors from "../../../../styles/colors";
import {
  extractNumbers,
  numberToWords,
  useAmountInput,
} from "../../../../utils";
import { useGetEnquiriesQuery } from "../../../riders/services/rider";

import { changeFilter } from "../../Filters/Filters.slice";
import { setData, updateEnquiries } from "../../../Landing/input.slice";
import {
  selectEducationOptions,
  selectEligibiltyGrid,
  selectOccupationOptions,
} from "../Eligibility.slice";
import {
  Details,
  ErrorMsg,
  // Label,
  ErrorMsgWrapper,
  // Select,
  // TextInput,
  Wrapper,
} from "../EligibilityCard.style";
import moment from "moment";
import Select from "./Select/Select";
import TextInput from "./TextInput/TextInput";
// import useMediaQuery from "../../../../custom-hooks/useMediaQuery";
import {
  sm,
  // smForHook
} from "../../../../styles/mediaQueries";
import ButtonLoader from "../../../../components/CardSkeletonLoader/ButtonLoader/ButtonLoader";

function OccupationDetails({ afterSubmit, onClose, isActive, goBack }) {
  const dispatch = useDispatch();
  const { data } = useGetEnquiriesQuery();

  // const matches = useMediaQuery(smForHook);
  const { data: inputData, isEnquiryLoading } = useSelector(
    state => state.inputForm,
  );
  const selected_product = inputData.selected_product;
  const {
    data: {
      input: {
        occupation: selectedOccupation,
        education: selectedEducation,
        term_annual_income: selectedAnnualIncome,
      },
    },
  } = data;

  const [occupation, setOccupation] = useState(
    inputData?.occupation || selectedOccupation,
  );
  const [education, setEducation] = useState(
    inputData?.education || selectedEducation,
  );

  const [annualIncome, handleAnnualIncomeChange, annualIncomeDigits] =
    useAmountInput(inputData?.term_annual_income || selectedAnnualIncome);

  const occupationOptions = useSelector(selectOccupationOptions);
  const educationOptions = useSelector(selectEducationOptions);
  useEffect(() => {
    // setOccupation(
    //   selected_product?.occupation ||
    //     inputData?.occupation ||
    //     selectedOccupation,
    // );

    setEducation(
      selected_product?.education || inputData?.education || selectedEducation,
    );
  }, [
    selectedOccupation,
    selectedEducation,
    selectedAnnualIncome,
    selected_product,
    inputData,
  ]);
  const { errors, isError, modifyCover } = useEligibilityGrid({
    occupation,
    education,
    annualIncome: annualIncomeDigits,
  });

  const occupationOptionsForMale = occupationOptions.filter(
    item => item.occupation_value !== "housewife",
  );

  const handleSubmit = evt => {
    // evt.preventDefault();

    afterSubmit && afterSubmit();
  };

  const handleOccupationChange = evt => {
    setOccupation(evt);
  };

  const handleEducationChange = evt => {
    setEducation(evt);
  };

  if (!isActive) return null;

  return (
    <Details onSubmit={handleSubmit}>
      <Wrapper fullWidth>
        <Select
          name="occupation"
          label="Occupation*"
          options={
            inputData.gender === "F"
              ? occupationOptions.map(item => ({
                  code: item.occupation_value,
                  display_name: item.occupation,
                }))
              : occupationOptionsForMale.map(item => ({
                  code: item.occupation_value,
                  display_name: item.occupation,
                }))
          }
          handleChange={handleOccupationChange}
          selectedValue={occupation}
          noSearch
        />
      </Wrapper>

      <Wrapper fullWidth>
        <Select
          name="education"
          label="Education*"
          options={educationOptions.map(item => ({
            code: item.education_value,
            display_name: item.education,
          }))}
          handleChange={handleEducationChange}
          selectedValue={education}
          noSearch
        />
      </Wrapper>

      <Wrapper
        fullWidth
        css={`
          height: auto;
        `}
      >
        <TextInput
          label="Annual Income*"
          name="term_annual_income"
          onChange={handleAnnualIncomeChange}
          value={annualIncome}
          maxLength={12}
          inputProps={{ placeholder: "eg. 10,00,000", inputMode: "numeric" }}
        />
        <div
          css={`
            font-size: 0.9em;
          `}
        >
          {numberToWords(annualIncomeDigits)}
        </div>
      </Wrapper>

      {errors.generic && (
        <ErrorMsgWrapper
          fullWidth
          css={`
            margin-bottom: 5px;
          `}
        >
          <ErrorMsg style={{ fontSize: "0.9rem" }}>{errors.generic}</ErrorMsg>
        </ErrorMsgWrapper>
      )}

      {modifyCover ? (
        <ModifyCoverOrGoToQuotes
          onClose={onClose}
          newSumAssured={modifyCover}
          annualIncome={annualIncome}
        />
      ) : null}

      <div
        css={`
          display: flex;
          gap: 0;
          width: 50%;
          margin: auto;
          ${sm} {
            gap: 30px;
            width: 100%;
            margin: 1rem auto;
          }
        `}
      >
        <button
          css={`
            width: 45%;
            background: unset;
            color: var(--secondary-text);
            font-weight: 900;
            font-size: 16px;
          `}
          onClick={goBack}
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 512 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="none"
              stroke-linecap="square"
              stroke-miterlimit="10"
              stroke-width="48"
              d="M244 400L100 256l144-144M120 256h292"
            ></path>
          </svg>{" "}
          Back
        </button>

        {!isError ? (
          <NextButton
            type="submit"
            className="btn btn-primary mx-auto d-block btn-lg shadow-none"
            onClick={async () => {
              await dispatch(
                updateEnquiries({
                  input: {
                    selected_product: {
                      pincode: inputData.selected_product?.pincode,
                      state: inputData.selected_product?.state,
                      state_name: inputData.selected_product?.state_name,
                      city: inputData.selected_product?.city,
                      city_name: inputData.selected_product?.city_name,
                      occupation,
                      education,
                      term_annual_income: extractNumbers(annualIncome),
                    },
                  },
                }),
              ).then(() => handleSubmit("personal_details"));
            }}
          >
            {isEnquiryLoading ? <ButtonLoader /> : "Next"}
          </NextButton>
        ) : null}
      </div>
    </Details>
  );
}

function ModifyCoverOrGoToQuotes({ onClose, newSumAssured = 0, annualIncome }) {
  const dispatch = useDispatch();

  const handleGoToQuotesClick = () => {
    dispatch(
      updateEnquiries({
        input: {
          term_annual_income: extractNumbers(annualIncome),
          term_sum_assured: newSumAssured,
        },
      }),
    );
    dispatch(
      setData({ term_annual_income: parseInt(extractNumbers(annualIncome)) }),
    );

    dispatch(changeFilter({ term_sum_assured: newSumAssured }));
    onClose && onClose();

    // dispatch(
    //   updateEnquiries({
    //     input: { term_sum_assured: newSumAssured },
    //   }),
    // );
    // dispatch(
    //   setData({ term_annual_income: parseInt(extractNumbers(annualIncome)) }),
    // );

    // dispatch(
    //   changeFilter({
    //     term_sum_assured: newSumAssured,
    //     term_annual_income: extractNumbers(annualIncome),
    //   }),
    // );
    // onClose && onClose();
  };

  // const handleModifyCoverClick = () => {
  //   dispatch(changeFilter({ term_sum_assured: newSumAssured }));

  //   // dispatch(
  //   //   updateEnquiries({
  //   //     input: { term_sum_assured: newSumAssured },
  //   //   }),
  //   // );
  //   // onClose (false)
  // };

  return (
    <button
      css={`
        width: 100%;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        position: absolute;
        // gap: 2em;
        margin-top: 268px;
        margin-left: 240px;
        background-color: var(--primary-color);
        height: 40px;
        width: 120px;
      `}
    >
      <ModifyButton type="button" onClick={handleGoToQuotesClick}>
        Modify Cover
      </ModifyButton>

      {/* <span>OR</span> */}
      {/* <ModifyButton type="button" onClick={handleModifyCoverClick}>
        Modify Cover
      </ModifyButton> */}
    </button>
  );
}

const ModifyButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 1rem;
`;

const NextButton = styled.button`
  width: 6em;
  padding: 0.6em 0;
  font-size: 1em;
  border-radius: 0rem;

  :hover {
    background-color: var(--primary-color);
  }
`;

export default OccupationDetails;

const errorMessages = {
  invalidOccupation:
    "This Product is not available for the selected Occupation",
  invalidEducation:
    "This Education is not eligible for this product for the selected Occupation.",
  invalidAnnualIncome: `This product is not eligibile for the given Annual Income`,
  exceedsMaxCover: maxCover =>
    `Sum assured must be less than or equal to ₹ ${getAnnualIncome(maxCover)}`,
};

function useEligibilityGrid({ education, occupation, annualIncome } = {}) {
  // const { data } = useGetEnquiriesQuery();
  const { data: inputData } = useSelector(state => state.inputForm);

  const { dob } = inputData;
  const age = moment().diff(moment(dob, "YYYY-MM-DD"), "years");

  // const { term_sum_assured: selectedCover } = useSelector(selectTermFilters);
  const {
    selectedQuote: { term_sum_assured: selectedCover },
  } = useSelector(state => state.quotes);

  // const occupationOptions = useSelector(selectOccupationOptions);
  const eligibilityGrid = useSelector(selectEligibiltyGrid);

  const combinations = eligibilityGrid[`${occupation}+${education}`];
  const {
    data: { age_band_name },
  } = useSelector(state => state.inputForm);
  if (!combinations)
    return { errors: { generic: "Not eligible" }, isError: true };

  const { case_result } = combinations[0];

  // const occupationNames = occupationOptions.filter(
  //   item => item.occupation !== "Housewife",
  // );

  if (case_result === "Fail") {
    const isOccupationEligible = Object.keys(eligibilityGrid)
      .filter(combinationName => combinationName.startsWith(occupation))
      .some(
        combinationName =>
          eligibilityGrid[combinationName][0].case_result === "Pass",
      );

    if (isOccupationEligible)
      return {
        errors: {
          generic: errorMessages.invalidEducation,
        },
        isError: true,
      };

    return {
      errors: { generic: errorMessages.invalidOccupation },
      isError: true,
    };
  }

  const validateAgeForCombination = ({ minimum_age, maximum_age }) =>
    age >= minimum_age && age <= maximum_age;

  const ageFilteredCombinations = combinations.filter(
    validateAgeForCombination,
  );

  if (!ageFilteredCombinations[0])
    return { errors: { generic: "Not eligible" }, isError: true };

  const annualIncomeFilteredCombinations = ageFilteredCombinations.filter(
    ({ minimum_annual_income, maximum_annual_income }) =>
      annualIncome >= minimum_annual_income &&
      annualIncome <= maximum_annual_income,
  );

  if (!annualIncomeFilteredCombinations[0])
    return {
      errors: { generic: errorMessages.invalidAnnualIncome },
      isError: true,
    };

  const { maximum_sum_assured, ...bands } = annualIncomeFilteredCombinations[0];
  const multiplier = bands[age_band_name];
  const maximum_calculated_sum_assured = annualIncome * multiplier;
  if (
    selectedCover > maximum_sum_assured ||
    selectedCover > maximum_calculated_sum_assured
  ) {
    return {
      errors: {
        generic: errorMessages.exceedsMaxCover(
          Math.min(maximum_sum_assured, maximum_calculated_sum_assured),
        ),
      },
      isError: true,
      modifyCover: Math.min(
        maximum_sum_assured,
        maximum_calculated_sum_assured,
      ),
    };
  }

  return { errors: { generic: "" }, isError: false };
}

// import { useEffect, useState } from "react";
// import { useForm } from "react-hook-form";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   extractNumbers,
//   numberToWords,
//   useAmountInput,
// } from "../../../../utils";
// import { updateEnquiries } from "../../../input/input.slice";
// import { getAnnualIncome } from "../../Filters/helper";
// import {
//   Details,
//   ErrorMsg,
//   Label,
//   Select,
//   TextInput,
//   Wrapper,
// } from "../EligibilityCard.style";
// import "styled-components/macro";
// const fillIfAvailable = (options, name, data) => {
//   return options.find(item => item[`${name}_value`] === data) ? data : "";
// };
// function OccupationDetails({ afterSubmit }) {
//   const { handleSubmit } = useForm();

//   const dispatch = useDispatch();

//   const { data } = useSelector(state => state.inputForm);
//   const {
//     filters: { term_sum_assured },
//   } = useSelector(state => state.filters);

//   let { occupationOptions, educationOptions, eligibilityGrid } = useSelector(
//     state => state.eligibility,
//   );
//   const [eligibilityData, setEligibilityData] = useState({
//     occupation: data?.selected_product?.occupation || data.occupation,
//     education: data?.selected_product?.education || data.education,
//     term_annual_income:
//       data?.selected_product?.term_annual_income || data.term_annual_income,
//   });

//   occupationOptions =
//     data.gender === "M"
//       ? occupationOptions.filter(item => item.occupation_value !== "housewife")
//       : occupationOptions;

//   const [errors, setErrors] = useState({});

//   useEffect(() => {
//     setEligibilityData({
//       occupation: fillIfAvailable(
//         occupationOptions,
//         "occupation",
//         data?.selected_product?.occupation || data.occupation,
//       ),
//       education: fillIfAvailable(
//         educationOptions,
//         "education",
//         data?.selected_product?.education || data.education,
//       ),
//       term_annual_income:
//         data?.selected_product?.term_annual_income || data.term_annual_income,
//     });
//   }, [
//     data?.selected_product?.occupation,
//     data?.selected_product?.education,
//     data?.selected_product?.term_annual_income,
//   ]);

//   useEffect(() => {
//     const data =
//       eligibilityGrid[
//         `${eligibilityData?.occupation}+${eligibilityData?.education}`
//       ];
//     if (data?.length) {
//       let eligibilityGridOccupations = Object.keys(eligibilityGrid).filter(
//         combination => combination.startsWith(eligibilityData?.occupation),
//       );

//       eligibilityGridOccupations = eligibilityGridOccupations.map(
//         combination => eligibilityGrid[combination],
//       );

//       const isOccupationEligible = eligibilityGridOccupations.some(
//         combination => combination[0].case_result === "Pass",
//       );

//       if (data[0].case_result === "Fail") {
//         setErrors({
//           generic: isOccupationEligible
//             ? "This Education is not eligible for this product for the Selected Occupation."
//             : "This Product is not available for the selected Occupation",
//         });

//         return;
//       }

//       const filteredItem = data.find(item => {
//         if (
//           parseInt(eligibilityData?.term_annual_income) >=
//             item.minimum_annual_income &&
//           parseInt(eligibilityData?.term_annual_income) <=
//             item.maximum_annual_income
//         ) {
//           return true;
//         } else return false;
//       });

//       if (!filteredItem) {
//         setErrors({
//           // term_annual_income: "Please enter a valid Annual Income",
//           generic: "Please enter a valid Annual Income",
//         });
//         return;
//       } else if (filteredItem.case_result !== "Pass") {
//         setErrors({
//           // education: "Selected education is invalid",
//           generic: "Selected education is invalid",
//         });
//       }
//       if (filteredItem && filteredItem.case_result === "Pass") {
//         let maxFail =
//           filteredItem.maximum_sum_assured < parseInt(term_sum_assured);
//         let minFail =
//           filteredItem.minimum_sum_assured > parseInt(term_sum_assured);

//         if (maxFail) {
//           setErrors({
//             // term_annual_income:
//             //   "Sum Assured should be less than " +
//             //   filteredItem.maximum_sum_assured,
//             generic:
//               "Sum Assured should be less than or equal to " +
//               filteredItem.maximum_sum_assured,
//           });
//         } else if (minFail) {
//           setErrors({
//             // term_annual_income:
//             //   "Sum Assured should be less than " +
//             //   filteredItem.minimum_sum_assured,
//             generic:
//               "Sum Assured should be less than or equal to " +
//               filteredItem.minimum_sum_assured,
//           });
//         } else setErrors({});
//       } else setErrors({});
//     }
//   }, [eligibilityData, eligibilityGrid]);

//   const onSubmit = formData => {

//     let flag = false;
//     for (let key in eligibilityData) {
//       if (!eligibilityData[key]) {
//         flag = true;
//         setErrors(prev => ({
//           ...prev,
//           [key]: "This field is required",
//         }));
//       }
//     }
//     const isValid = !Object.keys(errors).some(item => Boolean(errors[item]));

//     if (isValid && !flag) {
//       dispatch(
//         updateEnquiries({
//           input: {
//             selected_product: {
//               ...(data.selected_product ? data.selected_product : {}),
//               ...eligibilityData,
//             },
//           },
//         }),
//       );
//       afterSubmit && afterSubmit();
//     }
//   };

//   const [ai, setAi, annualIncomeDigits] = useAmountInput(
//     eligibilityData.term_annual_income,
//   );

//   const onChange = e => {
//     if (e.target.name === "term_annual_income") {
//       setAi(e);
//       setEligibilityData(prev => {
//         return {
//           ...prev,
//           [e.target.name]: extractNumbers(e.target.value),
//         };
//       });
//       return;
//     }
//     setEligibilityData(prev => {
//       return {
//         ...prev,
//         [e.target.name]: e.target.value,
//       };
//     });
//   };

//   return (
//     <Details onSubmit={handleSubmit(onSubmit)}>
//       <Wrapper fullWidth>
//         <Select
//           onChange={onChange}
//           name="occupation"
//           value={eligibilityData.occupation}
//         >
//           {" "}
//           <option value="">Select</option>
//           {occupationOptions.map(item => (
//             <option key={item.occupation_value} value={item.occupation_value}>
//               {item.occupation}
//             </option>
//           ))}
//         </Select>
//         <Label>Occupation</Label>
//         {errors.occupation && <ErrorMsg>{errors.occupation}</ErrorMsg>}
//       </Wrapper>

//       <Wrapper fullWidth>
//         <Select
//           onChange={onChange}
//           name="education"
//           value={eligibilityData.education}
//         >
//           {" "}
//           <option value="">Select</option>
//           {educationOptions.map(item => (
//             <option key={item.education_value} value={item.education_value}>
//               {item.education}
//             </option>
//           ))}
//         </Select>
//         <Label>Education</Label>
//         {errors.education && <ErrorMsg>{errors.education}</ErrorMsg>}
//       </Wrapper>

//       {/* <Wrapper fullWidth>
//         <Select
//           onChange={onChange}
//           name="term_annual_income"
//           value={eligibilityData.term_annual_income}
//         >
//           {getAnnualIncome(200000, 10000000).map(item => (
//             <option key={item.code} value={item.code}>
//               {item.display_name}
//             </option>
//           ))}
//         </Select>
//         <Label>Annual Income</Label>
//         {errors.term_annual_income && (
//           <ErrorMsg>{errors.term_annual_income}</ErrorMsg>
//         )}
//       </Wrapper> */}

//       <Wrapper
//         fullWidth
//         css={`
//           height: auto;
//         `}
//       >
//         <TextInput
//           name="term_annual_income"
//           placeholder="eg 12,12,123"
//           onChange={onChange}
//           value={ai}
//           css={`
//             height: auto;
//           `}
//           maxLength={12}
//         />
//         <Label>Annual Income</Label>
//         <div
//           css={`
//             font-size: 0.76em;
//           `}
//         >
//           {numberToWords(annualIncomeDigits)}
//         </div>
//         {errors.term_annual_income && (
//           <ErrorMsg>{errors.term_annual_income}</ErrorMsg>
//         )}
//       </Wrapper>

//       {errors.generic && (
//         <Wrapper fullWidth>
//           <ErrorMsg style={{ fontSize: "0.9rem" }}>{errors.generic}</ErrorMsg>
//         </Wrapper>
//       )}

//       <button
//         css={`
//           width: 6em;
//           padding: 0.6em 0;
//           font-size: 1em;

//           :hover {
//             background-color: var(--primary-color);
//           }
//         `}
//         type="submit"
//         className="btn btn-primary mx-auto d-block btn-lg"
//       >
//         Next
//       </button>
//     </Details>
//   );
// }

// export default OccupationDetails;
