import { useSelector } from "react-redux";

export const useGetAdditionalPremium = productId => {
  const { addedRiders } = useSelector(state => state.quotes);
  if (!addedRiders[productId]) return 0;
  else
    return addedRiders[productId].reduce(function (a, b) {
      if (isNaN(a)) a = 0;
      return a + b.total_premium;
    }, 0);
};
