import { useState } from "react";
// import { useSelector } from "react-redux";
import { Disclaimer, Suitability, Whatsapp } from "../components";
import { useInputPageHook } from "../hooks";
import {
  InputPageFormCardStyled,
  InputPageFormContainerStyled,
  InputPageFormWrapperStyled,
} from "../styles/inputForms.styled";
import CommunicationForm from "./CommunicationForm";
import EligibilityForm from "./EligibilityForm";
import PersonalForm from "./PersonalForm";
import PreferredICForm from "./PreferredICForm";
import QuestionForm from "./QuestionsForm";
import ReactConfetti from "react-confetti";

const InputPageForm = () => {
  const [current, setCurrent] = useState(2);
  const {
    isEnquiry,
    termDisclaimer,
    termSuitabilityFlagStatus,
    termWhatsappFlagStatus,
  } = useInputPageHook();
  const [isConfettiVisible, setIsConfettiVisible] = useState(false);

  return (
    <>
      <InputPageFormContainerStyled>
        {isConfettiVisible && (
          <ReactConfetti
            recycle={false}
            numberOfPieces={1000}
            tweenDuration={10000}
          />
        )}
        <InputPageFormCardStyled>
          <InputPageFormWrapperStyled>
            {current === 1 && (
              <PreferredICForm setCurrent={setCurrent} current={current} />
            )}
            {current === 2 && (
              <PersonalForm setCurrent={setCurrent} current={current} />
            )}
            {current === 3 && (
              <EligibilityForm setCurrent={setCurrent} current={current} />
            )}
            {!isEnquiry && (
              <>
                {current === 4 && (
                  <QuestionForm setCurrent={setCurrent} current={current} />
                )}
                {current === 5 && (
                  <CommunicationForm
                    setCurrent={setCurrent}
                    current={current}
                    setIsConfettiVisible={setIsConfettiVisible}
                    isConfettiVisible={isConfettiVisible}
                  />
                )}
              </>
            )}
          </InputPageFormWrapperStyled>
        </InputPageFormCardStyled>
        {current === 2 && termDisclaimer ? <Disclaimer /> : <></>}
        {current === 4 && termSuitabilityFlagStatus === "Yes" ? (
          <Suitability />
        ) : (
          <></>
        )}
        {current === 5 && termWhatsappFlagStatus === "Yes" ? (
          <Whatsapp />
        ) : (
          <></>
        )}
      </InputPageFormContainerStyled>
    </>
  );
};

export default InputPageForm;
