import { combineReducers } from "redux";
import {
  frontendBootSlice,
  inputSlice,
  FiltersSlice,
  quotesSlice,
  ProposalPageSlice,
  eligibilitySlice,
  compareQuotesSlice,
  cartSlice,
  compareSlice,
  newCompareSlice,
} from "../pages";

import { riderApi } from "../pages/riders/services/rider";

export default combineReducers({
  frontendBoot: frontendBootSlice,
  inputForm: inputSlice,
  filters: FiltersSlice,
  quotes: quotesSlice,
  compare: compareSlice,
  compareQuotes: compareQuotesSlice,
  cart: cartSlice,
  proposal: ProposalPageSlice,
  eligibility: eligibilitySlice,
  newCompare: newCompareSlice,

  [riderApi.reducerPath]: riderApi.reducer,
});
