import { useDispatch, useSelector } from "react-redux";
import { useGetEnquiriesQuery } from "../../riders/services/rider";
import {
  changeFilter,
  // selectDefaultFilters,
  selectFilters,
  selectTermFilters,
} from "./Filters.slice";

export const getTermSumAssured = (minimum, maximum) => {
  let nums = [];
  let step = 500000;
  for (let i = minimum; i <= maximum; i += step) {
    if (i >= 10000000) {
      step = 5000000;
    }
    if (String(i).length >= 8) {
      let value = i / 10000000;
      nums.push({
        code: i,
        display_name: `${value} Crore`,
        max: maximum,
        min: minimum,
      });
    } else if (String(i).length >= 6) {
      let value = i / 100000;
      nums.push({
        code: i,
        display_name: `${value} Lacs`,
        max: maximum,
        min: minimum,
      });
    }
  }
  return nums;
};
const isNumber = function isNumber(value) {
  return typeof value === "number" && isFinite(value);
};

const isNumberObject = function isNumberObject(n) {
  return Object.prototype.toString.apply(n) === "[object Number]";
};

const isCustomNumber = function isCustomNumber(n) {
  return isNumber(n) || isNumberObject(n);
};

export const getValue = num => {
  if (isCustomNumber(num)) {
    if (String(num).length >= 8) {
      let value = num / 10000000;
      return `${+(Math.round(value + "e+2") + "e-2")} Crore`;
    } else if (String(num).length >= 6) {
      let value = num / 100000;
      return `${+(Math.round(value + "e+2") + "e-2")} Lacs`;
    }
  }
  return num;
};
export const getTermCoverUpto = (minimum, maximum) => {
  let nums = [];

  for (let i = minimum; i <= maximum; i += 1) {
    nums.push({ code: i, display_name: `${i} years` });
  }

  return nums;
};
export const getAnnualIncome = (minimum, maximum) => {
  let nums = [];
  let step = 50000;
  for (let i = minimum; i <= maximum; i += step) {
    if (i >= 500000) {
      step = 100000;
    }
    if (i >= 10000000) {
      step = 10000000;
    }
    if (String(i).length >= 8) {
      let value = i / 10000000;
      nums.push({ code: i, display_name: `${value} Crore` });
    } else if (String(i).length >= 6) {
      let value = i / 100000;
      nums.push({ code: i, display_name: `${value} Lacs` });
    }
  }
  return nums;
};

export function useDefaultFilters() {
  const dispatch = useDispatch();
  const { defaultFilters } = useSelector(selectFilters);

  const { data: enquiryData, isLoading } = useGetEnquiriesQuery();

  let termDefaultSumAssured = 0;
  let termDefaultLimitedPayTerm = 0;

  if (enquiryData) {
    termDefaultSumAssured = defaultFilters.term_sum_assured;
    termDefaultLimitedPayTerm = defaultFilters.term_coverupto;
  }

  const {
    term_plan_type,
    term_payout_option,
    selected_insurers,
    term_pay_frequency,
    term_paytype_option,
    term_coverupto,
    term_sum_assured,
    term_pay_till,
  } = useSelector(selectTermFilters);

  const isDefaultFilters =
    term_plan_type === "B" &&
    term_payout_option === "L" &&
    !selected_insurers &&
    term_pay_frequency === 0 &&
    term_paytype_option === "R" &&
    term_coverupto === termDefaultLimitedPayTerm &&
    term_pay_till === termDefaultLimitedPayTerm &&
    term_sum_assured === termDefaultSumAssured;

  const resetFilters = () => {
    dispatch(
      changeFilter({
        term_pay_frequency: "0",
        term_plan_type: "B",
        selected_insurers: null,
        term_paytype_option: "R",
        term_payout_option: "L",
        term_coverupto: termDefaultLimitedPayTerm,
        term_pay_till: termDefaultLimitedPayTerm,
        term_sum_assured: termDefaultSumAssured,
      }),
    );
  };

  return {
    isDefaultFilters,
    resetFilters,
    isLoading,
    termDefaultLimitedPayTerm,
    termDefaultSumAssured,
  };
}
