import { Tooltip } from "react-bootstrap";
import "styled-components/macro";

const renderTooltip = ({ props, desc }) => {
  return (
    <Tooltip
      className="tooltip"
      css={`
        background-color: black;
        color: white;
        padding: 4px 6px;
        border-radius: 3px;
        .tooltip-inner {
          font-size: 11px;
          text-align: left;
        }
      `}
      {...props}
    >
      {desc}
    </Tooltip>
  );
};

export default renderTooltip;

// import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
// import { styled } from "@mui/material/styles";

// const TooltipOverlay = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))({
//   [`& .${tooltipClasses.tooltip}`]: {
//     maxWidth: 500,
//   },
// });

// export default TooltipOverlay;
