import React, { useRef, useState } from "react";
import Select from "../../product-page/Filters/FilterDropDown";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { changeFilter } from "../../product-page/Filters/Filters.slice";
import { clearQuotes } from "../../product-page/components/comparePlanPopup.slice";
import CustomLifeCoverModal from "../../../components/modal/CustomLifeCoverModal";
import {
  getAgeValue,
  getGender,
  tobaccoHabit,
} from "../../../components/navbar/helper";
import * as mq from "../../../styles/mediaQueries";
import { useEffect } from "react";
// import { usePutCompareProductsMutation } from "../../riders/services/rider";
import "./../../product-page/QuotesPage.scss";
const ComparePageFilters = () => {
  const { filters, filterOptions } = useSelector(state => state.filters);
  // const [putCompareQuotes] = usePutCompareProductsMutation();
  const [showCustom, setShowCustom] = useState(false);
  const dispatch = useDispatch();
  const ref = useRef();

  // const updateCompareQuotes = () => {
  //   putCompareQuotes({}).then(() => {
  //     return;
  //   });
  // };
  // useEffect(() => {
  //   if (compareQuotes) {
  //     updateCompareQuotes();
  //   }
  // }, [filters]);

  const [colorChange, setColorchange] = useState(false);
  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY >= 80) {
        ref?.current?.classList?.add("filters__wrapper--top");
        setColorchange(true);
      } else {
        ref?.current?.classList?.remove("filters__wrapper--top");
        setColorchange(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);
  return (
    <div className="filters__wrapper" ref={ref}>
      {" "}
      <BasicDetails colorChange={colorChange} />
      <div className="filters__comp">
        <Select
          label={<>Life Cover</>}
          options={filterOptions.term_sum_assured}
          selectedItem={filters.term_sum_assured}
          valueStyle={{ fontSize: "11px" }}
          onChange={value => {
            dispatch(changeFilter({ term_sum_assured: value }));
            dispatch(clearQuotes());
          }}
          customButton={true}
          customButtonText="Custom Input"
          customOnClick={() => {
            setShowCustom(true);
            dispatch(clearQuotes());
          }}
          // isDifferent={
          //   filters.term_sum_assured !== defaultFilters?.term_sum_assured
          // }
        ></Select>
      </div>
      <div className="filters__comp">
        <Select
          label={<>Cover till age</>}
          valueStyle={{ fontSize: "11px" }}
          options={filterOptions.term_coverupto}
          selectedItem={filters.term_coverupto}
          onChange={value => {
            dispatch(changeFilter({ term_coverupto: value }));
            dispatch(clearQuotes());
          }}
          // isDifferent={
          //   filters.term_coverupto !== defaultFilters?.term_coverupto
          // }
        ></Select>
      </div>
      <div className="filters__comp">
        <Select
          label={<>Pay till age</>}
          valueStyle={{ fontSize: "11px" }}
          options={filterOptions.term_pay_till}
          selectedItem={
            filters.term_pay_till === 1 ? "Single Pay" : filters.term_pay_till
          }
          onChange={value => {
            dispatch(changeFilter({ term_pay_till: value }));
            dispatch(clearQuotes());
          }}
          customButtonText="Pay Once"
          customButton={true}
          customOnClick={() => {
            dispatch(changeFilter({ term_pay_till: 1 }));
          }}
          // isDifferent={filters.term_pay_till !== defaultFilters?.term_pay_till}
        ></Select>
      </div>
      <div className="filters__comp">
        {filters.term_pay_till === 1 ? (
          <div className="pt-2">Single Pay</div>
        ) : (
          <Select
            label={<>Pay</>}
            valueStyle={{ fontSize: "11px" }}
            options={filterOptions.term_pay_frequency}
            selectedItem={String(filters.term_pay_frequency)}
            onChange={value => {
              dispatch(changeFilter({ term_pay_frequency: value }));
              dispatch(clearQuotes());
            }}
            // isDifferent={filters.term_pay_frequency !== 0}
          ></Select>
        )}
      </div>
      <CustomLifeCoverModal
        showCustom={showCustom}
        setShowCustom={setShowCustom}
      />
    </div>
  );
};

export default ComparePageFilters;

export function BasicDetails({ colorChange }) {
  const { data } = useSelector(state => state.inputForm);

  return (
    <ListWrapper colorChange={colorChange}>
      <ul
        style={{
          margin: "auto",
          textTransform: "capitalize",
          fontFamily: "Inter",
          fontWeight: "500",
        }}
      >
        <li>{getGender(data.gender)}</li>
        <li>{getAgeValue(data.dob)}</li>
        <li>{tobaccoHabit(data.tobacco_habit)}</li>
      </ul>
    </ListWrapper>
  );
}

const ListWrapper = styled.div`
  padding-right: 10px;
  margin: auto;

  ${mq.sm} {
    padding: 0;
    background: #fff;
  }
  & ul {
    display: flex;
    list-style: none;
    justify-content: center;
    align-items: center;
    ${mq.sm} {
      height: 25px;
      padding: 0;
    }
    & li {
      font-size: 12px;
      border-radius: none !important;
      padding: 0px 5px;
      color: ${props => (props.colorChange ? "#fff" : "#555")};
      ${mq.sm} {
        padding: 0 1.5px;
        font-weight: 600;
        font-size: 10px;
      }
    }
    & li:not(:last-child) {
      border-right: 2px solid #ccc;
    }
    ${mq.sm} {
      & li:last-child {
        display: inline;
      }
    }
  }
`;
