import React, { useState } from "react";
import ProposalHeaderModal from "./ProposalHeaderModal";
const ShareProposal = () => {
  const [showDialog, setShowDialog] = useState(false);
  return (
    <>
      <button
        className="btn btn-primary py-2"
        onClick={e => setShowDialog(true)}
      >
        <div style={{ fontSize: "11px" }}>Share Proposal</div>
      </button>
      <ProposalHeaderModal
        showPopup={showDialog}
        handleClose={() => setShowDialog(false)}
      />
    </>
  );
};
export default ShareProposal;
