export const titleOptionsMale = [
  {
    code: "1",
    display_name: "Mr.",
  },
  {
    code: "3",
    display_name: "Dr.",
  },
  {
    code: "4",
    display_name: "Master",
  },
  {
    code: "6",
    display_name: "OTHERS",
  },
];
export const relationOptions = [
  { code: "Father", display_name: "Father" },
  { code: "Husband", display_name: "Husband" },
];
export const titleOptionsFemale = [
  {
    code: "2",
    display_name: "Mrs.",
  },
  {
    code: "3",
    display_name: "Dr.",
  },
  {
    code: "5",
    display_name: "Miss.",
  },
  {
    code: "6",
    display_name: "OTHERS",
  },
  {
    code: "7",
    display_name: "Ms.",
  },
];

export const maritalStatusOptions = [
  {
    code: "U",
    display_name: "Unmarried",
  },
  {
    code: "M",
    display_name: "Married",
  },
  {
    code: "W",
    display_name: "Widow/Widower",
  },
  {
    code: "D",
    display_name: "Divorced",
  },
  {
    code: "P",
    display_name: "Separated",
  },
];
export const identityProofOptions = [
  { code: "1018010075", display_name: "Driving License" },
  { code: "1018010082", display_name: "Aadhar card, UID" },
  { code: "1018010081", display_name: "Voter ID Card" },
  { code: "1018010077", display_name: "PAN Card" },
  { code: "1018010078", display_name: "Passport" },
  { code: "1018010523", display_name: "Job Card by NREGA" },
  { code: "1018010699", display_name: "Bank KYC" },
];
export const bank_details_options = [
  {
    code: "1",
    display_name: "Savings Account",
  },
  {
    code: "2",
    display_name: "Current Account",
  },
  {
    code: "3",
    display_name: "NRE Account",
  },
  {
    code: "4",
    display_name: "NRO Account",
  },
];
export const account_typesOptions = [
  {
    code: "S",
    display_name: "Savings",
  },
  {
    code: "C",
    display_name: "Current",
  },
  {
    code: "N",
    display_name: "NRE",
  },
  {
    code: "O",
    display_name: "NRO",
  },
];
export const bank_name_length = {
  2: 11,
  3: 11,
  4: 11,
  5: 11,
  6: 11,
  7: 11,
  8: 0,
  9: 11,
  10: 11,
  11: 15,
  12: 0,
  13: 15,
  14: 11,
  15: 0,
  16: 10,
  17: 15,
  18: 12,
  19: 0,
  20: 15,
  22: 14,
  23: 0,
  24: 0,
  25: 14,
  26: 15,
  27: 13,
  28: 14,
  29: 15,
  33: 0,
  34: 0,
  36: 11,
  37: 10,
  39: 12,
  47: 18,
  48: 0,
  49: 14,
  51: 16,
  52: 16,
  53: 16,
  54: 0,
  56: 16,
  59: 16,
  60: 0,
  64: 12,
  65: 0,
  66: 0,
  68: 0,
  69: 0,
  71: "",
  72: 14,
  73: "",
  74: 16,
  76: "",
  77: "",
  79: "",
  82: "",
  84: "",
  85: "",
  86: "",
  87: "",
  88: "",
  89: "",
  91: "",
  92: "",
  93: "",
  94: "",
  95: "",
  96: "",
  98: "",
  99: "",
  101: "",
  103: "",
  105: "",
  107: "",
  108: "",
  109: "",
  110: "",
  111: "",
  112: "",
  113: "",
  114: "",
  116: "",
  117: "",
  118: "",
  124: "",
  126: "",
  128: "",
  130: "",
  131: "",
  134: "",
  135: "",
  136: "",
  138: "",
  139: "",
  141: "",
  142: "",
  143: "",
  144: "",
  149: "",
  150: "",
  153: "",
  154: "",
  156: "",
  159: "",
  160: "",
  164: "",
  165: "",
  168: "",
  171: "",
  172: "",
  174: "",
  175: "",
  176: 12,
  177: "",
  178: "",
  180: "",
  182: "",
  184: "",
  189: "",
  191: "",
  192: "",
  195: "",
  196: "",
  197: "",
  198: "",
  199: "",
  200: "",
  201: "",
  202: "",
  207: "",
  208: "",
  209: "",
  210: "",
  211: 15,
  213: "",
  215: "",
  217: "",
  218: "",
  219: "",
  222: "",
  225: "",
  226: "",
  227: "",
  229: 12,
  233: "",
  234: 12,
  235: "",
  238: "",
  240: 14,
  242: "",
  243: "",
  245: "",
  246: "",
  248: "",
  249: "",
  250: "",
  251: "",
  255: "",
  256: "",
  257: "",
  258: "",
  259: "",
  261: "",
  267: "",
  271: "",
  272: "",
  275: "",
  278: "",
  279: "",
  280: "",
  281: "",
  283: "",
  284: "",
  285: "",
  286: "",
  288: "",
  289: "",
  291: "",
  297: "",
  299: "",
  302: "",
  303: "",
  304: "",
  305: "",
  308: "",
  309: "",
  310: "",
  312: "",
  313: "",
  314: "",
  315: "",
  318: "",
  319: "",
  320: "",
  322: "",
  323: "",
  325: "",
  326: "",
  328: "",
  329: "",
  330: "",
  331: "",
  332: "",
  335: "",
  337: "",
  340: "",
  341: "",
  344: "",
  345: "",
  348: "",
  349: "",
  350: "",
  352: "",
  353: "",
  355: "",
  358: "",
  360: "",
  361: "",
  363: "",
  364: "",
  365: "",
  367: "",
  369: "",
  371: "",
  372: "",
  374: "",
  375: "",
  376: "",
  381: "",
  384: "",
  385: "",
  386: "",
  387: "",
  389: "",
  390: "",
  393: "",
  394: "",
  395: "",
  396: "",
  397: "",
  398: "",
  401: "",
  402: "",
  403: "",
  404: "",
  407: "",
  410: "",
  411: "",
  412: "",
  413: "",
  415: "",
  416: "",
  417: "",
  418: "",
  419: "",
  420: "",
  421: "",
  422: "",
  432: "",
  434: "",
  437: "",
  438: "",
  441: "",
  442: "",
  443: "",
  445: "",
  446: "",
  448: "",
  449: "",
  450: "",
  451: "",
  454: "",
  455: "",
  456: "",
  458: "",
  459: "",
  460: "",
  461: "",
  462: "",
  465: "",
  466: "",
  468: "",
  470: "",
  471: "",
  472: "",
  473: "",
  474: "",
  475: "",
  476: "",
  479: "",
  480: "",
  481: "",
  485: "",
  486: "",
  487: "",
  488: "",
  489: "",
  490: "",
  491: "",
  492: "",
  493: "",
  494: "",
  495: "",
  497: "",
  500: "",
  502: "",
  503: "",
  504: "",
  506: "",
  507: "",
  508: "",
  509: "",
  510: "",
  511: "",
  512: "",
  518: "",
  520: "",
  521: "",
  524: "",
  525: "",
  526: "",
  532: "",
  541: "",
  542: "",
  544: "",
  545: "",
  547: "",
  554: "",
  556: "",
  561: "",
  564: "",
  569: "",
  570: "",
  574: "",
  577: "",
  579: "",
  582: "",
  584: "",
  586: "",
  587: "",
  588: "",
  589: "",
  590: "",
  591: "",
  592: "",
  593: "",
  596: "",
  597: "",
  598: "",
  599: "",
  600: "",
  601: "",
  602: "",
  603: "",
  604: "",
  605: "",
  606: "",
  607: "",
  608: "",
  611: "",
  612: "",
  614: "",
  615: "",
  618: "",
  619: "",
  620: "",
  621: "",
  622: "",
  639: "",
  640: "",
  641: 12,
  642: "",
  643: "",
  644: "",
  645: "",
  648: "",
  652: "",
  653: "",
  658: "",
  659: "",
  660: "",
  661: "",
  662: "",
  663: 14,
  693: "",
  694: "",
  696: "",
  700: "",
  702: "",
  703: "",
  706: "",
  707: "",
  733: "",
  800: "",
  801: "",
  802: "",
  803: "",
  804: "",
  805: "",
  806: "",
  807: "",
  808: "",
  811: "",
  812: "",
  814: "",
  826: "",
  828: "",
  829: "",
  832: "",
  834: "",
  836: "",
  838: "",
  840: "",
  841: "",
  843: "",
  851: "",
  853: 0,
  854: 0,
  859: 0,
  899: 0,
  903: 0,
  998: 0,
  999: "",
  "000": 0,
  "037": 10,
  "016": 10,
  "025": 14,
  "054": "",
  "009": 11,
  "022": 14,
  "056": 16,
  "006": 11,
  "023": "",
  "059": 16,
  "072": 14,
  "082": "",
  "011": 15,
  "015": "",
  "053": 16,
  "060": "",
  "020": 15,
  "004": 11,
  "008": "",
  "012": 0,
  "029": 15,
  "049": 14,
  "064": 12,
  "069": "",
  "077": "",
  "085": "",
  "089": "",
  "099": "",
  "005": 11,
  "013": 15,
  "017": 15,
  "026": 15,
  "033": "",
  "039": 12,
  "051": 16,
  "065": "",
  "071": "",
  "076": "",
  "084": "",
  "088": "",
  "093": "",
  "098": "",
  "002": 11,
  "010": 11,
  "014": 11,
  "018": 12,
  "027": 13,
  "034": "",
  "047": 16,
  "052": 16,
  "066": "",
  "087": "",
  "092": "",
  "096": "",
  "003": 11,
  "007": 11,
  "019": "",
  "024": 20,
  "028": 14,
  "036": 11,
  "048": "",
  "068": "",
  "073": "",
  "079": "",
  "086": "",
  "091": "",
  "095": "",
};
export const bank_name = [
  { code: "853", display_name: "THE VAIDYANATH URBAN CO OP BANK LTD" },
  { code: "854", display_name: "POORNAWADI NAGRIK SAHAKARI BAN" },
  { code: "859", display_name: "Bhingar Urban Coop Bank" },
  { code: "899", display_name: "RESERVE BANK EMPLOYEES CO-OPER" },
  { code: "903", display_name: "DURGA CO-OP. URBAN BANK LTD" },
  { code: "998", display_name: "Credit Co Op Bank" },
  { code: "2", display_name: "STATE BANK OF INDIA" },
  { code: "3", display_name: "STATE BANK OF BIKANER & JAIPUR" },
  { code: "4	", display_name: "STATE BANK OF HYDERABAD" },
  { code: "5", display_name: "STATE BANK OF INDORE(SBN)" },
  { code: "6", display_name: "STATE BANK OF MYSORE" },
  { code: "7	", display_name: "STATE BANK OF PATIALA(007)" },
  { code: "8", display_name: "STATE BANK OF SAURASHTRA (MERG)" },
  { code: "9", display_name: "STATE BANK OF TRAVANCORE" },
  { code: "10", display_name: "ALLAHABAD BANK(ALB)" },
  { code: "11", display_name: "ANDHRA BANK" },
  { code: "12", display_name: "BANK OF BARODA" },
  { code: "13", display_name: "BANK OF INDIA" },
  { code: "14", display_name: "BANK OF MAHARASHTRA" },
  { code: "15", display_name: "CANARA BANK" },
  { code: "16", display_name: "CENTRAL BANK OF INDIA (CBI)  " },
  { code: "17", display_name: "CORPORATION BANK" },
  { code: "18", display_name: "DENA BANK" },
  { code: "19", display_name: "INDIAN BANK(INB)" },
  { code: "20", display_name: "INDIAN OVERSEAS BANK(IOB)" },
  { code: "22", display_name: "ORIENTAL BANK OF COMMERCE" },
  { code: "23", display_name: "PUNJAB AND SIND BANK" },
  { code: "24", display_name: "PUNJAB NATIONAL BANK" },
  { code: "25", display_name: "SYNDICATE BANK" },
  { code: "26", display_name: "UNION BANK OF INDIA(UBI)" },
  { code: "27", display_name: "UNITED BANK OF INDIA (027)" },
  { code: "28", display_name: "UCO BANK" },
  { code: "29", display_name: "VIJAYA BANK" },
  { code: "33", display_name: "BANK OF TOKYO LTD" },
  { code: "34", display_name: "BANQUE NATIONALE DE PARIS" },
  { code: "36", display_name: "STANDARD CHARTERED BANK" },
  { code: "37", display_name: "CITI BANK" },
  { code: "39", display_name: "HONGKONG SHANGHAI BKG.CORP." },
  { code: "47", display_name: "CATHOLIC SYRIAN BANK LTD." },
  { code: "48", display_name: "DHANALAKSHMI BANK LTD" },
  { code: "49", display_name: "FEDERAL BANK LTD" },
  { code: "51", display_name: "JAMMU AND KASHMIR BANK LTD" },
  { code: "52", display_name: "Karnataka bank" },
  { code: "53", display_name: "KARUR VYSYA BANK LTD.(KVB)" },
  { code: "54", display_name: "CITY UNION BANK LTD" },
  { code: "56", display_name: "LVB-LAKSHMI VILAS BANK LTD" },
  { code: "59", display_name: "SOUTH INDIAN BANK LTD.(SIB)" },
  { code: "60", display_name: "TAMILNAD MERCANTILE BANK LTD." },
  { code: "64", display_name: "VYSYA BANK LTD.(VBL)" },
  { code: "65", display_name: "ABHYUDAYA CO-OP BANK LTD" },
  { code: "66", display_name: "AHMEDABAD MERCANTILE CO-OP BANK LTD" },
  { code: "68", display_name: "MUMBAI DIST.CENTRAL CO-OP BANK" },
  { code: "69", display_name: "BMC BANK LTD" },
  { code: "71", display_name: "DECCAN MERCHANTS CO-OP BANK LTD" },
  { code: "72", display_name: "DEVELOPMENT CREDIT BANK LTD" },
  { code: "73", display_name: "JAIN SAHAKARI BANK LTD" },
  { code: "74", display_name: "JANATA SAHAKARI BANK LTD" },
  { code: "76", display_name: "KAPOLE CO-OP BANK LTD" },
  { code: "77", display_name: "KURLA NAGARIK SAHAKARI BANK LTD" },
  { code: "79", display_name: "THE MALAD SAHAKARI BANK LTD" },
  { code: "82", display_name: "MAHARASHTRA STATE CO-OP BANK LTD" },
  { code: "84", display_name: "MOGAVEERA CO-OP BANK LTD" },
  { code: "85", display_name: "NEW INDIA CO-OP BANK LTD" },
  { code: "86", display_name: "N.K.G.S.B.CO-OP BANK LTD" },
  { code: "87", display_name: "SAHYADRI SAH. BANK LTD" },
  { code: "88", display_name: "SARASWAT BANK" },
  { code: "89", display_name: "THE SHAMRAO VITHAL CO-OP BANK LTD." },
  { code: "91", display_name: "TAMILNADU STATE CO-OP BANK LTD" },
  { code: "92", display_name: "MADRAS CENTRAL CO-OP BANK LTD" },
  { code: "93", display_name: "WEST BENGAL STATE CO-OP BANK" },
  { code: "94", display_name: "NOIDA COMMERCIAL COOPERATIVE BANK LTD" },
  { code: "95", display_name: "GREATER BOMBAY CO-OP BANK LTD" },
  { code: "96", display_name: "THE MAHANAGAR CO-OP. BANK LTD" },
  { code: "98", display_name: "APNA SAHAKARI BANK LTD" },
  { code: "99", display_name: "SONALI BANK LIMITED" },
  { code: "101", display_name: "BANTRA CO-OP BANK LTD" },
  { code: "103", display_name: "LILUAH CO-OP BANK LTD" },
  { code: "105", display_name: "JANAKALYAN SAHAKARI BANK LTD" },
  { code: "107", display_name: "THE SATARA SAHAKARI BANK LTD" },
  { code: "108", display_name: "THE SAFE CO-OP BANK LTD" },
  { code: "109", display_name: "THANE JANATA SAHAKARI BANK LTD" },
  { code: "110", display_name: "THE MUNCIPAL CO-OP BANK LTD" },
  { code: "111", display_name: "	GENERAL POST OFFICE" },
  { code: "112", display_name: "THE BHARAT CO-OP BANK LTD" },
  { code: "113", display_name: "GUWAHATI CO-OP URBAN BANK LTD" },
  { code: "114", display_name: "INDUSTRIAL CO-OP BANK LTD" },
  { code: "116", display_name: "ASSAM GRAMIN VIKASH BANK(PJG)" },
  { code: "117", display_name: "ASSAM CO-OP APEX BANK LTD" },
  { code: "118", display_name: "AHMEDABAD DIST.CO-OP. BANK LTD.(ADC)" },
  { code: "124", display_name: "GUJRATH STATE CO-OP BANK LTD" },
  { code: "126", display_name: "KALUPUR COMMERCIAL CO-OP BANK LTD" },
  { code: "128", display_name: "NUTAN NAGRIK SAHAKRI BANK LTD" },
  { code: "130", display_name: "SOCIAL CO-OP BANK LTD" },
  { code: "131", display_name: "TEXTILE TRADERS CO-OP BANK LTD" },
  { code: "134", display_name: "BHAGYODAYA CO-OP BANK LTD" },
  { code: "135", display_name: "SARANGPURA CO-OP BANK LTD" },
  { code: "136", display_name: "UNION CO-OP BANK LTD" },
  { code: "138", display_name: "COLOUR MERCHANTS CO-OP BANK LTD" },
  { code: "139", display_name: "NAVNIRMAN CO-OP BANK LTD" },
  { code: "141", display_name: "PROGRESSIVE MERC.CO-OP BANK LTD" },
  { code: "142", display_name: "ANDHRA PRADESH MAHESH CO-OP UR" },
  { code: "143", display_name: "ANDHRA PRADESH STATE CO-OP BANK LTD" },
  { code: "144", display_name: "HYDERABAD DIST.CO-OP CENTRAL BANK LTD" },
  { code: "149", display_name: "PATAN CO-OP BANK LTD" },
  { code: "150", display_name: "BANK OF BAHRAIN AND KUWAIT BSC" },
  { code: "153", display_name: "BARODA CENTRAL CO-OP BANK LTD" },
  { code: "154", display_name: "BARODA TRADERS CO-OP BANK LTD" },
  { code: "156", display_name: "BARODA CITY CO-OP BANK LTD" },
  { code: "159", display_name: "MAKARPURA INDL.EST.CO-OP BANK LTD" },
  { code: "160", display_name: "VARDHAMAN SAH.BANK LTD" },
  { code: "164", display_name: "COSMOS CO-OP BANK LTD" },
  { code: "165", display_name: "THE MUSLIM CO-OP BANK LTD" },
  { code: "168", display_name: "PUNE PEOPLES CO-OP BANK LTD" },
  { code: "171", display_name: "VIDYA SAH.BANK LTD" },
  { code: "172", display_name: "PUNE HEAD POST OFFICE" },
  { code: "174", display_name: "PUNE ZILLA MADHYA.SAH.BANK MARYADIT" },
  { code: "175", display_name: "MAHESH SAH.BANK LTD" },
  { code: "176", display_name: "RATNAKAR BANK LTD" },
  { code: "177", display_name: "KERALA STATE CO-OP BANK LTD" },
  { code: "178", display_name: "LORD KRISHNA BANK LTD.(LKR)" },
  { code: "180", display_name: "TRIVANDRUM DIST.CO-OP BANK LTD" },
  { code: "182", display_name: "SHIKSHAK SAH.BANK LTD" },
  { code: "184", display_name: "NAINITAL BANK LTD" },
  { code: "189", display_name: "JAIPUR CENTRAL CO-OP BANK LTD" },
  { code: "191", display_name: "Rajasthan Urban Co-op Bank" },
  { code: "192", display_name: "URBAN CO-OP BANK LTD" },
  { code: "195", display_name: "THE VAISH CO-OP NEW BANK LTD" },
  { code: "196", display_name: "DELHI NAGARIK SAH.BANK LTD" },
  { code: "197", display_name: "GRAIN MERCHANTS CO-OP BANK LTD" },
  { code: "198", display_name: "TRIVANDRUM CO-OP URBAN BANK LTD" },
  { code: "199", display_name: "	CHITANVISPURA FRIENDS CO-OP BANK LTD" },
  { code: "200", display_name: "DEUTSCHE BANK" },
  { code: "201", display_name: "SHREE MAHALAXMI MER.CO-OP BANK LTD" },
  { code: "202", display_name: "PRAGATI SAHAKARI BANK LTD" },
  { code: "207", display_name: "SADHANA SAHAKARI BANK LTD" },
  { code: "208", display_name: "VAISH CO-OP ADARSH BANK LTD" },
  { code: "209", display_name: "CITIZEN CREDIT CO-OP BANK LTD" },
  { code: "210", display_name: "OMAN INTERNATIONAL BANK SAOG" },
  { code: "211", display_name: "AXIS BANK LTD." },
  { code: "213", display_name: "BIHAR STATE CO-OP BANK LTD" },
  { code: "215", display_name: "SARASPUR NAGRIK SAHKARI BANK(SNB)" },
  { code: "217", display_name: "RAJKOT NAGRIK SAHAKARI BANK LTD" },
  { code: "218", display_name: "ORRISA STATE CO-OP BANK LTD" },
  { code: "219", display_name: "NEELANCHAL GRAMYA BANK" },
  { code: "222", display_name: "A.P. VARDHAMAN (MAHILA) CO-OP BANK LTD" },
  { code: "225", display_name: "BCO-Sreenidhi Sahakara Bank Niyamitha" },
  { code: "226", display_name: "APEX BANK" },
  { code: "227", display_name: "MALLESWARAN CO-OP BANK LTD" },
  { code: "229", display_name: "I.C.I.C.I. BANKLTD." },
  { code: "233", display_name: "HDFC BANK (ERSTWHILE CENTURION)" },
  { code: "234", display_name: "INDUSIND BANK LTD" },
  { code: "235", display_name: "DOMBIVLI NAG.SAH.BANK LTD" },
  { code: "238", display_name: "BASSAIN CATHOLIC CO-OP BANK LTD" },
  { code: "240", display_name: "HDFC BANK LTD.(HDF)" },
  { code: "242", display_name: "PANCHSHEEL MERCANTILE CO-OP BANK LTD" },
  { code: "243", display_name: "THE RANDER PEOPLES CO-OP BANK LTD" },
  { code: "245", display_name: "SURAT MERCANTILE CO-OP BANK LTD" },
  { code: "246", display_name: "SURAT NATIONAL CO-OP BANK LTD" },
  { code: "248", display_name: "SURAT TEXTILE TRADERS CO-OP BANK LTD" },
  { code: "249", display_name: "SARVODAYA SAHAKARI BANK LTD" },
  { code: "250", display_name: "PRIME CO-OP BANK LTD" },
  { code: "251", display_name: "SURAT PEOPLES CO-OP BANK LTD" },
  { code: "255", display_name: "INDRAPRASTHA SAH. BANK LTD" },
  { code: "256", display_name: "SARDAR VALLABHBHAI SAH. BANK LTD" },
  { code: "257", display_name: "JANASEVA SAHAKARI BANK LTD" },
  { code: "258", display_name: "SIDDI CO-OP BANK LTD" },
  { code: "259", display_name: "I.D.B.I. BANK LTD." },
  { code: "261", display_name: "SHRI M.VISVESVASRAYA CO-OP BANK" },
  { code: "267", display_name: "MAHILA CO-OP BANK LTD" },
  { code: "271", display_name: "THE TEXTILE CO-OP BANK OF SURAT LTD" },
  { code: "272", display_name: "BHAGINI NIVEDITA SAH. BANK MAR" },
  { code: "274", display_name: "THE BHARAT CO-OP BANK LTD" },
  { code: "275", display_name: "THE NATIONAL CO-OP BANK LTD" },
  { code: "278", display_name: "INDORE CLOTH MARKET CO-OP BANK LTD" },
  { code: "279", display_name: "INDORE PARASPARA SAH. BANK LTD" },
  { code: "280", display_name: "INDORE PREMIER CO-OP BANK LTD" },
  { code: "281", display_name: "M.P.RAJYA SAH. BANK LTD" },
  { code: "283", display_name: "NAGRIK SAH. BANK LTD" },
  { code: "284", display_name: "PARASPAR SAHAYAK CO-OP BANK LTD" },
  { code: "285", display_name: "SHUBH-LAXMI MAHILA CO-OP BANK LTD" },
  { code: "286", display_name: "TRANSPORT CO-OP BANK LTD" },
  { code: "288", display_name: "RESERVE BANK EMPLOYEES CO-OP BANK LTD" },
  { code: "289", display_name: "THE VARACHHA CO-OP BANK LTD" },
  { code: "291", display_name: "THE SHIVAJI-NAGAR CO-OP BANK LTD" },
  { code: "297", display_name: "CITIZEN URBAN COOP BANK LTD" },
  { code: "299", display_name: "DISTRICT CO-OP BANK LTD" },
  { code: "302", display_name: "THE NARODA NAGRIK CO-OP BANK LTD" },
  { code: "303", display_name: "KANKARIA MANINAGAR NAG.SAH.BANK LTD" },
  { code: "304", display_name: "KANGRA CO-OP BANK LTD" },
  { code: "305", display_name: "KHATRI CO-OP URBAN BANK LTD" },
  { code: "308", display_name: "JHARNESHWAR NAG.SAH.BANK MARYADIT" },
  { code: "309", display_name: "BHOPAL CO-OP CENTRAL BANK LTD" },
  { code: "310", display_name: "SADGURU NAGRIK SAHAKARI BANK MARYADIT" },
  { code: "312", display_name: "PARSIK JANATA SAH.BANK LTD" },
  { code: "313", display_name: "MEHASANA URBAN CO-OP BANK LTD" },
  { code: "314", display_name: "THE CITY CO-OP BANK LTD" },
  { code: "315", display_name: "SHRI CHHANI NAG.SAH.BANK LTD" },
  { code: "318", display_name: "COMMERCIAL CO-OP BANK LTD" },

  { code: "319", display_name: "SANKHEDA NAG.SAH.BANK LTD" },
  { code: "320", display_name: "MAHESANA SAMAJ CO-OP BANK LTD" },
  { code: "322", display_name: "ALAVI CO-OP BANK LTD" },
  { code: "323", display_name: "UMA CO-OP BANK LTD" },
  { code: "325", display_name: "SULAIMANI CO-OP BANKING SOCIETY LTD" },
  { code: "326", display_name: "SHRI CO-OP BANK LTD" },
  { code: "328", display_name: "PUNJAB AND MAHARASHTRA CO-OP BANK LTD" },
  { code: "329", display_name: "JAIN CO-OP BANK LTD" },
  { code: "330", display_name: "AKOLA URBAN CO-OP BANK LTD" },
  { code: "331", display_name: "BANK OF CEYLON" },
  { code: "332", display_name: "STATE BANK OF MAURITIUS LTD" },
  { code: "335", display_name: "VSL-VISHWESHWAR SAHAKARI BANK" },
  {
    code: "337",
    display_name: "GURDASPUR-AMRITSAR KSHETRIYA GRAMIN VIKAS BANK",
  },
  { code: "340", display_name: "ASTHA MAHILA NAGRIK SAHAKARI BANK MARYADIT" },
  { code: "341", display_name: "MAHANAGAR NAGRIK SAHAKARI BANK MARYADIT" },
  { code: "344", display_name: "TIRUPATI URBAN CO-OP BANK LTD" },
  { code: "345", display_name: "Abhinav Sahakari Bank" },
  { code: "348", display_name: "JAIPUR THAR GRAMIN BANK" },
  { code: "349", display_name: "SHRAMIK SAHAKARI BANK LTD" },
  { code: "350", display_name: "PUNJAB STATE CO-OP BANK LTD" },
  { code: "352", display_name: "SUVARNAYUG SAHAKARI BANK LTD" },
  { code: "353", display_name: "RAJARSHI SHAHU SAHAKARI BANK MARYADIT" },
  { code: "355", display_name: "BHARATI SAHAKARI BANK LTD" },
  { code: "358", display_name: "SHREE SHARADA SAHAKARI BANK LTD" },
  { code: "360", display_name: "GANDHIBAGH SAHAKARI BANK LTD" },
  { code: "361", display_name: "JANATA CO-OP BANK LTD" },
  { code: "363", display_name: "KRISHNA MERCANTILE CO-OP BANK LTD" },
  { code: "364", display_name: "AKOLA JANATA COMMERCIAL CO-OP BANK LTD" },
  { code: "365", display_name: "SUB-SHRIRAM URBAN CO-OP. BANK" },
  { code: "367", display_name: "KALYAN JANATA SAHAKARI BANK LTD" },
  { code: "369", display_name: "NASIK MERCHANTS CO-OP BANK LTD" },
  { code: "371", display_name: "NASIK ZILHA MAHILA SAHAKARI BANK LTD" },
  { code: "372", display_name: "NASIK DISTRICT CENTRAL CO-OP BANK LTD" },
  { code: "374", display_name: "NASIK ROAD DEOLALI VYAPARI SAHAKARI BANK LTD" },
  { code: "375", display_name: "SHREE SAMARTH SAHAKARI BANK LTD" },
  { code: "376", display_name: "GODAVARI URBAN CO-OP BANK LTD" },
  { code: "381", display_name: "NASIK JILHA MAHILA VIKAS SAHAKARI BANK LTD" },
  { code: "384", display_name: "TRICHUR URBAN CO-OP BANK LTD" },
  { code: "385", display_name: "INDIAN MERCANTILE CO-OP BANK LTD" },
  { code: "386", display_name: "ICHALKARANJI JANATA SAHAKARI BANK" },
  { code: "387", display_name: "KOLHAPUR DISTRICT CENTRAL CO-OP BANK LTD" },
  { code: "389", display_name: "SHRI MAHALAXMI CO-OP BANK LTD" },
  { code: "390", display_name: "STATE TRANSPORT CO-OP BANK LTD" },
  { code: "393", display_name: "YOUTH DEVELOPMENT CO-OP BANK LTD" },
  { code: "394", display_name: "THE AJARA URBAN CO-OP BANK LTD" },
  { code: "395", display_name: "CHOUNDESHWARI CO-OP BANK LTD" },
  { code: "396", display_name: "SHRIPATRAO DADA SAHAKARI BANK LTD" },
  { code: "397", display_name: "SHRI WARANA SAHAKARI BANK LTD" },
  { code: "398", display_name: "KOLHAPUR URBAN CO-OP BANK LTD" },

  { code: "401", display_name: "KOLHAPUR MAHILA SAHAKARI BANK LTD" },
  { code: "402", display_name: "SHREE PANCHGANGA NAGARI SAHAKARI BANK LTD" },
  { code: "403", display_name: "THE VADGAON URBAN CO-OP BANK LTD" },
  { code: "404", display_name: "GADHINGLAJ URBAN CO-OP BANK LTD" },
  { code: "406", display_name: "JANATA SAHAKARI BANK LTD" },
  { code: "407", display_name: "SHRI VEERSHAIV CO-OP BANK LTD" },
  { code: "409", display_name: "THE NATIONAL CO-OP BANK LTD" },
  { code: "410", display_name: "JAMIA CO-OP BANK LTD" },
  { code: "411", display_name: "TAMILNADU INDL.COOP.BANK LTD" },
  { code: "412", display_name: "THE SEVA VIKAS CO-OP BANK LTD" },
  { code: "413", display_name: "BICHOLIM URBAN CO-OP BANK LTD" },
  { code: "415", display_name: "GOA STATE CO-OP BANK LTD" },
  { code: "416", display_name: "THE GOA URBAN CO OP BANK LTD" },
  { code: "417", display_name: "MADGAUM URBAN CO-OP BANK LTD" },
  { code: "418", display_name: "MAPUSA URBAN CO-OP BANK LTD" },
  { code: "419", display_name: "FINANCIAL CO-OP BANK LTD" },
  { code: "420", display_name: "APANI SAHAKARI BANK LTD" },
  { code: "421", display_name: "BAVLA NAGRIK SAHAKARI BANK LTD" },
  { code: "422", display_name: "UNITED CO-OP BANK LTD" },
  { code: "432", display_name: "ADINATH CO-OP BANK LTD" },
  { code: "434", display_name: "KUKARWADA NAG.SAH.BANK LTD" },
  { code: "437", display_name: "AKHAND ANAND CO-OP BANK LTD" },
  { code: "438", display_name: "THE ANDHRA BANK EMPLOYEES CO-OP BANK LTD" },
  { code: "441", display_name: "CO-OPERATIVE BANK OF RAJKOT LTD" },
  { code: "442", display_name: "SHREE DHARTI CO-OP BANK LTD" },
  { code: "443", display_name: "JIVAN COMM.CO-OP BANK LTD" },
  { code: "445", display_name: "RAJKOT PEOPLES CO-OP BANK LTD" },
  { code: "446", display_name: "SREE CHARAN CO-OP BANK LTD" },
  { code: "448", display_name: "AVADH GRAMIN BANK" },
  { code: "449", display_name: "ASSOCIATE CO-OP BANK LTD" },
  { code: "450", display_name: "NAGRIK SAMABAY BANK LTD" },
  { code: "451", display_name: "THE WOMENS CO-OP BANK LTD" },
  { code: "454", display_name: "BARODA UP GRAMIN BANK" },
  { code: "455", display_name: "SREE SUBRAMANYESWARA CO-OP BANK LTD" },
  { code: "456", display_name: "INTEGRAL URBAN CO-OP BANK" },
  { code: "458", display_name: "GEORGE TOWN CO-OP BANK LTD" },
  { code: "459", display_name: "PANCHMAHAL-VADODARA GRAMIN BAN" },
  { code: "460", display_name: "NORTH MALABAR GRAMIN BANK" },
  { code: "461", display_name: "AGRA DISTRICT CO-OP BANK LTD" },
  { code: "462", display_name: "JAMUNA GRAMIN BANK" },
  { code: "465", display_name: "SHREE LAXMI CO-OP BANK LTD" },
  { code: "466", display_name: "THE ALWAYE URBAN CO-OP BANK LTD" },
  { code: "468", display_name: "VIJAY COMMERCIAL CO-OP BANK LTD" },
  { code: "470", display_name: "NAGRIK SAHAKARI BANK LTD" },
  { code: "471", display_name: "CITIZENS CO-OP BANK LTD" },
  { code: "472", display_name: "VERAVAL MERCANTILE CO-OP BANK LTD" },
  { code: "473", display_name: "RAJKOT COMMERCIAL CO-OP BANK LTD" },
  { code: "474", display_name: "SHRI RAJKOT DISTRICT CO-OP BANK" },
  { code: "475", display_name: "SHREE PARSWANATH CO-OP BANK LTD" },
  { code: "476", display_name: "JILA SAHAKARI KENDRIYA BANK MARYADIT" },
  { code: "479", display_name: "JILA SAHAKARI CENTRAL BANK MARYADIT" },
  { code: "480", display_name: "SOUTH MALABAR GRAMIN BANK" },
  { code: "481", display_name: "THRISSUR DISTRICT CO-OP BANK LTD" },
  { code: "485", display_name: "KOTAK MAHINDRA BANK LTD" },
  { code: "486", display_name: "	KASHI GRAMIN BANK" },
  { code: "487", display_name: "BANARAS MERCANTILE CO-OP BANK LTD" },
  { code: "488", display_name: "KARAD URBAN CO-OP BANK LTD" },
  { code: "489", display_name: "GWALIOR DATIA KSHETRIYA GRAMIN BANK" },
  { code: "490", display_name: "ALLAHABAD DISTRICT CO-OP BANK LTD" },
  { code: "491", display_name: "CHHATTISGARH RAJYA SAHAKARI BANK MARYADIT" },
  {
    code: "492",
    display_name: "INDIRA PRIYADARSHINI MAHILA NAG.SAH.BANK MARYADIT",
  },
  { code: "493", display_name: "LAXMI MAHILA NAGRIK SAHAKARI BANK MARYADIT" },
  { code: "494", display_name: "RAIPUR URBAN MERCANTILE CO-OP BANK LTD" },
  { code: "495", display_name: "VYAVSAIK SAHAKARI BANK LTD" },
  { code: "496", display_name: "NAGRIK SAHAKARI BANK LTD" },
  {
    code: "497",
    display_name: "LAXMIBAI MAHILA NAGRIK SAHAKARI BANK MARYADIT",
  },
  { code: "500", display_name: "JPMORGAN CHASE BANK N.A." },
  { code: "502", display_name: "BUSINESS CO-OP BANK MARYADIT" },
  { code: "503", display_name: "JANAKALYAN CO-OP BANK LTD" },
  { code: "504", display_name: "VISHWAS CO-OP BANK LTD" },
  {
    code: "506",
    display_name: "PADMASHRI DR. VITHALRAO VIKHE PATIL CO-OP BANK LTD",
  },
  { code: "507", display_name: "JALGAON JANATA SAHAKARI BANK LTD" },
  { code: "508", display_name: "SANGAMNER MER. CO.OP.BK." },
  { code: "509", display_name: "NASIK ZILLA GIRNA SAHAKARI BANK LTD" },
  { code: "510", display_name: "JANASEVA CO-OP BANK LTD" },
  { code: "511", display_name: "DR.BABASAHEB AMBEDKAR SAH. BANK LTD" },
  { code: "512", display_name: "CENTRAL MADHYA PRADESH GRAMIN BANK" },
  { code: "518", display_name: "PIMPALGAON MERCHANTS CO-OP BANK LTD" },
  { code: "520", display_name: "FEROKE CO-OP URBAN BANK LTD" },
  { code: "521", display_name: "CHHATISGARH GRAMIN BANK" },
  { code: "522", display_name: "JILA SAHAKARI KENDRIYA BANK MARYADIT" },
  { code: "524", display_name: "THE THANE DISTRICT CENTRAL CO-" },
  { code: "525", display_name: "THANE BHARAT SAHAKARI BANK LTD" },
  { code: "526", display_name: "DR. AMBEDKAR NAGRIK SAHAKARI BANK MARYADIT" },
  { code: "532", display_name: "YES BANK LTD." },
  { code: "541", display_name: "DEHRADUN DISTRICT CO-OP BANK" },
  { code: "542", display_name: "URBAN CO-OP BANK" },
  { code: "544", display_name: "UTTARANCHAL GRAMIN BANK" },
  { code: "545", display_name: "CLA-CAPITAL LOCAL AREA BANK(CL)" },
  {
    code: "547",
    display_name: "TRICHIRAPALLI DISTRICT CENTRAL CO-OP BANK LTD",
  },
  { code: "554", display_name: "UTTARANCHAL STATE CO-OP. BANK LTD" },
  { code: "555", display_name: "BANK OF INDIA" },
  { code: "556", display_name: "SHRAMIK NAGARIK SAHAKARI BANK LTD" },
  { code: "561", display_name: "MALKAPUR URBAN CO-OP BANK LTD" },
  { code: "564", display_name: "	SUNDARLAL SAWJI URBAN CO-OP. BANK LTD" },
  { code: "569", display_name: "PEOPLES CO-OPERATIVE BANK LTD" },
  { code: "570", display_name: "MAHARASHTRA GODAVARI GRAMIN BANK" },
  { code: "574", display_name: "TUMKUR GRAIN MERCHANTS CO-OP BANK LTD" },
  { code: "577", display_name: "UDAIPUR URBAN CO-OP. BANK LTD.(UUC)" },
  { code: "579", display_name: "AURANGABAD DIST. CENTRAL CO-OP.BANK LTD" },
  { code: "582", display_name: "DEOGIRI NAGARI SAHAKARI BANK LTD" },
  { code: "584", display_name: "RAJARAMBAPU SAHAKARI BANK LTD" },
  { code: "586", display_name: "SOLAPUR DIST. CENTRAL CO-OP. BANK LTD" },
  { code: "587", display_name: "SOLAPUR JANATA SAHAKARI BANK LTD" },
  { code: "588", display_name: "PANDHARPUR URBAN CO-OP. BANK LTD" },
  { code: "589", display_name: "SOLAPUR SIDDEHWAR SAHAKARI BANK LTD" },
  { code: "590", display_name: "SOLAPUR SOCIAL URBAN CO-OP.BANK LTD" },
  { code: "591", display_name: "VIKAS SAHAKARI BANK LTD" },
  { code: "592", display_name: "VYAPARI SAHAKARI BANK LTD" },
  { code: "593", display_name: "BRAHMADEODADA MANE SAHAKARI BANK LTD" },
  { code: "596", display_name: "MANORAMA CO-OP. BANK LTD." },
  { code: "597", display_name: "SAMARTH SAHAKARI BANK LTD" },
  { code: "598", display_name: "MAHESH URBAN CO-OP. BANK LTD" },
  { code: "599", display_name: "OSMANABAD JANTA SAHAKARI BANK LTD" },
  { code: "600", display_name: "NILKANTH URBAN CO-OP. BANK LTD" },
  { code: "601", display_name: "VITA MERCHANTS CO-OP. BANK LTD" },
  { code: "602", display_name: "VIDYANANAD CO-OP. BANK LTD" },
  { code: "603", display_name: "SHRI MAHAVEER URBAN CO-OP. BAN" },
  { code: "604", display_name: "LOKMANGAL CO-OP. BANK LTD" },
  { code: "605", display_name: "KAMALA CO-OP. BANK LTD" },
  { code: "606", display_name: "BARAMATI SAHAKARI BANK LTD" },
  { code: "607", display_name: "SHARAD NAGARI SAHAKARI BANK LTD" },
  { code: "608", display_name: "SOCIETE GENERALE THE FRENCH AN" },
  { code: "611", display_name: "NAGAR SAHAKARI BANK LTD" },
  { code: "612", display_name: "LAXMI CO-OPERATIVE BANK LTD" },
  { code: "614", display_name: "MANIPUR RURAL BANK" },
  { code: "615", display_name: "Bhavnagar Nagrik Sahakri Bank" },
  { code: "618", display_name: "MEGHALAYA COOP APEX BANK LTD" },
  { code: "619", display_name: "Dena Gujarat Gramin Bank" },
  { code: "620", display_name: "MEGHALAYA RURAL BANK" },
  { code: "621", display_name: "NAWANAGAR CO-OP. BANK LTD" },
  { code: "622", display_name: "PARSHWANATH CO-OP. BANK LTD" },
  { code: "639", display_name: "CITIZEN CO-OP. BANK LTD" },
  { code: "640", display_name: "HINDUSTAN CO-OP. BANK LTD" },
  { code: "641", display_name: "DBS BANK LTD" },
  { code: "642", display_name: "JRB-JAMMU RURAL BANK" },
  { code: "643", display_name: "RANCHI KHUNTI CENTRAL CO-OP. BANK LTD" },
  { code: "644", display_name: "ELLAQUAI DEHATI BANK" },
  { code: "645", display_name: "THE JAMMU CENTRAL CO-OPERATIVE" },
  { code: "648", display_name: "SINGHBHUM DIST. CENTRAL CO-OP. BANK LTD" },
  { code: "652", display_name: "COASTAL LOCAL AREA BANK LTD.(CLA)" },
  { code: "653", display_name: "NARMADA MALWA GRAMIN BANK" },
  { code: "658", display_name: "BARCLAYS BANK PLC" },
  { code: "659", display_name: "SHINHAN BANK" },
  { code: "660", display_name: "Citizens Co op Bank Ltd" },
  { code: "661", display_name: "Lokvikas Nagari Sahakari Bank Ltd" },
  { code: "662", display_name: "SAURASHTRA GRAMIN BANK" },
  { code: "663", display_name: "HDFC BANK LTD." },
  { code: "693", display_name: "ZOROASTRIAN BANK" },
  { code: "694", display_name: "Mizuho Corporate Bank Limited" },
  { code: "696", display_name: "SARVA HARYANA GRAMIN BANK" },
  { code: "700", display_name: "BAITARANI GRAMYA BANK" },
  { code: "702", display_name: "CHAITANYA GODAVARI GRAMEENA BA" },
  { code: "703", display_name: "ANDHRA PRAGATHI GRAMEENA BANK" },
  { code: "706", display_name: "KALINGA GRAMYA BANK" },
  { code: "707", display_name: "DECCAN GRAMEENA BANK (DEG)" },
  { code: "733", display_name: "Bharati Sahakari Bank Ltd" },
  { code: "800", display_name: "CUTTACK CENTRAL CO-OP. BANK" },
  { code: "801", display_name: "Hindustan Co Op Bank" },
  { code: "802", display_name: "URBAN CO-OPERATIVE BANK" },
  { code: "803", display_name: "ABHIVRIDHI MAHILA SAHAKARI BAN" },
  { code: "804", display_name: "SIRSI URBAN SAHAKRI SOUHARD BA" },
  { code: "805", display_name: "KARNATAKA VIKAS BANK(KTV)" },
  { code: "806", display_name: "ANDHRA PRADESH GRAMEENA VIKAS" },
  { code: "807", display_name: "MANSA NAGARIK SAHAKARI BANK LTD" },
  { code: "808", display_name: "PIONEER URBAN CO-OPERATIVE BAN" },
  { code: "809", display_name: "HDFC BANK LTD" },
  { code: "811", display_name: "DECCAN GRAMEENA BANK (DGB)" },
  { code: "812", display_name: "DAIVADNYA SAHAKARI BANK" },
  { code: "814", display_name: "Irinjalakkuda Town Co.op. Bank Ltd" },
  { code: "826", display_name: "Peoples Urban Co-Operative Bank" },
  { code: "828", display_name: "HIMACHAL GRAMIN BANK" },
  { code: "829", display_name: "KOZHIKODE DISTRICT CO-OP BANK LTD" },
  { code: "832", display_name: "Feroke co op urban bank Ltd" },
  { code: "834", display_name: "MALVIYA URBAN CO-OPERATIVE BAN" },
  { code: "836", display_name: "DSP CO-OP BANK" },
  { code: "838", display_name: "Tirupati Co op Town Bank Ltd" },
  {
    code: "840",
    display_name: "SHRI CHHATRAPATI RAJARSHI SHAHU URBAN CO-OP. BANK LTD.",
  },
  { code: "841", display_name: "KODUNGALLUR TOWN CO-OP BANK" },
  { code: "843", display_name: "The Palamoor Co-Op Urban Bank Ltd" },
  { code: "851", display_name: "SARVODAYA COMMERCIAL CO-OP Bank LTD" },
  { code: "000", display_name: "Other" },
  { code: "037", display_name: "CITI BANK " },
  { code: "016", display_name: "CENTRAL BANK OF INDIA(CBI)" },
  { code: "025", display_name: "SYNDICATE BANK" },
  { code: "054", display_name: "CITY UNION BANK LTD" },
  { code: "009", display_name: "STATE BANK OF TRAVANCORE" },
  { code: "022", display_name: "ORIENTAL BANK OF COMMERCE" },
  { code: "056", display_name: "LVB-LAKSHMI VILAS BANK LTD" },
  { code: "006", display_name: "STATE BANK OF MYSORE" },
  { code: "023", display_name: "PUNJAB AND SIND BANK" },
  { code: "059", display_name: "SOUTH INDIAN BANK LTD.(SIB)" },
  { code: "072", display_name: "DEVELOPMENT CREDIT BANK LTD" },
  { code: "082", display_name: "MAHARASHTRA STATE CO-OP BANK LTD" },
  { code: "013", display_name: "BANK OF INDIA  " },
  { code: "039", display_name: "HSBC Bank" },
];
// export const bank_name = [
//   { code: "853", display_name: "THE VAIDYANATH URBAN CO OP BANK LTD" },
//   { code: "854", display_name: "POORNAWADI NAGRIK SAHAKARI BAN" },
//   { code: "859", display_name: "Bhingar Urban Coop Bank" },
//   { code: "899", display_name: "RESERVE BANK EMPLOYEES CO-OPER" },
//   { code: "903", display_name: "DURGA CO-OP. URBAN BANK LTD" },
//   { code: "998", display_name: "Credit Co Op Bank" },
//   { code: "2", display_name: "STATE BANK OF INDIA" },
//   { code: "3", display_name: "STATE BANK OF BIKANER & JAIPUR" },
//   { code: "4", display_name: "STATE BANK OF HYDERABAD" },
//   { code: "5", display_name: "STATE BANK OF INDORE(SBN)" },
//   { code: "6", display_name: "STATE BANK OF MYSORE" },
//   { code: "7", display_name: "STATE BANK OF PATIALA(007)" },
//   { code: "8", display_name: "STATE BANK OF SAURASHTRA (MERG" },
//   { code: "9", display_name: "STATE BANK OF TRAVANCORE" },
//   { code: "10", display_name: "ALLAHABAD BANK(ALB)" },
//   { code: "11", display_name: "ANDHRA BANK" },
//   { code: "12", display_name: "BANK OF BARODA" },

//   { code: "15", display_name: "CANARA BANK" },
//   { code: "16", display_name: "CENTRAL BANK OF INDIA (CBI)" },
//   { code: "17", display_name: "CORPORATION BANK" },
//   { code: "18", display_name: "DENA BANK" },
//   { code: "19", display_name: "INDIAN BANK(INB)" },
//   { code: "20", display_name: "INDIAN OVERSEAS BANK(IOB)" },
//   { code: "22", display_name: "ORIENTAL BANK OF COMMERCE" },
//   { code: "23", display_name: "PUNJAB AND SIND BANK" },
//   { code: "24", display_name: "PUNJAB NATIONAL BANK" },
//   { code: "25", display_name: "SYNDICATE BANK" },
//   { code: "26", display_name: "UNION BANK OF INDIA(UBI)" },
//   { code: "27", display_name: "UNITED BANK OF INDIA (027)" },
//   { code: "28", display_name: "UCO BANK" },
//   { code: "29", display_name: "VIJAYA BANK" },

//   { code: "36", display_name: "STANDARD CHARTERED BANK" },

//   { code: "39", display_name: "HONGKONG SHANGHAI BKG.CORP." },

//   { code: "48", display_name: "DHANALAKSHMI BANK LTD" },
//   { code: "49", display_name: "FEDERAL BANK LTD" },
//   { code: "51", display_name: "JAMMU AND KASHMIR BANK LTD" },
//   { code: "52", display_name: "Karnataka bank" },
//   { code: "53", display_name: "KARUR VYSYA BANK LTD.(KVB)" },
//   { code: "54", display_name: "CITY UNION BANK LTD" },
//   { code: "56", display_name: "LVB-LAKSHMI VILAS BANK LTD" },
//   { code: "59", display_name: "SOUTH INDIAN BANK LTD.(SIB)" },
//   { code: "60", display_name: "TAMILNAD MERCANTILE BANK LTD." },
//   { code: "64", display_name: "VYSYA BANK LTD.(VBL)" },
//   { code: "65", display_name: "ABHYUDAYA CO-OP BANK LTD" },

//   { code: "68", display_name: "MUMBAI DIST.CENTRAL CO-OP BANK" },

//   { code: "71", display_name: "DECCAN MERCHANTS CO-OP BANK LTD" },

//   { code: "73", display_name: "JAIN SAHAKARI BANK LTD" },
//   { code: "74", display_name: "JANATA SAHAKARI BANK LTD" },
//   { code: "76", display_name: "KAPOLE CO-OP BANK LTD" },
//   { code: "77", display_name: "KURLA NAGARIK SAHAKARI BANK LTD" },
//   { code: "79", display_name: "THE MALAD SAHAKARI BANK LTD" },
//   { code: "82", display_name: "MAHARASHTRA STATE CO-OP BANK L" },
//   { code: "84", display_name: "MOGAVEERA CO-OP BANK LTD" },
//   { code: "85", display_name: "NEW INDIA CO-OP BANK LTD" },
//   { code: "86", display_name: "N.K.G.S.B.CO-OP BANK LTD" },
//   { code: "87", display_name: "SAHYADRI SAH. BANK LTD" },
//   { code: "88", display_name: "SARASWAT BANK" },
//   { code: "89", display_name: "THE SHAMRAO VITHAL CO-OP BANK LTD." },
//   { code: "91", display_name: "TAMILNADU STATE CO-OP BANK LTD" },
//   { code: "92", display_name: "MADRAS CENTRAL CO-OP BANK LTD" },
//   { code: "93", display_name: "WEST BENGAL STATE CO-OP BANK" },
//   { code: "94", display_name: "NOIDA COMMERCIAL COOPERATIVE BANK LTD" },
//   { code: "95", display_name: "GREATER BOMBAY CO-OP BANK LTD" },
//   { code: "96", display_name: "THE MAHANAGAR CO-OP. BANK LTD" },
//   { code: "98", display_name: "APNA SAHAKARI BANK LTD" },
//   { code: "99", display_name: "SONALI BANK LIMITED" },
//   { code: "101", display_name: "BANTRA CO-OP BANK LTD" },
//   { code: "103", display_name: "LILUAH CO-OP BANK LTD" },
//   { code: "105", display_name: "JANAKALYAN SAHAKARI BANK LTD" },
//   { code: "107", display_name: "THE SATARA SAHAKARI BANK LTD" },
//   { code: "108", display_name: "THE SAFE CO-OP BANK LTD" },
//   { code: "109", display_name: "THANE JANATA SAHAKARI BANK LTD" },
//   { code: "110", display_name: "THE MUNCIPAL CO-OP BANK LTD" },
//   { code: "111", display_name: "GENERAL POST OFFICE" },
//   { code: "112", display_name: "THE BHARAT CO-OP BANK LTD" },
//   { code: "113", display_name: "GUWAHATI CO-OP URBAN BANK LTD" },
//   { code: "114", display_name: "INDUSTRIAL CO-OP BANK LTD" },
//   { code: "116", display_name: "ASSAM GRAMIN VIKASH BANK(PJG)" },
//   { code: "117", display_name: "ASSAM CO-OP APEX BANK LTD" },
//   { code: "118", display_name: "AHMEDABAD DIST.CO-OP. BANK LTD.(ADC)" },
//   { code: "124", display_name: "GUJRATH STATE CO-OP BANK LTD" },
//   { code: "126", display_name: "KALUPUR COMMERCIAL CO-OP BANK LTD" },
//   { code: "128", display_name: "NUTAN NAGRIK SAHAKRI BANK LTD" },
//   { code: "130", display_name: "SOCIAL CO-OP BANK LTD" },
//   { code: "131", display_name: "TEXTILE TRADERS CO-OP BANK LTD" },
//   { code: "134", display_name: "BHAGYODAYA CO-OP BANK LTD" },
//   { code: "135", display_name: "SARANGPURA CO-OP BANK LTD" },
//   { code: "136", display_name: "UNION CO-OP BANK LTD" },
//   { code: "138", display_name: "COLOUR MERCHANTS CO-OP BANK LTD" },
//   { code: "139", display_name: "NAVNIRMAN CO-OP BANK LTD" },
//   { code: "141", display_name: "PROGRESSIVE MERC.CO-OP BANK LTD" },
//   { code: "142", display_name: "ANDHRA PRADESH MAHESH CO-OP UR" },
//   { code: "143", display_name: "ANDHRA PRADESH STATE CO-OP BANK LTD" },
//   { code: "144", display_name: "HYDERABAD DIST.CO-OP CENTRAL BANK LTD" },
//   { code: "149", display_name: "PATAN CO-OP BANK LTD" },
//   { code: "150", display_name: "BANK OF BAHRAIN AND KUWAIT BSC" },
//   { code: "153", display_name: "BARODA CENTRAL CO-OP BANK LTD" },
//   { code: "154", display_name: "BARODA TRADERS CO-OP BANK LTD" },
//   { code: "156", display_name: "BARODA CITY CO-OP BANK LTD" },
//   { code: "159", display_name: "MAKARPURA INDL.EST.CO-OP BANK LTD" },
//   { code: "160", display_name: "VARDHAMAN SAH.BANK LTD" },
//   { code: "164", display_name: "COSMOS CO-OP BANK LTD" },
//   { code: "165", display_name: "THE MUSLIM CO-OP BANK LTD" },
//   { code: "168", display_name: "PUNE PEOPLES CO-OP BANK LTD" },
//   { code: "171", display_name: "VIDYA SAH.BANK LTD" },
//   { code: "172", display_name: "PUNE HEAD POST OFFICE" },
//   { code: "174", display_name: "PUNE ZILLA MADHYA.SAH.BANK MARYADIT" },
//   { code: "175", display_name: "MAHESH SAH.BANK LTD" },
//   { code: "176", display_name: "RATNAKAR BANK LTD" },
//   { code: "177", display_name: "KERALA STATE CO-OP BANK LTD" },
//   { code: "178", display_name: "LORD KRISHNA BANK LTD.(LKR)" },
//   { code: "180", display_name: "TRIVANDRUM DIST.CO-OP BANK LTD" },
//   { code: "182", display_name: "SHIKSHAK SAH.BANK LTD" },
//   { code: "184", display_name: "NAINITAL BANK LTD" },
//   { code: "189", display_name: "JAIPUR CENTRAL CO-OP BANK LTD" },
//   { code: "191", display_name: "Rajasthan Urban Co-op Bank" },
//   { code: "192", display_name: "URBAN CO-OP BANK LTD" },
//   { code: "195", display_name: "THE VAISH CO-OP NEW BANK LTD" },
//   { code: "196", display_name: "DELHI NAGARIK SAH.BANK LTD" },
//   { code: "197", display_name: "GRAIN MERCHANTS CO-OP BANK LTD" },
//   { code: "198", display_name: "TRIVANDRUM CO-OP URBAN BANK LTD" },
//   { code: "199", display_name: "CHITANVISPURA FRIENDS CO-OP BANK LTD" },
//   { code: "200", display_name: "DEUTSCHE BANK" },
//   { code: "201", display_name: "SHREE MAHALAXMI MER.CO-OP BANK LTD" },
//   { code: "202", display_name: "PRAGATI SAHAKARI BANK LTD" },
//   { code: "207", display_name: "SADHANA SAHAKARI BANK LTD" },
//   { code: "208", display_name: "VAISH CO-OP ADARSH BANK LTD" },
//   { code: "209", display_name: "CITIZEN CREDIT CO-OP BANK LTD" },
//   { code: "210", display_name: "OMAN INTERNATIONAL BANK SAOG" },
//   { code: "211", display_name: "AXIS BANK LTD." },
//   { code: "213", display_name: "BIHAR STATE CO-OP BANK LTD" },
//   { code: "215", display_name: "SARASPUR NAGRIK SAHKARI BANK(SNB)" },
//   { code: "217", display_name: "RAJKOT NAGRIK SAHAKARI BANK LTD" },
//   { code: "218", display_name: "ORRISA STATE CO-OP BANK LTD" },
//   { code: "219", display_name: "NEELANCHAL GRAMYA BANK" },
//   { code: "222", display_name: "A.P. VARDHAMAN (MAHILA) CO-OP BANK LTD" },
//   { code: "225", display_name: "BCO-Sreenidhi Sahakara Bank Niyamitha" },
//   { code: "226", display_name: "APEX BANK" },
//   { code: "227", display_name: "MALLESWARAN CO-OP BANK LTD" },
//   { code: "229", display_name: "I.C.I.C.I. BANKLTD." },
//   { code: "233", display_name: "HDFC BANK (ERSTWHILE CENTURION" },
//   { code: "234", display_name: "INDUSIND BANK LTD" },
//   { code: "235", display_name: "DOMBIVLI NAG.SAH.BANK LTD" },
//   { code: "238", display_name: "BASSAIN CATHOLIC CO-OP BANK LT" },
//   { code: "240", display_name: "HDFC BANK LTD.(HDF)" },
//   { code: "242", display_name: "PANCHSHEEL MERCANTILE CO-OP BANK LTD" },
//   { code: "243", display_name: "THE RANDER PEOPLES CO-OP BANK LTD" },
//   { code: "245", display_name: "SURAT MERCANTILE CO-OP BANK LTD" },
//   { code: "246", display_name: "SURAT NATIONAL CO-OP BANK LTD" },
//   { code: "248", display_name: "SURAT TEXTILE TRADERS CO-OP BANK LTD" },
//   { code: "249", display_name: "SARVODAYA SAHAKARI BANK LTD" },
//   { code: "250", display_name: "PRIME CO-OP BANK LTD" },
//   { code: "251", display_name: "SURAT PEOPLES CO-OP BANK LTD" },
//   { code: "255", display_name: "INDRAPRASTHA SAH. BANK LTD" },
//   { code: "256", display_name: "SARDAR VALLABHBHAI SAH. BANK LTD" },
//   { code: "257", display_name: "JANASEVA SAHAKARI BANK LTD" },
//   { code: "258", display_name: "SIDDI CO-OP BANK LTD" },
//   { code: "259", display_name: "I.D.B.I. BANK LTD." },
//   { code: "261", display_name: "SHRI M.VISVESVASRAYA CO-OP BAN" },
//   { code: "267", display_name: "MAHILA CO-OP BANK LTD" },
//   { code: "271", display_name: "THE TEXTILE CO-OP BANK OF SURAT LTD" },
//   { code: "272", display_name: "BHAGINI NIVEDITA SAH. BANK MAR" },
//   { code: "275", display_name: "THE NATIONAL CO-OP BANK LTD" },
//   { code: "278", display_name: "INDORE CLOTH MARKET CO-OP BANK LTD" },
//   { code: "279", display_name: "INDORE PARASPARA SAH. BANK LTD" },
//   { code: "280", display_name: "INDORE PREMIER CO-OP BANK LTD" },
//   { code: "281", display_name: "M.P.RAJYA SAH. BANK LTD" },
//   { code: "283", display_name: "NAGRIK SAH. BANK LTD" },
//   { code: "284", display_name: "PARASPAR SAHAYAK CO-OP BANK LTD" },
//   { code: "285", display_name: "SHUBH-LAXMI MAHILA CO-OP BANK LTD" },
//   { code: "286", display_name: "TRANSPORT CO-OP BANK LTD" },
//   { code: "288", display_name: "RESERVE BANK EMPLOYEES CO-OP BANK LTD" },
//   { code: "289", display_name: "THE VARACHHA CO-OP BANK LTD" },
//   { code: "291", display_name: "THE SHIVAJI-NAGAR CO-OP BANK LTD" },
//   { code: "297", display_name: "CITIZEN URBAN COOP BANK LTD" },
//   { code: "299", display_name: "DISTRICT CO-OP BANK LTD" },
//   { code: "302", display_name: "THE NARODA NAGRIK CO-OP BANK LTD" },
//   { code: "303", display_name: "KANKARIA MANINAGAR NAG.SAH.BANK LTD" },
//   { code: "304", display_name: "KANGRA CO-OP BANK LTD" },
//   { code: "305", display_name: "KHATRI CO-OP URBAN BANK LTD" },
//   { code: "308", display_name: "JHARNESHWAR NAG.SAH.BANK MARYADIT" },
//   { code: "309", display_name: "BHOPAL CO-OP CENTRAL BANK LTD" },
//   { code: "310", display_name: "SADGURU NAGRIK SAHAKARI BANK MARYADIT" },
//   { code: "312", display_name: "PARSIK JANATA SAH.BANK LTD" },
//   { code: "313", display_name: "MEHASANA URBAN CO-OP BANK LTD" },
//   { code: "314", display_name: "THE CITY CO-OP BANK LTD" },
//   { code: "315", display_name: "SHRI CHHANI NAG.SAH.BANK LTD" },
//   { code: "318", display_name: "COMMERCIAL CO-OP BANK LTD" },
//   { code: "319", display_name: "SANKHEDA NAG.SAH.BANK LTD" },
//   { code: "320", display_name: "MAHESANA SAMAJ CO-OP BANK LTD" },
//   { code: "322", display_name: "ALAVI CO-OP BANK LTD" },
//   { code: "323", display_name: "UMA CO-OP BANK LTD" },
//   { code: "325", display_name: "SULAIMANI CO-OP BANKING SOCIETY LTD" },
//   { code: "326", display_name: "SHRI CO-OP BANK LTD" },
//   { code: "328", display_name: "PUNJAB AND MAHARASHTRA CO-OP BANK LTD" },
//   { code: "329", display_name: "JAIN CO-OP BANK LTD" },
//   { code: "330", display_name: "AKOLA URBAN CO-OP BANK LTD" },
//   { code: "331", display_name: "BANK OF CEYLON" },
//   { code: "332", display_name: "STATE BANK OF MAURITIUS LTD" },
//   { code: "335", display_name: "VSL-VISHWESHWAR SAHAKARI BANK" },
//   {
//     code: "337",
//     display_name: "GURDASPUR-AMRITSAR KSHETRIYA GRAMIN VIKAS BANK",
//   },
//   { code: "340", display_name: "ASTHA MAHILA NAGRIK SAHAKARI BANK MARYADIT" },
//   { code: "341", display_name: "MAHANAGAR NAGRIK SAHAKARI BANK MARYADIT" },
//   { code: "344", display_name: "TIRUPATI URBAN CO-OP BANK LTD" },
//   { code: "345", display_name: "Abhinav Sahakari Bank" },
//   { code: "348", display_name: "JAIPUR THAR GRAMIN BANK" },
//   { code: "349", display_name: "SHRAMIK SAHAKARI BANK LTD" },
//   { code: "350", display_name: "PUNJAB STATE CO-OP BANK LTD" },
//   { code: "352", display_name: "SUVARNAYUG SAHAKARI BANK LTD" },
//   { code: "353", display_name: "RAJARSHI SHAHU SAHAKARI BANK MARYADIT" },
//   { code: "355", display_name: "BHARATI SAHAKARI BANK LTD" },
//   { code: "358", display_name: "SHREE SHARADA SAHAKARI BANK LTD" },
//   { code: "360", display_name: "GANDHIBAGH SAHAKARI BANK LTD" },
//   { code: "361", display_name: "JANATA CO-OP BANK LTD" },
//   { code: "363", display_name: "KRISHNA MERCANTILE CO-OP BANK LTD" },
//   { code: "364", display_name: "AKOLA JANATA COMMERCIAL CO-OP BANK LTD" },
//   { code: "365", display_name: "SUB-SHRIRAM URBAN CO-OP. BANK" },
//   { code: "367", display_name: "KALYAN JANATA SAHAKARI BANK LTD" },
//   { code: "369", display_name: "NASIK MERCHANTS CO-OP BANK LTD" },
//   { code: "371", display_name: "NASIK ZILHA MAHILA SAHAKARI BANK LTD" },
//   { code: "372", display_name: "NASIK DISTRICT CENTRAL CO-OP BANK LTD" },
//   { code: "374", display_name: "NASIK ROAD DEOLALI VYAPARI SAHAKARI BANK LTD" },
//   { code: "375", display_name: "SHREE SAMARTH SAHAKARI BANK LTD" },
//   { code: "376", display_name: "GODAVARI URBAN CO-OP BANK LTD" },
//   { code: "381", display_name: "NASIK JILHA MAHILA VIKAS SAHAKARI BANK LTD" },
//   { code: "384", display_name: "TRICHUR URBAN CO-OP BANK LTD" },
//   { code: "385", display_name: "INDIAN MERCANTILE CO-OP BANK LTD" },
//   { code: "386", display_name: "ICHALKARANJI JANATA SAHAKARI B" },
//   { code: "387", display_name: "KOLHAPUR DISTRICT CENTRAL CO-OP BANK LTD" },
//   { code: "389", display_name: "SHRI MAHALAXMI CO-OP BANK LTD" },
//   { code: "390", display_name: "STATE TRANSPORT CO-OP BANK LTD" },
//   { code: "393", display_name: "YOUTH DEVELOPMENT CO-OP BANK LTD" },
//   { code: "394", display_name: "THE AJARA URBAN CO-OP BANK LTD" },
//   { code: "395", display_name: "CHOUNDESHWARI CO-OP BANK LTD" },
//   { code: "396", display_name: "SHRIPATRAO DADA SAHAKARI BANK LTD" },
//   { code: "397", display_name: "SHRI WARANA SAHAKARI BANK LTD" },
//   { code: "398", display_name: "KOLHAPUR URBAN CO-OP BANK LTD" },
//   { code: "401", display_name: "KOLHAPUR MAHILA SAHAKARI BANK LTD" },
//   { code: "402", display_name: "SHREE PANCHGANGA NAGARI SAHAKARI BANK LTD" },
//   { code: "403", display_name: "THE VADGAON URBAN CO-OP BANK LTD" },
//   { code: "404", display_name: "GADHINGLAJ URBAN CO-OP BANK LTD" },
//   { code: "407", display_name: "SHRI VEERSHAIV CO-OP BANK LTD" },
//   { code: "410", display_name: "JAMIA CO-OP BANK LTD" },
//   { code: "411", display_name: "TAMILNADU INDL.COOP.BANK LTD" },
//   { code: "412", display_name: "THE SEVA VIKAS CO-OP BANK LTD" },
//   { code: "413", display_name: "BICHOLIM URBAN CO-OP BANK LTD" },
//   { code: "415", display_name: "GOA STATE CO-OP BANK LTD" },
//   { code: "416", display_name: "THE GOA URBAN CO OP BANK LTD" },
//   { code: "417", display_name: "MADGAUM URBAN CO-OP BANK LTD" },
//   { code: "418", display_name: "MAPUSA URBAN CO-OP BANK LTD" },
//   { code: "419", display_name: "FINANCIAL CO-OP BANK LTD" },
//   { code: "420", display_name: "APANI SAHAKARI BANK LTD" },
//   { code: "421", display_name: "BAVLA NAGRIK SAHAKARI BANK LTD" },
//   { code: "422", display_name: "UNITED CO-OP BANK LTD" },
//   { code: "432", display_name: "ADINATH CO-OP BANK LTD" },
//   { code: "434", display_name: "KUKARWADA NAG.SAH.BANK LTD" },
//   { code: "437", display_name: "AKHAND ANAND CO-OP BANK LTD" },
//   { code: "438", display_name: "THE ANDHRA BANK EMPLOYEES CO-OP BANK LTD" },
//   { code: "441", display_name: "CO-OPERATIVE BANK OF RAJKOT LTD" },
//   { code: "442", display_name: "SHREE DHARTI CO-OP BANK LTD" },
//   { code: "443", display_name: "JIVAN COMM.CO-OP BANK LTD" },
//   { code: "445", display_name: "RAJKOT PEOPLES CO-OP BANK LTD" },
//   { code: "446", display_name: "SREE CHARAN CO-OP BANK LTD" },
//   { code: "448", display_name: "AVADH GRAMIN BANK" },
//   { code: "449", display_name: "ASSOCIATE CO-OP BANK LTD" },
//   { code: "450", display_name: "NAGRIK SAMABAY BANK LTD" },
//   { code: "451", display_name: "THE WOMENS CO-OP BANK LTD" },
//   { code: "454", display_name: "BARODA UP GRAMIN BANK" },
//   { code: "455", display_name: "SREE SUBRAMANYESWARA CO-OP BANK LTD" },
//   { code: "456", display_name: "INTEGRAL URBAN CO-OP BANK" },
//   { code: "458", display_name: "GEORGE TOWN CO-OP BANK LTD" },
//   { code: "459", display_name: "PANCHMAHAL-VADODARA GRAMIN BAN" },
//   { code: "460", display_name: "NORTH MALABAR GRAMIN BANK" },
//   { code: "461", display_name: "AGRA DISTRICT CO-OP BANK LTD" },
//   { code: "462", display_name: "JAMUNA GRAMIN BANK" },
//   { code: "465", display_name: "SHREE LAXMI CO-OP BANK LTD" },
//   { code: "466", display_name: "THE ALWAYE URBAN CO-OP BANK LTD" },
//   { code: "468", display_name: "VIJAY COMMERCIAL CO-OP BANK LTD" },
//   { code: "470", display_name: "NAGRIK SAHAKARI BANK LTD" },
//   { code: "471", display_name: "CITIZENS CO-OP BANK LTD" },
//   { code: "472", display_name: "VERAVAL MERCANTILE CO-OP BANK LTD" },
//   { code: "473", display_name: "RAJKOT COMMERCIAL CO-OP BANK LTD" },
//   { code: "474", display_name: "SHRI RAJKOT DISTRICT CO-OP BAN" },
//   { code: "475", display_name: "SHREE PARSWANATH CO-OP BANK LTD" },
//   { code: "476", display_name: "JILA SAHAKARI KENDRIYA BANK MARYADIT" },
//   { code: "479", display_name: "JILA SAHAKARI CENTRAL BANK MARYADIT" },
//   { code: "480", display_name: "SOUTH MALABAR GRAMIN BANK" },
//   { code: "481", display_name: "THRISSUR DISTRICT CO-OP BANK LTD" },
//   { code: "485", display_name: "KOTAK MAHINDRA BANK LTD" },
//   { code: "486", display_name: "KASHI GRAMIN BANK" },
//   { code: "487", display_name: "BANARAS MERCANTILE CO-OP BANK LTD" },
//   { code: "488", display_name: "KARAD URBAN CO-OP BANK LTD" },
//   { code: "489", display_name: "GWALIOR DATIA KSHETRIYA GRAMIN BANK" },
//   { code: "490", display_name: "ALLAHABAD DISTRICT CO-OP BANK LTD" },
//   { code: "491", display_name: "CHHATTISGARH RAJYA SAHAKARI BANK MARYADIT" },
//   {
//     code: "492",
//     display_name: "INDIRA PRIYADARSHINI MAHILA NAG.SAH.BANK MARYADIT",
//   },
//   { code: "493", display_name: "LAXMI MAHILA NAGRIK SAHAKARI BANK MARYADIT" },
//   { code: "494", display_name: "RAIPUR URBAN MERCANTILE CO-OP BANK LTD" },
//   { code: "495", display_name: "VYAVSAIK SAHAKARI BANK LTD" },
//   {
//     code: "497",
//     display_name: "LAXMIBAI MAHILA NAGRIK SAHAKARI BANK MARYADIT",
//   },
//   { code: "500", display_name: "JPMORGAN CHASE BANK N.A." },
//   { code: "502", display_name: "BUSINESS CO-OP BANK MARYADIT" },
//   { code: "503", display_name: "JANAKALYAN CO-OP BANK LTD" },
//   { code: "504", display_name: "VISHWAS CO-OP BANK LTD" },
//   {
//     code: "506",
//     display_name: "PADMASHRI DR. VITHALRAO VIKHE PATIL CO-OP BANK LTD",
//   },
//   { code: "507", display_name: "JALGAON JANATA SAHAKARI BANK LTD" },
//   { code: "508", display_name: "SANGAMNER MER. CO.OP.BK.," },
//   { code: "509", display_name: "NASIK ZILLA GIRNA SAHAKARI BANK LTD" },
//   { code: "510", display_name: "JANASEVA CO-OP BANK LTD" },
//   { code: "511", display_name: "DR.BABASAHEB AMBEDKAR SAH. BANK LTD" },
//   { code: "512", display_name: "CENTRAL MADHYA PRADESH GRAMIN BANK" },
//   { code: "518", display_name: "PIMPALGAON MERCHANTS CO-OP BANK LTD" },
//   { code: "520", display_name: "FEROKE CO-OP URBAN BANK LTD" },
//   { code: "521", display_name: "CHHATISGARH GRAMIN BANK" },
//   { code: "524", display_name: "THE THANE DISTRICT CENTRAL CO-" },
//   { code: "525", display_name: "THANE BHARAT SAHAKARI BANK LTD" },
//   { code: "526", display_name: "DR. AMBEDKAR NAGRIK SAHAKARI BANK MARYADIT" },
//   { code: "532", display_name: "YES BANK LTD." },
//   { code: "541", display_name: "DEHRADUN DISTRICT CO-OP BANK" },
//   { code: "542", display_name: "URBAN CO-OP BANK" },
//   { code: "544", display_name: "UTTARANCHAL GRAMIN BANK" },
//   { code: "545", display_name: "CLA-CAPITAL LOCAL AREA BANK(CL" },
//   {
//     code: "547",
//     display_name: "TRICHIRAPALLI DISTRICT CENTRAL CO-OP BANK LTD",
//   },
//   { code: "554", display_name: "UTTARANCHAL STATE CO-OP. BANK LTD" },
//   { code: "556", display_name: "SHRAMIK NAGARIK SAHAKARI BANK LTD" },
//   { code: "561", display_name: "MALKAPUR URBAN CO-OP BANK LTD" },
//   { code: "564", display_name: "SUNDARLAL SAWJI URBAN CO-OP. BANK LTD" },
//   { code: "569", display_name: "PEOPLES CO-OPERATIVE BANK LTD" },
//   { code: "570", display_name: "MAHARASHTRA GODAVARI GRAMIN BANK" },
//   { code: "574", display_name: "TUMKUR GRAIN MERCHANTS CO-OP BANK LTD" },
//   { code: "577", display_name: "UDAIPUR URBAN CO-OP. BANK LTD.(UUC)" },
//   { code: "579", display_name: "AURANGABAD DIST. CENTRAL CO-OP.BANK LTD" },
//   { code: "582", display_name: "DEOGIRI NAGARI SAHAKARI BANK LTD" },
//   { code: "584", display_name: "RAJARAMBAPU SAHAKARI BANK LTD" },
//   { code: "586", display_name: "SOLAPUR DIST. CENTRAL CO-OP. BANK LTD" },
//   { code: "587", display_name: "SOLAPUR JANATA SAHAKARI BANK LTD" },
//   { code: "588", display_name: "PANDHARPUR URBAN CO-OP. BANK LTD" },
//   { code: "589", display_name: "SOLAPUR SIDDEHWAR SAHAKARI BANK LTD" },
//   { code: "590", display_name: "SOLAPUR SOCIAL URBAN CO-OP.BANK LTD" },
//   { code: "591", display_name: "VIKAS SAHAKARI BANK LTD" },
//   { code: "592", display_name: "VYAPARI SAHAKARI BANK LTD" },
//   { code: "593", display_name: "BRAHMADEODADA MANE SAHAKARI BANK LTD" },
//   { code: "596", display_name: "MANORAMA CO-OP. BANK LTD." },
//   { code: "597", display_name: "SAMARTH SAHAKARI BANK LTD" },
//   { code: "598", display_name: "MAHESH URBAN CO-OP. BANK LTD" },
//   { code: "599", display_name: "OSMANABAD JANTA SAHAKARI BANK LTD" },
//   { code: "600", display_name: "NILKANTH URBAN CO-OP. BANK LTD" },
//   { code: "601", display_name: "VITA MERCHANTS CO-OP. BANK LTD" },
//   { code: "602", display_name: "VIDYANANAD CO-OP. BANK LTD" },
//   { code: "603", display_name: "SHRI MAHAVEER URBAN CO-OP. BAN" },
//   { code: "604", display_name: "LOKMANGAL CO-OP. BANK LTD" },
//   { code: "605", display_name: "KAMALA CO-OP. BANK LTD" },
//   { code: "606", display_name: "BARAMATI SAHAKARI BANK LTD" },
//   { code: "607", display_name: "SHARAD NAGARI SAHAKARI BANK LTD" },
//   { code: "608", display_name: "SOCIETE GENERALE THE FRENCH AN" },
//   { code: "611", display_name: "NAGAR SAHAKARI BANK LTD" },
//   { code: "612", display_name: "LAXMI CO-OPERATIVE BANK LTD" },
//   { code: "614", display_name: "MANIPUR RURAL BANK" },
//   { code: "615", display_name: "Bhavnagar Nagrik Sahakri Bank" },
//   { code: "618", display_name: "MEGHALAYA COOP APEX BANK LTD" },
//   { code: "619", display_name: "Dena Gujarat Gramin Bank" },
//   { code: "620", display_name: "MEGHALAYA RURAL BANK" },
//   { code: "621", display_name: "NAWANAGAR CO-OP. BANK LTD" },
//   { code: "622", display_name: "PARSHWANATH CO-OP. BANK LTD" },
//   // { code: "639", display_name: "CITIZEN CO-OP. BANK LTD" },
//   { code: "640", display_name: "HINDUSTAN CO-OP. BANK LTD" },
//   { code: "641", display_name: "DBS BANK LTD" },
//   { code: "642", display_name: "JRB-JAMMU RURAL BANK" },
//   { code: "643", display_name: "RANCHI KHUNTI CENTRAL CO-OP. BANK LTD" },
//   { code: "644", display_name: "ELLAQUAI DEHATI BANK" },
//   { code: "645", display_name: "THE JAMMU CENTRAL CO-OPERATIVE" },
//   { code: "648", display_name: "SINGHBHUM DIST. CENTRAL CO-OP. BANK LTD" },
//   { code: "652", display_name: "COASTAL LOCAL AREA BANK LTD.(CLA)" },
//   { code: "653", display_name: "NARMADA MALWA GRAMIN BANK" },
//   { code: "658", display_name: "BARCLAYS BANK PLC" },
//   { code: "659", display_name: "SHINHAN BANK" },
//   // { code: "660", display_name: "Citizens Co op Bank Ltd" },
//   { code: "661", display_name: "Lokvikas Nagari Sahakari Bank Ltd" },
//   { code: "662", display_name: "SAURASHTRA GRAMIN BANK" },
//   { code: "663", display_name: "HDFC BANK LTD." },
//   { code: "693", display_name: "ZOROASTRIAN BANK" },
//   { code: "694", display_name: "Mizuho Corporate Bank Limited" },
//   { code: "696", display_name: "SARVA HARYANA GRAMIN BANK" },
//   { code: "700", display_name: "BAITARANI GRAMYA BANK" },
//   { code: "702", display_name: "CHAITANYA GODAVARI GRAMEENA BA" },
//   { code: "703", display_name: "ANDHRA PRAGATHI GRAMEENA BANK" },
//   { code: "706", display_name: "KALINGA GRAMYA BANK" },
//   { code: "707", display_name: "DECCAN GRAMEENA BANK (DEG)" },
//   { code: "733", display_name: "Bharati Sahakari Bank Ltd" },
//   { code: "800", display_name: "CUTTACK CENTRAL CO-OP. BANK" },
//   { code: "801", display_name: "Hindustan Co Op Bank" },
//   { code: "802", display_name: "URBAN CO-OPERATIVE BANK" },
//   { code: "803", display_name: "ABHIVRIDHI MAHILA SAHAKARI BAN" },
//   { code: "804", display_name: "SIRSI URBAN SAHAKRI SOUHARD BA" },
//   { code: "805", display_name: "KARNATAKA VIKAS BANK(KTV)" },
//   { code: "806", display_name: "ANDHRA PRADESH GRAMEENA VIKAS" },
//   { code: "807", display_name: "MANSA NAGARIK SAHAKARI BANK LTD" },
//   { code: "808", display_name: "PIONEER URBAN CO-OPERATIVE BAN" },
//   { code: "811", display_name: "DECCAN GRAMEENA BANK (DGB)" },
//   { code: "812", display_name: "DAIVADNYA SAHAKARI BANK" },
//   { code: "814", display_name: "Irinjalakkuda Town Co.op. Bank Ltd" },
//   { code: "826", display_name: "Peoples Urban Co-Operative Bank" },
//   { code: "828", display_name: "HIMACHAL GRAMIN BANK" },
//   { code: "829", display_name: "KOZHIKODE DISTRICT CO-OP BANK LTD" },
//   { code: "832", display_name: "Feroke co op urban bank Ltd" },
//   { code: "834", display_name: "MALVIYA URBAN CO-OPERATIVE BAN" },
//   { code: "836", display_name: "DSP CO-OP BANK" },
//   { code: "838", display_name: "Tirupati Co op Town Bank Ltd" },
//   {
//     code: "840",
//     display_name: "SHRI CHHATRAPATI RAJARSHI SHAHU URBAN CO-OP. BANK LTD.",
//   },
//   { code: "841", display_name: "KODUNGALLUR TOWN CO-OP BANK" },
//   { code: "843", display_name: "The Palamoor Co-Op Urban Bank Ltd" },
//   { code: "851", display_name: "SARVODAYA COMMERCIAL CO-OP Bank LTD" },
//   { code: "000", display_name: "Other" },
//   { code: "037", display_name: "CITI BANK  " },

//   { code: "025", display_name: "SYNDICATE BANK" },

//   { code: "009", display_name: "STATE BANK OF TRAVANCORE" },
//   { code: "022", display_name: "ORIENTAL BANK OF COMMERCE" },
//   { code: "056", display_name: "LVB-LAKSHMI VILAS BANK LTD" },
//   { code: "006", display_name: "STATE BANK OF MYSORE" },
//   { code: "023", display_name: "PUNJAB AND SIND BANK" },
//   { code: "059", display_name: "SOUTH INDIAN BANK LTD.(SIB)" },
//   { code: "072", display_name: "DEVELOPMENT CREDIT BANK LTD" },
//   { code: "082", display_name: "MAHARASHTRA STATE CO-OP BANK L" },

//   { code: "053", display_name: "KARUR VYSYA BANK LTD.(KVB)" },
//   { code: "060", display_name: "TAMILNAD MERCANTILE BANK LTD." },
//   { code: "020", display_name: "INDIAN OVERSEAS BANK(IOB)" },
//   { code: "004", display_name: "STATE BANK OF HYDERABAD" },
//   { code: "008", display_name: "STATE BANK OF SAURASHTRA (MERG" },

//   { code: "029", display_name: "VIJAYA BANK" },
//   { code: "049", display_name: "FEDERAL BANK     " },
//   { code: "064", display_name: "VYSYA BANK LTD.(VBL)" },
//   { code: "069", display_name: "BMC BANK LTD" },
//   { code: "077", display_name: "KURLA NAGARIK SAHAKARI BANK LTD" },
//   { code: "085", display_name: "NEW INDIA CO-OP BANK LTD" },
//   { code: "089", display_name: "THE SHAMRAO VITHAL CO-OP BANK LTD." },
//   { code: "099", display_name: "SONALI BANK LIMITED" },
//   { code: "005", display_name: "STATE BANK OF INDORE(SBN)" },
//   { code: "013", display_name: "BANK OF INDIA" },

//   { code: "026", display_name: "UNION BANK OF INDIA " },
//   { code: "033", display_name: "BANK OF TOKYO LTD" },
//   { code: "039", display_name: "HSBC Bank" },
//   { code: "051", display_name: "JAMMU AND KASHMIR BANK LTD" },

//   { code: "076", display_name: "KAPOLE CO-OP BANK LTD" },
//   { code: "084", display_name: "MOGAVEERA CO-OP BANK LTD" },
//   { code: "088", display_name: "SARASWAT BANK" },
//   { code: "093", display_name: "WEST BENGAL STATE CO-OP BANK" },

//   { code: "002", display_name: "SBI" },
//   { code: "010", display_name: "ALLAHABAD BANK" },
//   { code: "014", display_name: "BANK OF MAHARASHTRA" },

//   { code: "027", display_name: "UNITED BANK OF INDIA" },
//   { code: "034", display_name: "BANQUE NATIONALE DE PARIS" },
//   { code: "047", display_name: "CATHOLIC SYRIAN BANK LTD." },
//   { code: "052", display_name: "Karnataka bank" },
//   { code: "066", display_name: "AHMEDABAD MERCANTILE CO-OP BANK LTD" },
//   { code: "087", display_name: "SAHYADRI SAH. BANK LTD" },
//   { code: "092", display_name: "MADRAS CENTRAL CO-OP BANK LTD" },
//   { code: "096", display_name: "THE MAHANAGAR CO-OP. BANK LTD" },
//   { code: "003", display_name: "STATE BANK OF BIKANER & JAIPUR" },
//   { code: "007", display_name: "STATE BANK OF PATIALA" },
//   { code: "019", display_name: "INDIAN BANK(INB)" },
//   { code: "024", display_name: "PUNJAB NATIONAL BANK" },
//   { code: "028", display_name: "UCO BANK" },
//   { code: "036", display_name: "STANDARD CHARTERED BANK" },
//   { code: "048", display_name: "DHANALAKSHMI BANK LTD" },
//   { code: "068", display_name: "MUMBAI DIST.CENTRAL CO-OP BANK" },
//   { code: "073", display_name: "JAIN SAHAKARI BANK LTD" },
//   { code: "079", display_name: "THE MALAD SAHAKARI BANK LTD" },
//   { code: "086", display_name: "N.K.G.S.B.CO-OP BANK LTD" },
//   { code: "091", display_name: "TAMILNADU STATE CO-OP BANK LTD" },
//   { code: "095", display_name: "GREATER BOMBAY CO-OP BANK LTD" },
//   { code: "999", display_name: "BANDHAN BANK  " },
// ];
export const eiaOptions = [
  {
    code: "NDML",
    display_name: "NSDL Database Management Limited",
  },
];
export const age_proof = [
  { code: "1012010311", display_name: "Others" },
  { code: "1012010366", display_name: "Voter ID Card" },
  { code: "1012010368", display_name: "Gram Panchayat Certificate" },
  { code: "1012010370", display_name: "ESIS Card" },
  { code: "1012010372", display_name: "Health Scheme Certificate (CGHS)" },
  { code: "1012010376", display_name: "LIC Age Admitted without Code" },
  { code: "1012010019", display_name: "Baptism Certificate" },
  { code: "1012010020", display_name: "Defense ID Card" },
  { code: "1012010021", display_name: "Domicile Certificate" },
  { code: "1012010024", display_name: "Municipal Birth Certificate" },
  { code: "1012010025", display_name: "PAN Card" },
  { code: "1012010027", display_name: "PF Statement" },
  { code: "1012010028", display_name: "School, College Proofs" },
  { code: "1012010029", display_name: "Service Extract" },
  { code: "1012010030", display_name: "Employee ID" },
  {
    code: "1012010031",
    display_name: "Hospital Discharge  Card less than age 5 years",
  },
  { code: "1012010032", display_name: "IRDA agent license" },
  { code: "1012010034", display_name: "LIC Policies, Reciepts" },
  { code: "1012010035", display_name: "School, college Certificate" },
  { code: "1012010036", display_name: "School, college admit cards" },
  {
    code: "1012010033",
    display_name: "Municipal Birth Certificate less than age 5yrs",
  },
  { code: "1012010364", display_name: "Ration Card" },
  { code: "1012010374", display_name: "Aadhar ID Card - Non Standard" },
  { code: "1012010026", display_name: "Passport" },
  { code: "1012010022", display_name: "Driving License" },
  { code: "1012010023", display_name: "Marriage Certificate" },
  { code: "1012010545", display_name: "Aadhar ID Card - Standard" },
];
