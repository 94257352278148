import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetCartQuery } from "../riders/services/rider";

const useProposalForm = ({ validator, defaultState, name, index }) => {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const { proposalData, position } = useSelector(state => state.proposal);
  const { data } = useGetCartQuery();

  useEffect(() => {
    setFormData(prev => ({
      ...defaultState,
      ...proposalData[name],
      ...prev,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposalData]);

  const setValue = (name, data, additionalValidate) => {
    setFormData(prev => ({ ...prev, [name]: data }));
    validator
      .validateAt(name, {
        ...formData,
        [name]: data,
      })
      .then(() => {
        setErrors(prev => ({ ...prev, [name]: "" }));
      })
      .catch(error => {
        setErrors(prev => ({ ...prev, [name]: error.message }));
      });
    if (additionalValidate) {
      additionalValidate.forEach(item => {
        const newData = {
          ...formData,
          [name]: data,
        };
        if (item && reduceObj(newData, item)) trigger(item, newData);
      });
    }
  };
  const trigger = (name, newData) => {
    validator
      .validateAt(name, {
        ...(newData || formData),
      })
      .then(() => {
        setErrors(prev => ({ ...prev, [name]: "" }));
      })
      .catch(error => {
        setErrors(prev => ({ ...prev, [name]: error.message }));
      });
  };
  const onSubmit = onSuccess => {
    validator
      .validate(formData, { abortEarly: false })
      .then(() => {
        setErrors({});
        onSuccess({ ...formData });
      })
      .catch(error => {
        error.inner?.forEach(item => {
          setErrors(prev => ({
            ...prev,
            [item.path.replace(/\[/g, ".").replace(/\]/g, "")]: item.message,
          }));
        });
      });
  };
  return {
    formData,
    setErrors,
    setValue,
    setFormData,
    errors,
    trigger,
    onSubmit,
    company_alias: data?.data?.product?.company?.alias,
    isSummary: position > index,
  };
};

export default useProposalForm;

export const reduceObj = (obj, key) => {
  const keys = key.split(".");
  let temp = obj[keys[0]];
  for (let i = 1; i < keys.length; ++i) {
    if (temp) temp = temp[keys[i]];
    else return undefined;
  }
  return temp;
};
