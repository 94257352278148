import { useState } from "react";
import { useEffect, useRef } from "react";

export const useDidMountEffect = (func = () => {}, deps = []) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export function useToggle(initialValue = false) {
  const [value, setValue] = useState(initialValue);

  const on = () => setValue(true);
  const off = () => setValue(false);

  const toggle = () => setValue(previousValue => !previousValue);

  return { value, toggle, on, off };
}
