import { Modal } from "react-bootstrap";
import "styled-components/macro";

function FullScreenModal({ children, animation = false, ...props }) {
  return (
    <Modal animation={animation} {...props}>
      <Modal.Body
        css={`
          width: 100vw;
          height: 100vh;
          position: fixed;
          top: 0;
          left: 0;
          background-color: #fff;
          padding: 0;
          margin: 0;
        `}
      >
        {children}
      </Modal.Body>
    </Modal>
  );
}

export default FullScreenModal;
